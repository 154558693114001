// External imports
import { useSelector } from 'react-redux';

// Pages imports
import Dashboard from '../../pages/dashboard_page/components/Dashboard';

function DashboardRoute() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const dashboardRoute = [
    {
      path: 'dashboard',
      element: <Dashboard />,
      isAllowed: customPermission.dashboard_index,
    },
  ];

  return dashboardRoute;
}

export default DashboardRoute;
