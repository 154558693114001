import { axiosSesion } from '../axios';

// Get all positions
export const resultPositions = (Authorization) => {
  return axiosSesion.get('/api/v1/positions', { headers: { Authorization } });
};

// Get position by ID
export const resultPositionId = (Authorization, id) => {
  return axiosSesion.get(`/api/v1/positions/${id}`, {
    headers: { Authorization },
  });
};

// Create a new position
export const resultNewPosition = (Authorization, newPosition) => {
  return axiosSesion.post('/api/v1/positions', newPosition, {
    headers: { Authorization },
  });
};

// Update position
export const resultEditPosition = (Authorization, id, positionUpdate) => {
  return axiosSesion.put(`/api/v1/positions/${id}`, positionUpdate, {
    headers: { Authorization },
  });
};
