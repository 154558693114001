export const dateWithTimezone = (date) => {
  // Output format yyyy-mm-dd hh-mm-ss offset

  const arrayDate1 = date.split('T');
  let arrayDate2 = [];
  let newTimezone = '';

  if (arrayDate1[1].includes('.')) {
    arrayDate2 = arrayDate1[1].split('.');
    newTimezone = arrayDate2[1].slice(-6);
  } else {
    if (arrayDate1[1].includes('+')) {
      arrayDate2 = arrayDate1[1].split('+');
      newTimezone = `+${arrayDate2[1]}`;
    } else {
      arrayDate2 = arrayDate1[1].split('-');
      newTimezone = `-${arrayDate2[1]}`;
    }
  }

  const newDate = arrayDate1[0];
  const newTime = arrayDate2[0];

  return `${newDate} ${newTime} ${newTimezone}`;
};

export const dateWithOutTimezone = (date) => {
  // Output format yyyy-mm-dd hh-mm-ss

  const arrayDate1 = date.split('T');
  let arrayDate2 = [];

  if (arrayDate1[1].includes('.')) {
    arrayDate2 = arrayDate1[1].split('.');
  } else {
    if (arrayDate1[1].includes('+')) {
      arrayDate2 = arrayDate1[1].split('+');
    } else {
      arrayDate2 = arrayDate1[1].split('-');
    }
  }

  const newDate = arrayDate1[0];
  const newTime = arrayDate2[0];

  return `${newDate} ${newTime}`;
};

export const dateBasic = (date) => {
  // Output format yyyy-mm-dd

  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const day = newDate.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const getTime = (date) => {
  // Output format hh:mm:ss

  const newDate = dateWithTimezone(date);
  return newDate.split(' ')[1];
};

export const toDateTimePicker = (date) => {
  // Output format aaaa-mmmm-ddddThh:mm

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const timeToSave = (date) => {
  // Output format aaaa-mmmm-ddddThh:mm:ss±HH:mm (ISO 8601)

  const formatDate = new Date(date);

  const year = formatDate.getFullYear();
  const month = (formatDate.getMonth() + 1).toString().padStart(2, '0');
  const day = formatDate.getDate().toString().padStart(2, '0');
  const hours = formatDate.getHours().toString().padStart(2, '0');
  const minutes = formatDate.getMinutes().toString().padStart(2, '0');

  const timeZoneOffset = formatDate.getTimezoneOffset();
  const timeZoneOffsetHours = Math.floor(Math.abs(timeZoneOffset) / 60)
    .toString()
    .padStart(2, '0');
  const timeZoneOffsetMinutes = (Math.abs(timeZoneOffset) % 60)
    .toString()
    .padStart(2, '0');
  const timeZoneSign = timeZoneOffset >= 0 ? '-' : '+';

  return `${year}-${month}-${day}T${hours}:${minutes}:00${timeZoneSign}${timeZoneOffsetHours}:${timeZoneOffsetMinutes}`;
};

export const getHoursAndMinutes = (time) => {
  const format = time.split(':');
  const hours = format[0].padStart(2, 0);
  const minutes = format[1].padStart(2, 0);

  return `${hours}:${minutes}`;
};

export const formatDateToGantt = (date) => {
  // Output format aaaa-mmmm-dddd hh:mm:ss

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const convertSecondsToHours = (secondsNumber) => {
  // Output format hh:mm:ss

  let isNegative = '';
  if (secondsNumber < 0) {
    isNegative = '-';
    secondsNumber *= -1;
  }

  const hours = Math.floor(secondsNumber / 3600)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((secondsNumber % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (secondsNumber % 60).toString().padStart(2, '0');

  return `${isNegative}${hours}:${minutes}:${seconds}`;
};
