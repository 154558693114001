import { axiosSesion } from '../axios';

// Get all menus
export const resultMenuInformations = (Authorization) => {
  return axiosSesion.get('/api/v1/menu_informations', {
    headers: { Authorization },
  });
};

// Get menu by ID
export const resultEditIdMenu = (Authorization, id) => {
  return axiosSesion.get(`/api/v1/menus/${id}`, {
    headers: { Authorization },
  });
};

// Create new menu
export const resultNewMenu = (
  Authorization,
  customPermissionId,
  nameEn,
  nameEs,
  path,
  level,
  icon,
) => {
  return axiosSesion.post(
    '/api/v1/menus',
    {
      menu: {
        custom_permission_id: customPermissionId,
        name_en: nameEn,
        name_es: nameEs,
        path,
        level,
        icon,
      },
    },
    {
      headers: { Authorization },
    },
  );
};

// Update menu
export const resultUpdateMenu = (
  Authorization,
  id,
  customPermissionId,
  nameEn,
  nameEs,
  path,
  level,
  icon,
) => {
  return axiosSesion.put(
    `/api/v1/menus/${id}`,
    {
      menu: {
        custom_permission_id: customPermissionId,
        name_en: nameEn,
        name_es: nameEs,
        path,
        level,
        icon,
      },
    },
    {
      headers: { Authorization },
    },
  );
};
