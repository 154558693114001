import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LANGUAGES_FILES } from '../../constants/enums';

export default function FootTable({ handlePerPage, entries }) {
  const { numStart, numEnd, total } = entries;
  const [t] = useTranslation(LANGUAGES_FILES.GLOBAL);
  const [perPage, setPerPage] = useState(15);

  const handleChange = (event) => {
    const { value } = event.target;
    setPerPage(value);
    handlePerPage(value);
  };

  return (
    <section
      className="
        w-full text-[13px] flex flex-col gap-2 xs:mb-2 xs:pl-2 sm:flex-row sm:gap-0
        sm:justify-between sm:pl-4 sm:pr-8 sm:mb-0 select-none
      "
    >
      <span className="font-semibold">
        {`${t(
          'global.layout.tables.foot-table.quantity-registers.start',
        )} ${numStart} ${t(
          'global.layout.tables.foot-table.quantity-registers.middle1',
        )} ${numEnd} ${t(
          'global.layout.tables.foot-table.quantity-registers.middle2',
        )} ${total} ${t(
          'global.layout.tables.foot-table.quantity-registers.end',
        )}`}
      </span>

      <div className="flex gap-2">
        <span className="font-semibold">
          {t('global.layout.tables.foot-table.rows-per-page')}
        </span>
        <select
          className="w-12 pl-1 focus:outline-none rounded-md"
          style={{ border: '1px solid #a3a3a3' }}
          value={perPage}
          onChange={handleChange}
        >
          <option value="15">15</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="70">70</option>
        </select>
      </div>
    </section>
  );
}
