import { axiosSesion } from '../axios';

// Get all company services
export const resultCompanyServices = (Authorization) => {
  return axiosSesion.get('/api/v1/company_services', {
    headers: { Authorization },
  });
};

// Get company service by ID
export const resultGetCompaniesServicesId = (Authorization, id) => {
  return axiosSesion.get(`/api/v1/company_services/${id}`, {
    headers: { Authorization },
  });
};

// Create new company service
export const resultNewCompanyServices = (Authorization, newComServices) => {
  return axiosSesion.post('/api/v1/company_services', newComServices, {
    headers: { Authorization },
  });
};

// Update company service
export const resultUpdateCompanyServices = (Authorization, id, updated) => {
  return axiosSesion.put(`/api/v1/company_services/${id}`, updated, {
    headers: { Authorization },
  });
};
