import {
  SET_GET_COMPANIES,
  SET_COMPANY_SERVICES,
  SET_GET_COMPANY_ID,
  SET_GET_COMPANY_SERVICES,
  SET_GET_COMPANIES_SERVICES_ID,
} from '../types/companiesTypes';

const initialState = {
  resultGetCompanies: [],
  resultCompanyServices: [],
  resultGetCompanyId: [],
  resultGetCompanyServices: [],
  resultGetCompaniesServicesId: [],
};

function companiesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_GET_COMPANIES:
      return { ...state, resultGetCompanies: action.payload };

    case SET_COMPANY_SERVICES:
      return { ...state, resultCompanyServices: action.payload };

    case SET_GET_COMPANY_ID:
      return { ...state, resultGetCompanyId: action.payload };

    case SET_GET_COMPANY_SERVICES:
      return { ...state, resultGetCompanyServices: action.payload };

    case SET_GET_COMPANIES_SERVICES_ID:
      return { ...state, resultGetCompaniesServicesId: action.payload };

    default:
      return state;
  }
}

export default companiesReducer;
