import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ALERT_TYPE, LANGUAGES_FILES } from '../../../constants/enums';

import { createNewAlert } from '../../../redux/reducer/alert-messages/alerts.actions';

import CheckBoxBasic from '../../../components/CheckBoxBasic';

export default function Itinerary({
  disabled,
  flightInfo,
  handleSubmit,
  update,
  setUpdate,
  setWithItinerary,
}) {
  const dispatch = useDispatch();
  const [t] = useTranslation(LANGUAGES_FILES.FLIGHTS);

  const [monday, setMonday] = useState(() => {
    return flightInfo?.monday ? flightInfo.monday : false;
  });
  const [tuesday, setTuesday] = useState(() => {
    return flightInfo?.tuesday ? flightInfo.tuesday : false;
  });
  const [wednesday, setWednesday] = useState(() => {
    return flightInfo?.wednesday ? flightInfo.wednesday : false;
  });
  const [thursday, setThursday] = useState(() => {
    return flightInfo?.thursday ? flightInfo.thursday : false;
  });
  const [friday, setFriday] = useState(() => {
    return flightInfo?.friday ? flightInfo.friday : false;
  });
  const [saturday, setSaturday] = useState(() => {
    return flightInfo?.saturday ? flightInfo.saturday : false;
  });
  const [sunday, setSunday] = useState(() => {
    return flightInfo?.sunday ? flightInfo.sunday : false;
  });

  const [mondayDep, setMondayDep] = useState(() => {
    return flightInfo?.mondayDep ? flightInfo.mondayDep : '';
  });
  const [mondayArr, setMondayArr] = useState(() => {
    return flightInfo?.mondayArr ? flightInfo.mondayArr : '';
  });
  const [tuesdayDep, setTuesdayDep] = useState(() => {
    return flightInfo?.tuesdayDep ? flightInfo.tuesdayDep : '';
  });
  const [tuesdayArr, setTuesdayArr] = useState(() => {
    return flightInfo?.tuesdayArr ? flightInfo.tuesdayArr : '';
  });
  const [wednesdayDep, setWednesdayDep] = useState(() => {
    return flightInfo?.wednesdayDep ? flightInfo.wednesdayDep : '';
  });
  const [wednesdayArr, setWednesdayArr] = useState(() => {
    return flightInfo?.wednesdayArr ? flightInfo.wednesdayArr : '';
  });
  const [thursdayDep, setThursdayDep] = useState(() => {
    return flightInfo?.thursdayDep ? flightInfo.thursdayDep : '';
  });
  const [thursdayArr, setThursdayArr] = useState(() => {
    return flightInfo?.thursdayArr ? flightInfo.thursdayArr : '';
  });
  const [fridayDep, setFridayDep] = useState(() => {
    return flightInfo?.fridayDep ? flightInfo.fridayDep : '';
  });
  const [fridayArr, setFridayArr] = useState(() => {
    return flightInfo?.fridayArr ? flightInfo.fridayArr : '';
  });
  const [saturdayDep, setSaturdayDep] = useState(() => {
    return flightInfo?.saturdayDep ? flightInfo.saturdayDep : '';
  });
  const [saturdayArr, setSaturdayArr] = useState(() => {
    return flightInfo?.saturdayArr ? flightInfo.saturdayArr : '';
  });
  const [sundayDep, setSundayDep] = useState(() => {
    return flightInfo?.sundayDep ? flightInfo.sundayDep : '';
  });
  const [sundayArr, setSundayArr] = useState(() => {
    return flightInfo?.sundayArr ? flightInfo.sundayArr : '';
  });

  const validateItinerary = () => {
    const states = {
      monday: { isActive: monday, dep: mondayDep, arr: mondayArr },
      tuesday: { isActive: tuesday, dep: tuesdayDep, arr: tuesdayArr },
      wednesday: { isActive: wednesday, dep: wednesdayDep, arr: wednesdayArr },
      thursday: { isActive: thursday, dep: thursdayDep, arr: thursdayArr },
      friday: { isActive: friday, dep: fridayDep, arr: fridayArr },
      saturday: { isActive: saturday, dep: saturdayDep, arr: saturdayArr },
      sunday: { isActive: sunday, dep: sundayDep, arr: sundayArr },
    };

    let message = setWithItinerary ? t('frequent-flights.views.index.messages.web.error.configure-day') : '';

    for (const day in states) {
      if (states[day].isActive) {
        if (!states[day].dep || !states[day].arr) {
          message = t('frequent-flights.views.index.messages.web.error.configure-time');
        } else {
          message = '';
        }
      }
    }

    return message;
  };

  useEffect(() => {
    if (setWithItinerary) {
      if (
        !monday &&
        !tuesday &&
        !wednesday &&
        !thursday &&
        !friday &&
        !saturday &&
        !sunday
      ) {
        setWithItinerary(false);      
      } else {
        setWithItinerary(true);
      }
    }
  }, [monday, tuesday, wednesday, thursday, friday, saturday, sunday]);

  useEffect(() => {
    const id = flightInfo?.id ? flightInfo.id : null;
    if (update) {
      setUpdate(false);

      const errorMessage = validateItinerary();

      if (errorMessage) {
        dispatch(
          createNewAlert({
            newAlert: true,
            type: ALERT_TYPE.ERROR,
            message: errorMessage,
            languageFile: LANGUAGES_FILES.FLIGHTS,
          }),
        );
        return;
      } else {
        handleSubmit(id, {
          monday,
          monday_dep_scheduled_at: mondayDep ? mondayDep + ':00' : '',
          monday_arr_scheduled_at: mondayArr ? mondayArr + ':00' : '',
          tuesday,
          tuesday_dep_scheduled_at: tuesdayDep ? tuesdayDep + ':00' : '',
          tuesday_arr_scheduled_at: tuesdayArr ? tuesdayArr + ':00' : '',
          wednesday,
          wednesday_dep_scheduled_at: wednesdayDep ? wednesdayDep + ':00' : '',
          wednesday_arr_scheduled_at: wednesdayArr ? wednesdayArr + ':00' : '',
          thursday,
          thursday_dep_scheduled_at: thursdayDep ? thursdayDep + ':00' : '',
          thursday_arr_scheduled_at: thursdayArr ? thursdayArr + ':00' : '',
          friday,
          friday_dep_scheduled_at: fridayDep ? fridayDep + ':00' : '',
          friday_arr_scheduled_at: fridayArr ? fridayArr + ':00' : '',
          saturday,
          saturday_dep_scheduled_at: saturdayDep ? saturdayDep + ':00' : '',
          saturday_arr_scheduled_at: saturdayArr ? saturdayArr + ':00' : '',
          sunday,
          sunday_dep_scheduled_at: sundayDep ? sundayDep + ':00' : '',
          sunday_arr_scheduled_at: sundayArr ? sundayArr + ':00' : '',
        });
      }      
    }
  }, [update]);

  return (
    <main
      className="w-full rounded-[6px] px-2 pt-4 pb-3"
      style={{ border: '1px solid #d4d4d4' }}
    >
      <table className="w-full text-sm">
        <thead>
          <tr
            className="text-[15px] font-semibold text-center"
            style={{ borderBottom: '1px solid #d4d4d4' }}
          >
            <th className="py-2 px-5">
              {t(
                'flights.views.new.layout.itinerary-section.table.headers.day',
              )}
            </th>
            <th className="py-2 px-4">
              {t(
                'flights.views.new.layout.itinerary-section.table.headers.schedule-departure-time',
              )}
            </th>
            <th className="py-2 px-4">
              {t(
                'flights.views.new.layout.itinerary-section.table.headers.schedule-arrival-time',
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          <DayRow
            check={monday}
            setCheck={setMonday}
            disabled={disabled}
            dayName={'mon'}
            defaultDeparture={mondayDep}
            defaultArrival={mondayArr}
            setDeparture={setMondayDep}
            setArrival={setMondayArr}
          />

          <DayRow
            check={tuesday}
            setCheck={setTuesday}
            disabled={disabled}
            dayName={'tue'}
            defaultDeparture={tuesdayDep}
            defaultArrival={tuesdayArr}
            setDeparture={setTuesdayDep}
            setArrival={setTuesdayArr}
          />

          <DayRow
            check={wednesday}
            setCheck={setWednesday}
            disabled={disabled}
            dayName={'wed'}
            defaultDeparture={wednesdayDep}
            defaultArrival={wednesdayArr}
            setDeparture={setWednesdayDep}
            setArrival={setWednesdayArr}
          />

          <DayRow
            check={thursday}
            setCheck={setThursday}
            disabled={disabled}
            dayName={'thu'}
            defaultDeparture={thursdayDep}
            defaultArrival={thursdayArr}
            setDeparture={setThursdayDep}
            setArrival={setThursdayArr}
          />

          <DayRow
            check={friday}
            setCheck={setFriday}
            disabled={disabled}
            dayName={'fri'}
            defaultDeparture={fridayDep}
            defaultArrival={fridayArr}
            setDeparture={setFridayDep}
            setArrival={setFridayArr}
          />

          <DayRow
            check={saturday}
            setCheck={setSaturday}
            disabled={disabled}
            dayName={'sat'}
            defaultDeparture={saturdayDep}
            defaultArrival={saturdayArr}
            setDeparture={setSaturdayDep}
            setArrival={setSaturdayArr}
          />

          <DayRow
            check={sunday}
            setCheck={setSunday}
            disabled={disabled}
            dayName={'sun'}
            defaultDeparture={sundayDep}
            defaultArrival={sundayArr}
            setDeparture={setSundayDep}
            setArrival={setSundayArr}
          />
        </tbody>
      </table>
    </main>
  );
}

const DayRow = ({
  check,
  setCheck,
  disabled,
  dayName,
  defaultDeparture,
  defaultArrival,
  setDeparture,
  setArrival,
}) => {
  const [t] = useTranslation(LANGUAGES_FILES.FLIGHTS);

  const basic = 'flights.views.new.layout.itinerary-section.table.days.';
  const label = `${basic}${dayName}`;

  const isBlock = disabled ? true : !check ? true : false;

  const handleChange = (event) => {
    const { value, name } = event.target;

    if (name === 'departure') {
      setDeparture(value);
    } else {
      setArrival(value);
    }
  };

  useEffect(() => {
    if (!check) {
      setDeparture('');
      setArrival('');
    }
  }, [check]);

  return (
    <tr
      className="h-[50px] bg-neutral-50"
      style={{ borderBottom: '1px solid #d4d4d4' }}
    >
      <td className="h-[50px]">
        <div className="h-[50px] flex items-center gap-[10px] pl-2">
          <CheckBoxBasic
            checked={check}
            setParams={setCheck}
            disabled={disabled}
          />

          <span
            className={`font-semibold text-[14px] ${
              disabled ? 'text-gray-300' : 'text-[#273447]'
            }`}
          >
            {t(label)}
          </span>
        </div>
      </td>

      <td>
        <div className="w-full h-[50px] flex justify-center items-center">
          <input
            className="min-w-[200px] h-[30px] w-1/2 pr-2 pl-3 rounded-[10px] shadow-md outline-none focus:outline-none"
            type="time"
            value={defaultDeparture}
            name="departure"
            onChange={handleChange}
            disabled={isBlock}
          />
        </div>
      </td>

      <td>
        <div className="w-full h-[50px] flex justify-center items-center">
          <input
            className="min-w-[200px] h-[30px] w-1/2 pr-2 pl-3 rounded-[10px] shadow-md outline-none focus:outline-none"
            type="time"
            value={defaultArrival}
            name="arrival"
            onChange={handleChange}
            disabled={isBlock}
          />
        </div>
      </td>
    </tr>
  );
};
