import { axiosSesion } from '../axios';

// Get all job positions
export const resultJobPositions = (Authorization) => {
  return axiosSesion.get('/api/v1/job_positions', {
    headers: { Authorization },
  });
};

// Get job position by ID
export const resultGetJobPositionId = (Authorization, id) => {
  return axiosSesion.get(`/api/v1/job_positions/${id}`, {
    headers: { Authorization },
  });
};

// Create a new job position
export const resultNewJobPositions = (Authorization, newJobPosition) => {
  return axiosSesion.post('/api/v1/job_positions', newJobPosition, {
    headers: { Authorization },
  });
};

// Update a job position
export const resultUpdateJobPositions = (
  Authorization,
  id,
  jobPositionUpdate,
) => {
  return axiosSesion.put(`/api/v1/job_positions/${id}`, jobPositionUpdate, {
    headers: { Authorization },
  });
};
