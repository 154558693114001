import { axiosSesion } from '../axios';

// Get flights by airport and airline
export const resultFlightsFromAirlinesAndAirports = (
  Authorization,
  airportId,
  airlineId,
) => {
  return axiosSesion.get(
    `api/v1/flights/flights_station_airline/${airportId}/${airlineId}`,
    {
      headers: { Authorization },
    },
  );
};

// Filter flights by user - filter 1
export const resultFilterFlights = (
  Authorization,
  idUser,
  iata_flight,
  date,
  airline_id,
  station_id,
  flight_status,
  flight_type,
  flight_records,
) => {
  const sParams = `[iata_flight]=${iata_flight}&[date]=${date}&[airline_id]=${airline_id}&[station_id]=${station_id}&[flight_status]=${flight_status}&[flight_type]=${flight_type}&[flight_records]=${flight_records}`;
  return axiosSesion.get(`/api/v1/users/${idUser}/allowed_flights?${sParams}`, {
    headers: { Authorization },
  });
};

// Filter flights by user - filter 2
export const resultFilterFlightsParameterization = (
  Authorization,
  idUser,
  iata_flight,
  airline_id,
  station_id,
  flight_type,
  flight_configured,
) => {
  const sParams = `[iata_flight]=${iata_flight}&[airline_id]=${airline_id}&[station_id]=${station_id}&[flight_type]=${flight_type}&[flight_configured]=${flight_configured}`;
  return axiosSesion.get(`/api/v1/users/${idUser}/allowed_flights?${sParams}`, {
    headers: { Authorization },
  });
};

// Get today's flights
export const resultFlightsToday = (Authorization) => {
  return axiosSesion.get('/api/v1/flights/flights_today', {
    headers: { Authorization },
  });
};

// Create a new flight
export const resultNewFlight = (
  Authorization,
  iata_flight,
  flight_type,
  airline_id,
  iata_departure_airport,
  iata_arrival_airport,
  departure_scheduled_time,
  arrival_scheduled_time,
  station_id,
  flight_number,
  monday,
  monday_dep_scheduled_at,
  monday_arr_scheduled_at,
  tuesday,
  tuesday_dep_scheduled_at,
  tuesday_arr_scheduled_at,
  wednesday,
  wednesday_dep_scheduled_at,
  wednesday_arr_scheduled_at,
  thursday,
  thursday_dep_scheduled_at,
  thursday_arr_scheduled_at,
  friday,
  friday_dep_scheduled_at,
  friday_arr_scheduled_at,
  saturday,
  saturday_dep_scheduled_at,
  saturday_arr_scheduled_at,
  sunday,
  sunday_dep_scheduled_at,
  sunday_arr_scheduled_at,
) => {
  return axiosSesion.post(
    '/api/v1/flights',
    {
      flight: {
        iata_flight: `${iata_flight}`,
        flight_type: `${flight_type}`,
        airline_id: `${airline_id}`,
        iata_departure_airport: `${iata_departure_airport}`,
        iata_arrival_airport: `${iata_arrival_airport}`,
        departure_scheduled_time: `${departure_scheduled_time}`,
        arrival_scheduled_time: `${arrival_scheduled_time}`,
        station_id: `${station_id}`,
        flight_number: `${flight_number}`,
        departure_delay: null,
        departure_estimated_time: null,
        departure_actual_time: null,
        departure_estimated_runway: null,
        departure_actual_runway: null,
        arrival_estimated_time_utc: null,
        arrival_actual_time_utc: null,
        arrival_estimated_runway_utc: null,
        arrival_actual_runway_utc: null,
        user_id: 1,
        flight_status: 'scheduled',
        is_enabled: true,
        departure_scheduled_time_utc: new Date(departure_scheduled_time).toISOString(),
        arrival_scheduled_time_utc: new Date(arrival_scheduled_time).toISOString(),
        frequency_flights: [
          {
            monday: `${monday}`,
            monday_dep_scheduled_at: `${monday_dep_scheduled_at}`,
            monday_arr_scheduled_at: `${monday_arr_scheduled_at}`,
            tuesday: `${tuesday}`,
            tuesday_dep_scheduled_at: `${tuesday_dep_scheduled_at}`,
            tuesday_arr_scheduled_at: `${tuesday_arr_scheduled_at}`,
            wednesday: `${wednesday}`,
            wednesday_dep_scheduled_at: `${wednesday_dep_scheduled_at}`,
            wednesday_arr_scheduled_at: `${wednesday_arr_scheduled_at}`,
            thursday: `${thursday}`,
            thursday_dep_scheduled_at: `${thursday_dep_scheduled_at}`,
            thursday_arr_scheduled_at: `${thursday_arr_scheduled_at}`,
            friday: `${friday}`,
            friday_dep_scheduled_at: `${friday_dep_scheduled_at}`,
            friday_arr_scheduled_at: `${friday_arr_scheduled_at}`,
            saturday: `${saturday}`,
            saturday_dep_scheduled_at: `${saturday_dep_scheduled_at}`,
            saturday_arr_scheduled_at: `${saturday_arr_scheduled_at}`,
            sunday: `${sunday}`,
            sunday_dep_scheduled_at: `${sunday_dep_scheduled_at}`,
            sunday_arr_scheduled_at: `${sunday_arr_scheduled_at}`,
          },
        ],
      },
    },
    {
      headers: { Authorization },
    },
  );
};

// Create a new flight with itinerary
export const resultNewFlightWithoutItinerary = (
  Authorization,
  iata_flight,
  flight_type,
  airline_id,
  iata_departure_airport,
  iata_arrival_airport,
  departure_scheduled_time,
  arrival_scheduled_time,
  station_id,
  flight_number,
) => {
  return axiosSesion.post(
    '/api/v1/flights',
    {
      flight: {
        iata_flight: `${iata_flight}`,
        flight_type: `${flight_type}`,
        airline_id: `${airline_id}`,
        iata_departure_airport: `${iata_departure_airport}`,
        iata_arrival_airport: `${iata_arrival_airport}`,
        departure_scheduled_time: `${departure_scheduled_time}`,
        arrival_scheduled_time: `${arrival_scheduled_time}`,
        station_id: `${station_id}`,
        flight_number: `${flight_number}`,
        departure_delay: null,
        departure_estimated_time: null,
        departure_actual_time: null,
        departure_estimated_runway: null,
        departure_actual_runway: null,
        arrival_estimated_time_utc: null,
        arrival_actual_time_utc: null,
        arrival_estimated_runway_utc: null,
        arrival_actual_runway_utc: null,
        user_id: 1,
        is_enabled: true,
        flight_status: 'scheduled',
        departure_scheduled_time_utc: new Date(departure_scheduled_time).toISOString(),
        arrival_scheduled_time_utc: new Date(arrival_scheduled_time).toISOString(),
      },
    },
    {
      headers: { Authorization },
    },
  );
};

// Edit flight
export const resultEditFlight = (Authorization, id, array) => {
  return axiosSesion.patch(
    `/api/v1/flights/${id}/activate_flight`,
    {
      flight: { user_id: 1 },
      flights_itineraries_areas: array,
    },
    {
      headers: { Authorization },
    },
  );
};

// Get list of iata_flights
export const resultIataFlightList = (Authorization, text, signal) => {
  return axiosSesion.get('/api/v1/flights/by_params', {
    headers: { Authorization },
    params: text,
    signal,
  });
};

// Get last flight by iata_flight
export const resultLastFlight = (Authorization, iataFlight) => {
  return axiosSesion.get(
    `/api/v1/flights/last_flight_iata_flight/${iataFlight}`,
    { headers: { Authorization } },
  );
};

// Get flights by params
export const getFlightsByParams = (Authorization, type, params) => {
  return axiosSesion.get(
    `/api/v1/flights/by_params?${type}${params ? params : ''}`,
    {
      headers: { Authorization },
    },
  );
};

// Get flights configuration
export const getFligthsConfigurations = (Authorization) => {
  return axiosSesion.get('/flight_configurations/flights_enabled', {
    headers: { Authorization },
  });
};

// Get all iata flights
export const getAllIataFlights = (Authorization, userId) => {
  return axiosSesion.get('/api/v1/flights/all_iata_flights', {
    headers: { Authorization },
    params: { user_id: userId },
  });
};

// Get total records by company service
export const getRecordsByCompanyService = (Authorization, flightId) => {
  return axiosSesion.get(
    `/api/v1/flights/get_total_records_by_company_service/${flightId}`,
    { headers: { Authorization } },
  );
};

// Get cities by flight
export const getCitiesByFlight = (Authorization, flightId) => {
  return axiosSesion.get(`/api/v1/flights/get_cities_by_flight/${flightId}`, {
    headers: { Authorization },
  });
};

// Get flights by filters
export const flightsByFilters = (Authorization, params) => {
  return axiosSesion.get('/api/v1/flights/by_filters', {
    headers: { Authorization },
    params,
  });
};
