import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LiaPowerOffSolid } from 'react-icons/lia';

import { LANGUAGES_FILES, ALERT_TYPE } from '../../../constants/enums';

import { createNewAlert } from '../../../redux/reducer/alert-messages/alerts.actions';

import { logOut } from '../../../services/api/v1/user_requests/userRequests';
import {
  setAuthorization,
  setInformationUser,
} from '../../../redux/reducer/authorization/actions/authorizationsActions';

import DecryptedToken from '../../../components/security_components/decrypted_token/decrypted_token';

export default function LogOutButton() {
  const authorization = DecryptedToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t] = useTranslation(LANGUAGES_FILES.GLOBAL);
  const { isContractedMenu } = useSelector((state) => state.contractedMenu);

  const handleLogout = async () => {    
    try {
      await logOut(authorization);
      dispatch(setAuthorization(''));
      dispatch(setInformationUser([]));
      navigate('/login');
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t(
            'global.messages.api.error.connection',
          ),
          languageFile: LANGUAGES_FILES.GLOBAL,
        }),
      )
    }
  }

  return (
    <section
      className="w-full text-sm"
      style={{ borderTop: '1px solid #2f3d53' }}
    >
      <button
        className="text-[#FFFFFF] hover:text-[#50B8E4] hover:bg-[#303750] py-2 flex justify-center items-center gap-2 w-full"
        onClick={handleLogout}
      >
        <LiaPowerOffSolid size={23} />
        {!isContractedMenu ? (
          <span className="pt-[2px]">
            {t('global.layout.sidebar.buttons.log-out')}
          </span>
        ) : null}
      </button>
    </section>
  );
}
