export const SET_CUSTOM_PERMISSIONS = 'SET_CUSTOM_PERMISSIONS';
export const SET_APPLY_PERMISSION = 'SET_APPLY_PERMISSION';
export const SET_PERMISSION_TYPES = 'SET_PERMISSION_TYPES';
export const SET_NEW_PERMISSION_TYPE = 'SET_NEW_PERMISSION_TYPE';
export const SET_GET_PERMISSION_TYPE_ID = 'SET_GET_PERMISSION_TYPE_ID';
export const SET_UPDATE_PERMISSION_TYPE = 'SET_UPDATE_PERMISSION_TYPE';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_NEW_PERMISSION = 'SET_NEW_PERMISSION';
export const SET_GET_PERMISSION_ID = 'SET_GET_PERMISSION_ID';
export const SET_UPDATE_PERMISSION = 'SET_UPDATE_PERMISSION';
export const SET_APPLY_PERMISSION_USER = 'SET_APPLY_PERMISSION_USER';
export const SET_FLIGHT_PERMISSION = 'SET_FLIGHT_PERMISSION';
export const SET_PERMISSION_APPLY = 'SET_PERMISSION_APPLY';
