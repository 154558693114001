import { useEffect, useState } from 'react';
import { AiOutlineClear } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';

export default function SearchBar({
  list,
  handleFilter,
  columnsToSearch,
  placeholder,
  disabled,
  update,
}) {
  const [searchText, setSearchText] = useState('');
  const [copyList, setCopyList] = useState([]);

  const handleChange = (event) => {
    const { value } = event.target;
    setSearchText(value);

    let filtered = [];
    columnsToSearch.forEach((column) => {
      copyList.forEach((item) => {
        if (item[column]?.toLowerCase().includes(value.toLowerCase())) {
          const found = filtered.find((element) => element.id === item.id);
          if (!found) filtered.push(item);
        }
      });
    });

    handleFilter(filtered);
  };

  const handleClear = () => {
    setSearchText('');
    handleFilter(copyList);
  };

  useEffect(() => {
    setCopyList(list);
    setSearchText('');
  }, [update]);

  useEffect(() => {
    if (!copyList.length) setCopyList(list);
  }, [list]);

  return (
    <main className="min-w-[250px] w-1/2 md:w-2/3 text-sm">
      <section
        className="w-full h-8 flex justify-between items-center pr-2 pl-[12px] py-[1px] mb-[3px] mt-1 rounded-[10px] shadow-md"
        style={{ border: '1px solid #a3a3a3' }}
      >
        <input
          className="bg-transparent focus:outline-none w-full pt-[2px] pr-2 disabled:text-gray-300"
          type="text"
          value={searchText}
          placeholder={placeholder ? placeholder : ''}
          disabled={disabled}
          onChange={handleChange}
        />

        <button
          className={`${!searchText ? 'hidden' : ''} ${
            disabled ? 'text-gray-300' : 'text-[#50B8E4] hover:text-[#273447]'
          }`}
          onClick={handleClear}
          disabled={disabled}
        >
          <AiOutlineClear size={20} />
        </button>

        <span
          className={`${searchText && 'hidden'} ${
            disabled ? 'text-gray-300' : 'text-[#50B8E4]'
          }`}
          disabled={disabled}
        >
          <BsSearch size={20} />
        </span>
      </section>
    </main>
  );
}
