import { SET_FOOTER_FIXED } from '../types/footerTypes';

const initialState = {
  footerFixed: false,
};

function footerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FOOTER_FIXED:
      return { ...state, footerFixed: action.payload };

    default:
      return state;
  }
}

export default footerReducer;
