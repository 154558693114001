// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import Flights from '../../pages/flights_page/components/Flights';

// Pages imports with lazy loading
const NewFlight = lazy(() =>
  import('../../pages/flights_page/components/NewFlight'),
);
const FlightDetail = lazy(() =>
  import('../../pages/flights_page/components/FlightDetail'),
);
const EditOperator = lazy(() =>
  import('../../pages/flights_page/components/EditOperator'),
);
const EditMatch = lazy(() =>
  import('../../pages/flights_page/components/EditMatch'),
);

function FlightsRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const flightsRoutes = [
    {
      path: 'flights',
      element: <Flights />,
      isAllowed: customPermission.flight_index,
    },
    {
      path: 'flights/new',
      element: <NewFlight />,
      isAllowed: customPermission.flight_create,
    },
    {
      path: 'flights/show/:id',
      element: <FlightDetail />,
      isAllowed: customPermission.flight_show,
    },
    {
      path: 'flights/edit_operator/:ioo_id/flight_id/:flight_id',
      element: <EditOperator />,
      isAllowed: customPermission.edit_in_out_operators,
    },
    {
      path: 'edit_match/in_out_operators/:ioo_id/flight_id/:flight_id/header_format_id/:hf_id',
      element: <EditMatch />,
      isAllowed: customPermission.edit_in_out_operators,
    },
  ];

  return flightsRoutes;
}

export default FlightsRoutes;
