import {
  SET_IDENTIFICATION_TYPE,
  SET_NEW_USER_IDENTIFICATION_TYPE,
  SET_UPDATE_IDENTIFICATION_TYPE,
  SET_USER_IDENTIFICATION_TYPE_ID,
} from '../types/identificationsTypes';

const initialState = {
  resultIdentificationType: [],
  resultNewUserIdentificationType: [],
  resultUserIdentificationTypeId: [],
  resultUpdateIdentificationType: [],
};

function identificationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_IDENTIFICATION_TYPE:
      return { ...state, resultIdentificationType: action.payload };

    case SET_NEW_USER_IDENTIFICATION_TYPE:
      return { ...state, resultNewUserIdentificationType: action.payload };

    case SET_USER_IDENTIFICATION_TYPE_ID:
      return { ...state, resultUserIdentificationTypeId: action.payload };

    case SET_UPDATE_IDENTIFICATION_TYPE:
      return { ...state, resultUpdateIdentificationType: action.payload };

    default:
      return state;
  }
}

export default identificationsReducer;
