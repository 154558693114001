import {
  SET_HEADER_FIXED,
  SET_HEADER_SEARCH_HOVER,
  SET_HEADER_SHADOW,
} from '../types/headerTypes';

const initialState = {
  headerFixed: true,
  headerShadow: true,
  headerSearchHover: false,
};

function headerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_HEADER_FIXED:
      return { ...state, headerFixed: action.payload };

    case SET_HEADER_SHADOW:
      return { ...state, headerShadow: action.payload };

    case SET_HEADER_SEARCH_HOVER:
      return { ...state, headerSearchHover: action.payload };

    default:
      return state;
  }
}

export default headerReducer;
