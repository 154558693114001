import { dateWithTimezone } from '../../../utils/dateFormatter';

export const settingAdapter = (data, handleModal) => {
  const response = [];
  data.forEach((item) => {
    response.push({
      id: item.id,
      code: item.code,
      description: item.description,
      nameEn: item.name_en,
      nameEs: item.name_es,
      value: item.setting_value,
      isEnabled: item.is_enabled,
      createdAt: dateWithTimezone(item.created_at),
      handleModal,
    });
  });

  response.sort(function(a, b) {
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;
    return 0;
  })

  return response;
};

export const currentSettingAdapter = (data) => {
  return {
    id: data.id,
    code: data.code,
    description: data.description,
    nameEn: data.name_en,
    nameEs: data.name_es,
    value: data.setting_value,
    isEnabled: data.is_enabled,
  };
}
