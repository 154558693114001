import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LANGUAGES_FILES, ALERT_TYPE } from '../../../constants/enums';

import { resultUserPermissions } from '../../../services/api/v1/permission_requests/permissionRequests';
import { airlinesAllowedPerUser } from '../../../services/api/v1/user_requests/userRequests';
import { stationsAllowedPerUser } from '../../../services/api/v1/user_requests/userRequests';

import {
  updateFrequencyFlight,
  getFrequencyFlightsFilter,
  getFrequencyFlightIataFlights,
  getFrequencyFlightAirlines,
  getFrequencyFlightAirports,
} from '../../../services/api/v1/frequency_flights_requests/frequencyFlightsRequests';

import { createNewAlert } from '../../../redux/reducer/alert-messages/alerts.actions';
import {
  setNavbarInfo,
  clearNavbar,
} from '../../../redux/reducer/navbar/navbar.reducers';

import {
  iataCodeListAdapter,
  airlinesListAdapter,
  airportsListAdapter,
  frequentFlightsAdapter,
} from '../adapters/frequentFlights';

import RowsTableFrequentFlights from './table-frequent-flights/RowsTableFrequentFlights';
import Modal from '../../../components/Modal';
import Itinerary from '../../flights_page/components/Itinerary';
import SecondLoading from '../../../components/SecondLoading';
import ButtonGeneral from '../../../components/ButtonGeneral';
import ButtonCancel from '../../../components/ButtonCancel';
import SelectAutocomplete from '../../../components/SelectAutocomplete';
import DataTable from '../../../components/data_table/DataTable';
import SearchBar from '../../../components/SearchBar';

import DecryptedToken from '../../../components/security_components/decrypted_token/decrypted_token';

const columnsToSearch = [
  'flightCode',
  'scheduledDepartureTime',
  'scheduledArrivalTime',
  'flightStatus',
];

export default function FrequencyFlights() {
  const [t, e] = useTranslation(LANGUAGES_FILES.FLIGHTS);
  const authorization = DecryptedToken();
  const dispatch = useDispatch();

  const [updateSearch, setUpdateSearch] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [flightInfo, setFlightInfo] = useState(null);
  const [update, setUpdate] = useState(false);

  const [flights, setFlights] = useState({ columns: [], rows: [] });

  const { id } = useSelector((state) => state.authorization.informationUser);

  const [canEditFrequentFlight, setCanEditFrequentFlight] = useState(false);
  const [canDisableFrequentFlight, setCanDisableFrequentFlight] =
    useState(false);

  const [firstRender, setFirstRender] = useState(false);
  const [flightCodes, setFlightCodes] = useState(null);
  const [flightAirlines, setFlightAirlines] = useState(null);
  const [flightAirports, setFlightAirports] = useState(null);
  const [filters, setFilters] = useState({});

  const columns = [
    {
      id: 1,
      title: t(
        'frequent-flights.views.index.layout.tables.table-frequent-flights.tabla.header.code',
      ),
      sortable: true,
      sortBy: 'flightCode',
      width: 130,
    },
    {
      id: 2,
      title: t(
        'frequent-flights.views.index.layout.tables.table-frequent-flights.tabla.header.scheduled-departure-time',
      ),
      sortable: true,
      sortBy: 'scheduledDepartureTime',
      width: 210,
    },
    {
      id: 3,
      title: t(
        'frequent-flights.views.index.layout.tables.table-frequent-flights.tabla.header.scheduled-arrival-time',
      ),
      sortable: true,
      sortBy: 'scheduledArrivalTime',
      width: 210,
    },
    {
      id: 4,
      title: t(
        'frequent-flights.views.index.layout.tables.table-frequent-flights.tabla.header.status',
      ),
      sortable: true,
      sortBy: 'flightStatus',
      width: 130,
    },
  ];

  if (canEditFrequentFlight)
    columns.push({
      id: 5,
      title: t(
        'frequent-flights.views.index.layout.tables.table-frequent-flights.tabla.header.edit',
      ),
      width: 100,
      action: true,
    });

  if (canDisableFrequentFlight)
    columns.push({
      id: 6,
      title: t(
        'frequent-flights.views.index.layout.tables.table-frequent-flights.tabla.header.enabled-disabled',
      ),
      width: 190,
      action: true,
    });

  const title = t('frequent-flights.views.index.layout.title');

  const breadcrumbs = [
    {
      id: 1,
      text: t('frequent-flights.views.index.layout.breadcrumbs.home'),
      path: '/dashboard',
    },
    {
      id: 2,
      text: t(
        'frequent-flights.views.index.layout.breadcrumbs.frequent-flights',
      ),
    },
  ];

  const handleModal = (data) => {
    setOpenModal(true);
    setFlightInfo(data);
  };

  // Get flightcodes by params
  const handleFlightCode = async () => {
    try {
      const params = `user_id=${id}&airline=${
        filters.airline ? filters.airline : ''
      }&airport=${filters.airport ? filters.airport : ''}`;
      const { data } = await getFrequencyFlightIataFlights(authorization, params);
      setFlightCodes(iataCodeListAdapter(data));
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t(
            'frequent-flights.views.index.messages.api.error.no-data-to-filter',
          ),
          languageFile: LANGUAGES_FILES.FLIGHTS,
        }),
      );
    }
  };

  const setParamsFlightCode = (id) => {
    setFilters({
      ...filters,
      flight_code: id,
    });
  };

  // Get airlines by params
  const handleFlightAirline = async () => {
    try {
      const params = `flight_code=${
        filters.flight_code ? filters.flight_code : ''
      }&airport=${filters.airport ? filters.airport : ''}`;
      const { data } = await getFrequencyFlightAirlines(authorization, params);
      const airlinesAllowed = await airlinesAllowedPerUser(authorization, id);
      setFlightAirlines(airlinesListAdapter(data, airlinesAllowed.data));
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t(
            'frequent-flights.views.index.messages.api.error.no-data-to-filter',
          ),
          languageFile: LANGUAGES_FILES.FLIGHTS,
        }),
      );
    }
  };

  const setParamsAirline = (id) => {
    setFilters({
      ...filters,
      airline: id,
    });
  };

  // Get airports by params
  const handleFlightAirport = async () => {
    try {
      const params = `airline=${
        filters.airline ? filters.airline : ''
      }&flight_code=${filters.flight_code ? filters.flight_code : ''}`;
      const { data } = await getFrequencyFlightAirports(authorization, params);
      const airportsAllowed = await stationsAllowedPerUser(authorization, id);
      setFlightAirports(airportsListAdapter(data, airportsAllowed.data));
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t(
            'frequent-flights.views.index.messages.api.error.no-data-to-filter',
          ),
          languageFile: LANGUAGES_FILES.FLIGHTS,
        }),
      );
    }
  };

  const setParamsAirport = (id) => {
    setFilters({
      ...filters,
      airport: id,
    });
  };

  // Load selects info
  useEffect(() => {
    handleFlightCode();
    handleFlightAirline();
    handleFlightAirport();
    setFirstRender(true);
  }, []);

  // Update airlines and airports select
  useEffect(() => {
    if (firstRender) {
      handleFlightAirline();
      handleFlightAirport();
    }
  }, [filters.flight_code]);

  // Update flight Codes and airports select
  useEffect(() => {
    if (firstRender) {
      handleFlightCode();
      handleFlightAirport();
    }
  }, [filters.airline]);

  // Update airlines and flight Codes select
  useEffect(() => {
    if (firstRender) {
      handleFlightAirline();
      handleFlightCode();
    }
  }, [filters.airport]);

  const handleFilter = (rowsFiltereds) => {
    setFlights({
      ...flights,
      rows: rowsFiltereds,
    });
  };

  const getFlightsFiltereds = async () => {
    setIsLoading(true);
    try {
      const params = `user_id=${id}&airline=${
        filters.airline ? filters.airline : ''
      }&flight_code=${filters.flight_code ? filters.flight_code : ''}&airport=${
        filters.airport ? filters.airport : ''
      }`;

      const { data } = await getFrequencyFlightsFilter(authorization, params);
      setFlights({
        ...flights,
        rows: frequentFlightsAdapter(data, handleModal),
      });
      setUpdateSearch(updateSearch + 1);
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t(
            'frequent-flights.views.index.messages.api.error.flight-filter-no-found',
          ),
          languageFile: LANGUAGES_FILES.FLIGHTS,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getPermissions = async () => {
    const editFrequentFlight = await resultUserPermissions(
      authorization,
      id,
      'frequency_flight_update',
    );

    const disableFrequentFlight = await resultUserPermissions(
      authorization,
      id,
      'frequency_flight_enable_disable',
    );

    setCanEditFrequentFlight(editFrequentFlight.data.can);
    // setCanDisableFrequentFlight(disableFrequentFlight.data.can);
    setCanDisableFrequentFlight(false);
  };

  const handleSubmit = async (id, data) => {
    try {
      await updateFrequencyFlight(authorization, id, data);
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.DONE,
          message: t('frequent-flights.views.index.messages.api.correct.edit'),
          languageFile: LANGUAGES_FILES.FLIGHTS,
        }),
      );
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('frequent-flights.views.index.messages.api.error.no-edit'),
          languageFile: LANGUAGES_FILES.FLIGHTS,
        }),
      );
    } finally {
      getFlightsFiltereds();
      setOpenModal(false);
    }
  };

  useEffect(() => {
    getPermissions();
    getFlightsFiltereds();
  }, []);

  useEffect(() => {
    dispatch(setNavbarInfo({ title, breadcrumbs }));

    return () => {
      dispatch(clearNavbar());
    };
  }, [e.language]);

  return (
    <main className="w-full min-w-[280px] h-auto flex flex-col gap-1 pb-4">
      {openModal ? (
        <Modal>
          <div className="bg-white px-8 py-4 rounded-[6px]">
            <h2 className="text-[#273447] text-[32px] font-bold p-0 m-0 mb-2">
              {t('frequent-flights.views.index.layout.itinerary-section.title')}
            </h2>

            <Itinerary
              disabled={isLoading}
              flightInfo={flightInfo}
              handleSubmit={handleSubmit}
              update={update}
              setUpdate={setUpdate}
            />

            <div className="flex flex-col md:flex-row xs:gap-[20px] xs:w-[50%] md:gap-[90px] md:w-full justify-center lg:pt-[20px]">
              <ButtonGeneral
                value={t(
                  'frequent-flights.views.index.layout.itinerary-section.buttons.save',
                )}
                type={'button'}
                onClick={() => setUpdate(true)}
                disabled={isLoading}
              />

              <ButtonCancel
                value={t(
                  'frequent-flights.views.index.layout.itinerary-section.buttons.cancel',
                )}
                onClick={() => setOpenModal(false)}
                disabled={isLoading}
              />
            </div>
          </div>
        </Modal>
      ) : null}

      <section
        className="
          w-full bg-white grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3
          xl:px-5 gap-4 place-items-center shadow-md border py-5 mb-1 sm:rounded-[6px]
        "
      >
        <SelectAutocomplete
          data={flightCodes}
          setParams={setParamsFlightCode}
          label={t(
            'frequent-flights.views.index.layout.forms.filter-flights.labels.flight-code-select',
          )}
          disabled={isLoading}
        />

        <SelectAutocomplete
          data={flightAirlines}
          setParams={setParamsAirline}
          label={t(
            'frequent-flights.views.index.layout.forms.filter-flights.labels.airline-select',
          )}
          disabled={isLoading}
        />

        <SelectAutocomplete
          data={flightAirports}
          setParams={setParamsAirport}
          label={t(
            'frequent-flights.views.index.layout.forms.filter-flights.labels.departure-arrival-station',
          )}
          disabled={isLoading}
        />

        <div className="col-span-1">
          <ButtonGeneral
            value={t(
              'frequent-flights.views.index.layout.forms.filter-flights.buttons.filter',
            )}
            onClick={getFlightsFiltereds}
            width={130}
            disabled={isLoading}
          />
        </div>
      </section>

      <section className="w-full h-[75%] min-h-[500px] bg-white sm:rounded-[6px] shadow-md border pb-5 pt-4 relative mb-4">
        {isLoading && (
          <SecondLoading
            size={100}
            color={'#475569'}
            rounded={'6px'}
            opacity={'0.4'}
          />
        )}

        {columns.length ? (
          <div className="h-full flex flex-col items-center gap-4 xs:px-1 sm:px-4 md:px-5">
            <div className="w-full grid grid-cols-1 gap-3 md:grid-cols-3">
              <div className="w-full flex justify-center md:col-start-2">
                <SearchBar
                  list={flights.rows}
                  handleFilter={handleFilter}
                  columnsToSearch={columnsToSearch}
                  placeholder={t(
                    'frequent-flights.views.index.layout.tables.table-frequent-flights.searchbar',
                  )}
                  disabled={isLoading}
                  update={updateSearch}
                />
              </div>
            </div>

            <DataTable
              data={flights}
              headers={columns}
              RowsComponent={RowsTableFrequentFlights}
              permission={{
                canEditFrequentFlight,
                canDisableFrequentFlight,
              }}
              noData={t(
                'frequent-flights.views.index.layout.tables.table-frequent-flights.tabla.no-data',
              )}
            />
          </div>
        ) : null}
      </section>
    </main>
  );
}
