import {
  SET_CAN_USER,
  SET_CAN_USER_DOWNLOAD,
  SET_CAN_USER_ENABLE,
  SET_CAN_USER_SHOW_DETAIL,
  SET_CAN_USER_UPDATE,
  SET_IS_AUTHENTICATED,
  SET_LOGGED,
  SET_NEW_USER,
  SET_UPDATE_USER,
  SET_USERS,
  SET_USER_ID,
} from '../types/usersTypes';

const initialState = {
  resultNewUser: [],
  resultUsers: [],
  resultUserId: [],
  resultUpdateUser: [],
  resultCanUser: [],
  resultCanUserEnable: [],
  resultCanUserUpdate: [],
  resultCanUserDownload: [],
  resultCanUserShowDetail: [],
  isAuthenticated: true,
  logged: true,
};

function usersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NEW_USER:
      return { ...state, resultNewUser: action.payload };

    case SET_USERS:
      return { ...state, resultUsers: action.payload };

    case SET_USER_ID:
      return { ...state, resultUserId: action.payload };

    case SET_UPDATE_USER:
      return { ...state, resultUpdateUser: action.payload };

    case SET_CAN_USER:
      return { ...state, resultCanUser: action.payload };

    case SET_CAN_USER_ENABLE:
      return { ...state, resultCanUserEnable: action.payload };

    case SET_CAN_USER_UPDATE:
      return { ...state, resultCanUserUpdate: action.payload };

    case SET_CAN_USER_DOWNLOAD:
      return { ...state, resultCanUserDownload: action.payload };

    case SET_CAN_USER_SHOW_DETAIL:
      return { ...state, resultCanUserShowDetail: action.payload };

    case SET_IS_AUTHENTICATED:
      return { ...state, isAuthenticated: action.payload };

    case SET_LOGGED:
      return { ...state, logged: action.payload };

    default:
      return state;
  }
}

export default usersReducer;
