import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ALERT_TYPE, LANGUAGES_FILES } from '../../../constants/enums';

import { resultUserPermissions } from '../../../services/api/v1/permission_requests/permissionRequests';
import { resultMenuInformations } from '../../../services/api/v1/menu_requests/menuRequests';
import { resultDisableRole } from '../../../services/api/v1/rol_requests/rolRequests';

import { createNewAlert } from '../../../redux/reducer/alert-messages/alerts.actions';
import {
  setNavbarInfo,
  clearNavbar,
} from '../../../redux/reducer/navbar/navbar.reducers';
import { menusAdapter } from '../adapters/menus';

import RowTableMenus from './table-menus/RowTableMenus';
import SecondLoading from '../../../components/SecondLoading';
import ButtonGeneral from '../../../components/ButtonGeneral';
import SearchBar from '../../../components/SearchBar';
import DataTable from '../../../components/data_table/DataTable';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';

import DecryptedToken from '../../../components/security_components/decrypted_token/decrypted_token';
import EnableDisableAlert from '../../../components/EnableDisableAlert';

const columnsToSearch = [
  'level',
  'nameEn',
  'nameEs',
  'path',
  'sectionFullName',
  'permissionName',
];

export default function Menus() {
  const [t, e] = useTranslation(LANGUAGES_FILES.MENUS);
  const authorization = DecryptedToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateSearch, setUpdateSearch] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [openDisableEnableModal, setOpenDisableEnableModal] = useState(false);

  const [menus, setMenus] = useState([]);
  const [menuInfo, setMenuInfo] = useState({
    id: null,
    name: null,
    isEnabled: null,
  });

  const [canUpdateMenus, setCanUpdateMenus] = useState(false);
  const [canEnableDisableMenu, setCanEnableDisableMenu] = useState(false);
  const [canCreateMenu, setCanCreateMenu] = useState(false);

  const { id } = useSelector((state) => state.authorization.informationUser);

  const columns = [
    {
      id: 1,
      title: t(
        'menus.views.index.layout.tables.table-menus.table.header.level',
      ),
      sortable: true,
      sortBy: 'level',
      width: 60,
    },
    {
      id: 2,
      title: t(
        'menus.views.index.layout.tables.table-menus.table.header.name-en',
      ),
      sortable: true,
      sortBy: 'nameEn',
      width: 180,
    },
    {
      id: 3,
      title: t(
        'menus.views.index.layout.tables.table-menus.table.header.name-es',
      ),
      sortable: true,
      sortBy: 'nameEs',
      width: 180,
    },
    {
      id: 4,
      title: t('menus.views.index.layout.tables.table-menus.table.header.path'),
      sortable: true,
      sortBy: 'path',
      width: 170,
    },
    {
      id: 5,
      title: t(
        'menus.views.index.layout.tables.table-menus.table.header.section-full-name',
      ),
      sortable: true,
      sortBy: 'sectionFullName',
      width: 350,
    },
    {
      id: 6,
      title: t(
        'menus.views.index.layout.tables.table-menus.table.header.permission-name',
      ),
      sortable: true,
      sortBy: 'permissionName',
      width: 230,
    },
  ];

  if (canUpdateMenus)
    columns.push({
      id: 7,
      title: t('menus.views.index.layout.tables.table-menus.table.header.edit'),
      width: 90,
      action: true,
    });

  if (canEnableDisableMenu)
    columns.push({
      id: 8,
      title: t(
        'menus.views.index.layout.tables.table-menus.table.header.enabled',
      ),
      width: 180,
      action: true,
    });

  const title = t('menus.views.index.layout.title');

  const breadcrumbs = [
    {
      id: 1,
      text: t('menus.views.index.layout.breadcrumbs.home'),
      path: '/dashboard',
    },
    {
      id: 2,
      text: t('menus.views.index.layout.breadcrumbs.menus'),
    },
  ];

  const openModal = (value) => {
    setOpenDisableEnableModal(true);
    setMenuInfo(value);
  };

  const closeModal = () => {
    setOpenDisableEnableModal(false);
  };

  const handleEnableDisableMenu = async (id, action) => {
    setLoadingRequest(true);
    try {
      const response = await resultDisableRole(
        authorization,
        'menus',
        id,
        action,
      );
      await getDataToTable();
      setUpdateSearch(updateSearch + 1);

      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.DONE,
          message: response.data.is_enabled
            ? t('menus.views.index.messages.api.correct.enable')
            : t('menus.views.index.messages.api.correct.disable'),
          languageFile: LANGUAGES_FILES.MENUS,
        }),
      );

      closeModal();
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('menus.views.index.messages.api.error.no-update'),
          languageFile: LANGUAGES_FILES.MENUS,
        }),
      );
    } finally {
      setLoadingRequest(false);
    }
  };

  const handleFilter = (rowsFiltereds) => {
    setMenus(rowsFiltereds);
  };

  const getPermissions = async () => {
    const updateMenus = await resultUserPermissions(
      authorization,
      id,
      'menu_update',
    );
    const enableDisableMenu = await resultUserPermissions(
      authorization,
      id,
      'menu_enable_disable',
    );
    const createMenu = await resultUserPermissions(
      authorization,
      id,
      'menu_create',
    );

    setCanUpdateMenus(updateMenus.data.can);
    setCanEnableDisableMenu(enableDisableMenu.data.can);
    setCanCreateMenu(createMenu.data.can);
  };

  const getDataToTable = async () => {
    const getMenus = await resultMenuInformations(authorization);
    setMenus(menusAdapter(getMenus.data, openModal));
    setIsLoading(false);
  };

  useEffect(() => {
    getPermissions();
    getDataToTable();
  }, []);

  useEffect(() => {
    dispatch(setNavbarInfo({ title, breadcrumbs }));

    return () => {
      dispatch(clearNavbar());
    };
  }, [e.language]);

  return (
    <main className="w-full min-w-[280px] h-full flex flex-col gap-1">
      {openDisableEnableModal ? (
        <Modal>
          <EnableDisableAlert
            handleClick={handleEnableDisableMenu}
            handleClose={closeModal}
            itemInfo={menuInfo}
            itemType={t('menus.views.index.layout.modals.enable-disable.title')}
          />
        </Modal>
      ) : null}

      {loadingRequest ? (
        <Modal>
          <Loading />
        </Modal>
      ) : null}

      <section className="w-full h-[95%] min-h-[550px] bg-white sm:rounded-[6px] shadow-md border pb-[28px] pt-4 relative">
        {isLoading && (
          <SecondLoading
            size={100}
            color={'#475569'}
            rounded={'6px'}
            opacity={'0.4'}
          />
        )}

        {columns.length ? (
          <div className="h-full flex flex-col items-center gap-4 xs:px-1 sm:px-4 md:px-5">
            <div className="w-full grid grid-cols-1 gap-3 md:grid-cols-3">
              {canCreateMenu ? (
                <div className="w-full flex items-center justify-center md:order-2 md:justify-end md:pr-3">
                  <ButtonGeneral
                    value={t('menus.views.index.layout.buttons.new-menu')}
                    width={120}
                    onClick={() => navigate('/configuration/menus/new')}
                  />
                </div>
              ) : null}

              <div className="w-full flex justify-center md:col-start-2">
                <SearchBar
                  list={menus}
                  handleFilter={handleFilter}
                  columnsToSearch={columnsToSearch}
                  placeholder={t(
                    'menus.views.index.layout.tables.table-menus.searchbar',
                  )}
                  disabled={isLoading}
                  update={updateSearch}
                />
              </div>
            </div>

            <DataTable
              data={{ rows: menus }}
              headers={columns}
              RowsComponent={RowTableMenus}
              permission={{
                canUpdateMenus,
                canEnableDisableMenu,
              }}
              noData={t(
                'menus.views.index.layout.tables.table-menus.table.no-data',
              )}
            />
          </div>
        ) : null}
      </section>
    </main>
  );
}
