import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AiOutlineSetting } from "react-icons/ai";

import HomeSVG from '../../../../assets/icons/menu/home.svg?react';
import FlightsSVG from '../../../../assets/icons/menu/flights.svg?react';
import AdministrationSVG from '../../../../assets/icons/menu/administration.svg?react';
import AdminUsersSVG from '../../../../assets/icons/menu/admin-users.svg?react';
import AdminCompaniesSVG from '../../../../assets/icons/menu/admin-companies.svg?react';
import AdminFlightsSVG from '../../../../assets/icons/menu/admin-flights.svg?react';
import ParameterizationSVG from '../../../../assets/icons/menu/parameterization.svg?react';
import ParameterUsersSVG from '../../../../assets/icons/menu/parameter-users.svg?react';
import RolesSVG from '../../../../assets/icons/menu/roles.svg?react';
import IdentificationTypesSVG from '../../../../assets/icons/menu/identification-types.svg?react';
import JobPositionSVG from '../../../../assets/icons/menu/job-position.svg?react';
import ParameterFlightsSVG from '../../../../assets/icons/menu/parameter-flights.svg?react';
import CountriesSVG from '../../../../assets/icons/menu/countries.svg?react';
import CitiesSVG from '../../../../assets/icons/menu/cities.svg?react';
import AirportsSVG from '../../../../assets/icons/menu/airports.svg?react';
import AirlinesSVG from '../../../../assets/icons/menu/airlines.svg?react';
import AreasSVG from '../../../../assets/icons/menu/areas.svg?react';
import PositionsSVG from '../../../../assets/icons/menu/positions.svg?react';
import FlightParameterizationSVG from '../../../../assets/icons/menu/flights-parameterization.svg?react';
import ParameterCompaniesSVG from '../../../../assets/icons/menu/parameter-companies.svg?react';
import CompanyServicesSVG from '../../../../assets/icons/menu/company-services.svg?react';
import ConfigurationSVG from '../../../../assets/icons/menu/configuration.svg?react';
import SectionsSVG from '../../../../assets/icons/menu/sections.svg?react';
import PermissionsTypesSVG from '../../../../assets/icons/menu/permissions-types.svg?react';
import PermissionSVG from '../../../../assets/icons/menu/permissions.svg?react';
import MenusSVG from '../../../../assets/icons/menu/menus.svg?react';

import {
  LANGUAGES_FILES,
  MENU_SELECT,
  ALERT_TYPE,
} from '../../../../constants/enums';

import { createNewAlert } from '../../../../redux/reducer/alert-messages/alerts.actions';

import { resultCustomPermissionsByUser } from '../../../../services/api/v1/permission_requests/permissionRequests';

import SimpleButton from './SimpleButton';
import ListButton from './ListButton';

import DecryptedToken from '../../../../components/security_components/decrypted_token/decrypted_token';
import { useTranslation } from 'react-i18next';

export default function Menu() {
  const authorization = DecryptedToken();
  const dispatch = useDispatch();
  const [t] = useTranslation(LANGUAGES_FILES.GLOBAL);

  const [permissions, setPermissions] = useState({});

  const { id } = useSelector((state) => state.authorization.informationUser);

  const adminButtons = [
    {
      id: 1,
      icon: AdminUsersSVG,
      text: t('global.layout.sidebar.menus.administration-menu.users'),
      goTo: '/users',
      name: MENU_SELECT.ADMINISTRATION_USERS,
      menuType: 'medium',
      canShow: permissions.profile_index,
    },
    {
      id: 2,
      icon: AdminCompaniesSVG,
      text: t('global.layout.sidebar.menus.administration-menu.companies'),
      goTo: '/companies',
      name: MENU_SELECT.ADMINISTRATION_COMPANIES,
      menuType: 'medium',
      canShow: permissions.company_index,
    },
    {
      id: 3,
      icon: AdminFlightsSVG,
      text: t('global.layout.sidebar.menus.administration-menu.flights'),
      goTo: '/frequent_flights',
      name: MENU_SELECT.ADMINISTRATION_FLIGHTS,
      menuType: 'medium',
      canShow: permissions.frequency_flight_index,
    },
  ];

  const parameterButtons = [
    {
      id: 1,
      icon: ParameterUsersSVG,
      text: t('global.layout.sidebar.menus.parameterization-menu.users'),
      name: MENU_SELECT.PARAMETERIZATION_USERS,
      menuType: 'medium',
      canShow: permissions.parameterization_user_section,
      list: [
        {
          id: 11,
          icon: RolesSVG,
          text: t(
            'global.layout.sidebar.menus.parameterization-menu.users-menu.roles',
          ),
          goTo: '/parameters/roles',
          name: MENU_SELECT.USERS_ROLES,
          menuType: 'low',
          stroke: true,
          canShow: permissions.role_index,
        },
        {
          id: 12,
          icon: IdentificationTypesSVG,
          text: t(
            'global.layout.sidebar.menus.parameterization-menu.users-menu.identification-types',
          ),
          goTo: '/parameters/identifications_types',
          name: MENU_SELECT.USERS_IDENTIFICATION_TYPES,
          menuType: 'low',
          canShow: permissions.identification_type_index,
        },
        {
          id: 13,
          icon: JobPositionSVG,
          text: t(
            'global.layout.sidebar.menus.parameterization-menu.users-menu.job-positions',
          ),
          goTo: '/parameters/jobs_positions',
          name: MENU_SELECT.USERS_JOB_POSITIONS,
          menuType: 'low',
          canShow: permissions.job_position_index,
        },
      ],
    },
    {
      id: 2,
      icon: ParameterFlightsSVG,
      text: t('global.layout.sidebar.menus.parameterization-menu.flights'),
      name: MENU_SELECT.PARAMETERIZATION_FLIGHTS,
      menuType: 'medium',
      canShow: permissions.parameterization_flight_section,
      list: [
        {
          id: 21,
          icon: CountriesSVG,
          text: t(
            'global.layout.sidebar.menus.parameterization-menu.flights-menu.countries',
          ),
          goTo: '/parameters/countries',
          name: MENU_SELECT.FLIGHTS_COUNTRIES,
          menuType: 'low',
          canShow: permissions.country_index,
        },
        {
          id: 22,
          icon: CitiesSVG,
          text: t(
            'global.layout.sidebar.menus.parameterization-menu.flights-menu.cities',
          ),
          goTo: '/parameters/cities',
          name: MENU_SELECT.FLIGHTS_CITIES,
          menuType: 'low',
          canShow: permissions.city_index,
        },
        {
          id: 23,
          icon: AirportsSVG,
          text: t(
            'global.layout.sidebar.menus.parameterization-menu.flights-menu.airports',
          ),
          goTo: '/parameters/airports',
          name: MENU_SELECT.FLIGHTS_AIRPORTS,
          menuType: 'low',
          stroke: true,
          canShow: permissions.station_index,
        },
        {
          id: 24,
          icon: AirlinesSVG,
          text: t(
            'global.layout.sidebar.menus.parameterization-menu.flights-menu.airlines',
          ),
          goTo: '/parameters/airlines',
          name: MENU_SELECT.FLIGHTS_AIRLINES,
          menuType: 'low',
          stroke: true,
          canShow: permissions.airline_index,
        },
        {
          id: 25,
          icon: AreasSVG,
          text: t(
            'global.layout.sidebar.menus.parameterization-menu.flights-menu.areas',
          ),
          goTo: '/parameters/areas',
          name: MENU_SELECT.FLIGHTS_AREAS,
          menuType: 'low',
          canShow: permissions.area_index,
        },
        {
          id: 26,
          icon: PositionsSVG,
          text: t(
            'global.layout.sidebar.menus.parameterization-menu.flights-menu.positions',
          ),
          goTo: '/parameters/positions',
          name: MENU_SELECT.FLIGHTS_POSITIONS,
          menuType: 'low',
          stroke: true,
          canShow: permissions.position_index,
        },
        {
          id: 27,
          icon: FlightParameterizationSVG,
          text: t(
            'global.layout.sidebar.menus.parameterization-menu.flights-menu.flights-parameterization',
          ),
          goTo: '/parameters/flights',
          name: MENU_SELECT.FLIGHTS_PARAMETERIZATION,
          menuType: 'low',
          stroke: true,
          canShow: permissions.flight_config_flights,
        },
      ],
    },
    {
      id: 3,
      icon: ParameterCompaniesSVG,
      text: t('global.layout.sidebar.menus.parameterization-menu.companies'),
      name: MENU_SELECT.PARAMETERIZATION_COMPANIES,
      menuType: 'medium',
      canShow: permissions.parameterization_company_section,
      list: [
        {
          id: 31,
          icon: CompanyServicesSVG,
          text: t(
            'global.layout.sidebar.menus.parameterization-menu.companies-menu.company-services',
          ),
          goTo: '/parameters/company_services',
          name: MENU_SELECT.COMPANY_SERVICES,
          menuType: 'low',
          canShow: permissions.company_service_index,
        },
      ],
    },
  ];

  const configButtons = [
    {
      id: 1,
      icon: SectionsSVG,
      text: t('global.layout.sidebar.menus.configurations-menu.sections'),
      goTo: '/configuration/sections',
      name: MENU_SELECT.CONFIGURATION_SECTIONS,
      menuType: 'medium',
      canShow: permissions.section_index,
    },
    {
      id: 2,
      icon: PermissionsTypesSVG,
      text: t(
        'global.layout.sidebar.menus.configurations-menu.permission-types',
      ),
      goTo: '/configuration/permission_types',
      name: MENU_SELECT.CONFIGURATION_PERMISSION_TYPES,
      menuType: 'medium',
      canShow: permissions.permission_type_index,
    },
    {
      id: 3,
      icon: PermissionSVG,
      text: t('global.layout.sidebar.menus.configurations-menu.permissions'),
      goTo: '/configuration/permissions',
      name: MENU_SELECT.CONFIGURATION_PERMISSIONS,
      menuType: 'medium',
      stroke: true,
      canShow: permissions.custom_permission_index,
    },
    {
      id: 4,
      icon: MenusSVG,
      text: t('global.layout.sidebar.menus.configurations-menu.menus'),
      goTo: '/configuration/menus',
      name: MENU_SELECT.CONFIGURATION_MENUS,
      menuType: 'medium',
      canShow: permissions.menu_index,
    },
    {
      id: 5,
      icon: AiOutlineSetting,
      text: t('global.layout.sidebar.menus.configurations-menu.settings'),
      goTo: '/configuration/settings',
      name: MENU_SELECT.CONFIGURATION_SETTINGS,
      menuType: 'medium',
      canShow: permissions.setting_index,
    },
  ];

  const getPermissions = async () => {
    try {
      const { data } = await resultCustomPermissionsByUser(id, authorization);
      setPermissions(data);
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('global.messages.api.error.connection'),
          languageFile: LANGUAGES_FILES.GLOBAL,
        }),
      );
    }
  };

  useEffect(() => {
    getPermissions();
  }, []);

  return (
    <main className="py-1 flex flex-col">
      <SimpleButton
        icon={HomeSVG}
        text={t('global.layout.sidebar.menus.home')}
        goTo={'/dashboard'}
        name={MENU_SELECT.HOME}
        canShow={true}
      />

      <SimpleButton
        icon={FlightsSVG}
        text={t('global.layout.sidebar.menus.flights')}
        goTo={'/flights'}
        name={MENU_SELECT.FLIGHTS}
        canShow={permissions.flight_index}
      />

      <ListButton
        icon={AdministrationSVG}
        text={t('global.layout.sidebar.menus.administration')}
        name={MENU_SELECT.ADMINISTRATION}
        list={adminButtons}
        canShow={permissions.administration_section}
      />

      <ListButton
        icon={ParameterizationSVG}
        text={t('global.layout.sidebar.menus.parameterization')}
        name={MENU_SELECT.PARAMETERIZATION}
        isSubList={true}
        list={parameterButtons}
        canShow={permissions.parameterization_section}
      />

      <ListButton
        icon={ConfigurationSVG}
        text={t('global.layout.sidebar.menus.configurations')}
        name={MENU_SELECT.CONFIGURATION}
        list={configButtons}
        canShow={permissions.configuration_section}
      />
    </main>
  );
}
