import { dateWithTimezone } from '../../../utils/dateFormatter';

export const airlinesAdapter = (data) => {
  const response = [];
  data.forEach((item) => {
    if (item.name.includes('xxx')) return;

    response.push({
      id: item.id,
      name: `[${item.iata_code}] ${item.name}`,
    });
  });

  return response;
};

export const airportsAdapter = (data) => {
  const response = [];
  data.forEach((item) => {
    if (item.name.includes('xxx')) return;

    response.push({
      id: item.id,
      name: `[${item.iata_code}] ${item.name}`,
    });
  });

  return response;
};

export const iataFlightsAdapter = (data) => {
  let response = []
  response = data.map((item) => ({
    id: item,
    name: item,
  }));

  return response;
};

export const flightsAdapter = (data) => {
  const response = data.map((item) => ({
    id: item.id,
    flightCode: item.iata_flight,
    flightDistribution: item.flight_distribution,
    scheduledDepartureTime: dateWithTimezone(item.departure_scheduled_time),
    scheduledArrivalTime: dateWithTimezone(item.arrival_scheduled_time),
    flightStatus: item.flight_status,
  }));

  return response;
};

export const flightParameterizationAdapter = (data, config) => {
  const response = [];

  data.forEach((item) => {
    const findFlight = config.find(
      (element) => element.iata_flight === item.iata_flight,
    );
    response.push({
      id: item.flight_id,
      flightCode: item.iata_flight,
      flightDistribution: item.flight_distribution,
      stationId: item.station_id,
      airlineId: item.airline_id,
      configurationStatus: findFlight ? true : false,
      configurationAt: findFlight
        ? dateWithTimezone(findFlight.updated_at)
        : '-',
    });
  });

  return response;
};
