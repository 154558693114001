import { axiosSesion } from '../axios';

// Get all permissions
export const resultPermissions = (Authorization) => {
  return axiosSesion.get('/api/v1/custom_permissions', {
    headers: { Authorization },
  });
};

// Get permission by ID
export const resultPermissionId = (Authorization, id) => {
  return axiosSesion.get(`/api/v1/custom_permissions/${id}`, {
    headers: { Authorization },
  });
};

// Create a new permission
export const resultNewPermission = (
  Authorization,
  code,
  nameEn,
  nameEs,
  description,
  permissionTypeId,
  sectionId,
) => {
  return axiosSesion.post(
    '/api/v1/custom_permissions/',
    {
      custom_permission: {
        code,
        name_en: nameEn,
        name_es: nameEs,
        description,
        permission_type_id: permissionTypeId,
        section_id: sectionId,
      },
    },
    {
      headers: { Authorization },
    },
  );
};

// Update permission
export const resultUpdatePermission = (
  Authorization,
  id,
  code,
  nameEn,
  nameEs,
  description,
  permissionTypeId,
  sectionId,
) => {
  return axiosSesion.put(
    `/api/v1/custom_permissions/${id}`,
    {
      custom_permission: {
        code,
        name_en: nameEn,
        name_es: nameEs,
        description,
        permission_type_id: permissionTypeId,
        section_id: sectionId,
      },
    },
    {
      headers: { Authorization },
    },
  );
};

// Get permissions by user
export const resultUserPermissions = (Authorization, id, permission) => {
  return axiosSesion.get(
    `/api/v1/users/${id}/can/?permission_code=${permission}`,
    {
      headers: { Authorization },
    },
  );
};

// Get permissions by flight
export const resultFlightPermission = (Authorization, id) => {
  return axiosSesion.get(`/api/v1/flights/${id}`, {
    headers: { Authorization },
  });
};

// Get permissions by custom
export const resultCustomPermissions = (Authorization, id) => {
  return axiosSesion.get(
    `/api/v1/custom_permissions/get_permissions_to_apply_by_role_id?[role_id]=${id}`,
    { headers: { Authorization } },
  );
};

// Apply permissions to user
export const resultApplyPermissionUser = (
  Authorization,
  id,
  permissionId,
  bApply,
) => {
  return axiosSesion.post(
    `/api/v1/users/${id}/apply_permission`,
    {
      apply_permission: {
        permission_id: permissionId,
        b_apply: bApply,
      },
    },
    {
      headers: { Authorization },
    },
  );
};

// Apply permission to rol
export const resultChecked = (Authorization, roleId, permissionId, bApply) => {
  return axiosSesion.post(
    `/api/v1/roles/${roleId}/apply_permission`,
    {
      apply_permission: {
        permission_id: permissionId,
        b_apply: bApply,
      },
    },
    {
      headers: { Authorization },
    },
  );
};

// Update apply permissions
export const resultUpdatePermissionApply = (Authorization, id, apply) => {
  return axiosSesion.put(
    `/api/v1/custom_permissions/${id}`,
    {
      custom_permission: {
        apply,
      },
    },
    {
      headers: { Authorization },
    },
  );
};

// Get all permissions by user
export const resultCustomPermissionsByUser = (id, Authorization) => {
  return axiosSesion.get(`/api/v1/users/${id}/user_custom_permissions`, {
    headers: { Authorization },
  });
};

export const enableDisablePermission = (Authorization, id, type) => {
  const body = {};
  if (type === 'enable') body.with_disables = true;

  return axiosSesion.patch(`/api/v1/custom_permissions/${id}/${type}`, body, {
    headers: { Authorization },
  });
};
