import EditFrequentFlight from './EditFrequentFlight';

export default function RowsTableFrequentFlights({ rows, permission }) {
  return (
    <>
      {rows.map((row) => (
        <tr
          key={row.id}
          className="text-slate-800 text-sm even:bg-slate-50 hover:bg-[#cdd5e4]"
        >
          <td
            className="p-2.5 select-none"
            style={{
              borderTop: '1px solid #e2e8f0',
              borderBottom: '1px solid #e2e8f0',
            }}
          >
            {row.flightCode}
          </td>

          <td
            className="p-2.5 select-none"
            style={{ border: '1px solid #e2e8f0' }}
          >
            {row.scheduledDepartureTime}
          </td>

          <td
            className="p-2.5 select-none"
            style={{ border: '1px solid #e2e8f0' }}
          >
            {row.scheduledArrivalTime}
          </td>

          <td
            className="p-2.5 select-none"
            style={{
              borderTop: '1px solid #e2e8f0',
              borderBottom: '1px solid #e2e8f0',
              borderRight: `${permission ? '1px solid #e2e8f0' : 'none'}`,
            }}
          >
            {row.flightStatus}
          </td>

          {permission.canEditFrequentFlight && (
            <td
              style={{
                borderTop: '1px solid #e2e8f0',
                borderBottom: '1px solid #e2e8f0',
              }}
            >
              <EditFrequentFlight
                flightInfo={row.flightInfo}
                handleModal={row.handleModal}
              />
            </td>
          )}
        </tr>
      ))}
    </>
  );
}
