import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { BsChevronRight, BsChevronDown } from 'react-icons/bs';

import { selectMenu } from '../../../../redux/reducer/menu-selected/menuSelected.actions';

import SimpleButton from './SimpleButton';
import SubListButton from './SubListButton';

export default function ListButton({
  icon: Icon,
  text,
  name,
  isSubList = false,
  list,
  canShow = false,
}) {
  const dispatch = useDispatch();

  const { isContractedMenu } = useSelector((state) => state.contractedMenu);
  const [isOpen, setIsOpen] = useState(false);

  const { menuSelected, subMenuSelected } = useSelector(
    (state) => state.selectMenus,
  );

  const handleClick = () => {
    dispatch(selectMenu(name));
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (menuSelected !== name) setIsOpen(false);
  }, [menuSelected]);

  return (
    <>
      {canShow ? (
        <button
          className={`w-full text-[13px] text-[#FFFFFF] py-[9px] flex ${
            isContractedMenu
              ? 'xl:justify-center'
              : 'pl-[10px] pr-[18px] items-center justify-between'
          } ${menuSelected === name ? 'bg-[#303750]' : 'bg-transparent'}`}
          style={{
            borderLeft: `${
              menuSelected === name && !subMenuSelected
                ? '5px solid #50B8E4'
                : '5px solid transparent'
            }`,
            paddingRight: '5px',
          }}
          onClick={handleClick}
        >
          <div className="flex gap-2">
            <Icon fill={menuSelected === name ? '#FFFFFF' : '#50B8E4'} />

            {!isContractedMenu ? <span>{text}</span> : null}
          </div>

          {!isContractedMenu ? (
            <span
              className={`${
                menuSelected === name ? 'text-[#FFFFFF]' : 'text-[#50B8E4]'
              }`}
            >
              {menuSelected === name && isOpen ? (
                <BsChevronDown size={17} />
              ) : (
                <BsChevronRight size={17} />
              )}
            </span>
          ) : null}
        </button>
      ) : null}

      <main
        className={`bg-[#2f3d53] py-1 flex flex-col ${
          menuSelected === name && isOpen ? 'block' : 'hidden'
        }`}
      >
        {isSubList
          ? list.map((item) =>
              item.canShow ? (
                <SubListButton
                  key={item.id}
                  icon={item.icon}
                  text={item.text}
                  name={item.name}
                  menuType={item.menuType}
                  list={item.list}
                  canShow={item.canShow}
                />
              ) : null,
            )
          : list.map((item) =>
              item.canShow ? (
                <SimpleButton
                  key={item.id}
                  icon={item.icon}
                  text={item.text}
                  goTo={item.goTo}
                  name={item.name}
                  menuType={item.menuType}
                  stroke={item.stroke ? item.stroke : false}
                  canShow={item.canShow}
                />
              ) : null,
            )}
      </main>
    </>
  );
}
