// External imports
import { useSelector } from 'react-redux';

// Pages imports
import FrequencyFlights from '../../pages/frequency_flight_page/components/FrequencyFlights';

function FrequencyFlightRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const frequencyFlightRoutes = [
    {
      path: 'frequent_flights',
      element: <FrequencyFlights />,
      isAllowed: customPermission.frequency_flight_index,
    },
  ];

  return frequencyFlightRoutes;
}

export default FrequencyFlightRoutes;
