import {
  SET_GENERAL_REPORT,
  SET_GET_BODY_FORMATS,
  SET_RECORDS_IN_OUT,
  SET_TOTAL_RECORD,
} from '../types/recordsAndReportsTypes';

const initialState = {
  resultTotalRecord: [],
  resultGeneralReport: [],
  resultRecordInOut: [],
  resultGetBodyFormats: [],
};

function recordsAndReportsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOTAL_RECORD:
      return { ...state, resultTotalRecord: action.payload };

    case SET_GENERAL_REPORT:
      return { ...state, resultGeneralReport: action.payload };

    case SET_RECORDS_IN_OUT:
      return { ...state, resultRecordInOut: action.payload };

    case SET_GET_BODY_FORMATS:
      return { ...state, resultGetBodyFormats: action.payload };

    default:
      return state;
  }
}

export default recordsAndReportsReducer;
