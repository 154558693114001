export const errorList = {
  401: {
    message:
      'Authentication credentials are not valid or the token has expired',
    severity: 'error',
  },
  422: {
    message:
      'The server was unable to process the request due to a problem with the entity provided',
    severity: 'error',
  },
  500: {
    message:
      'An error has occurred on the server, please contact technical support',
    severity: 'error',
  },
  unknown: {
    message: 'Unknown error, please try again',
    severity: 'error',
  },
  errNetwork: {
    message:
      'There is a problem with the internet connection or the connection has been interrupted',
    severity: 'error',
  },
  timeout: {
    message:
      'The request has taken too long to process and has timed out. Please try again later',
    severity: 'error',
  },
  frequency_flight: {
    message:
      'The created flight already has an associated itinerary, if you want to change it, go to the frequecy flights menu',
    severity: 'warning',
    goTo: '/frequency_flights',
    subString: 'frequecy flights menu',
  },
  flight: {
    message:
      "The flight you are trying to create already exists. Please check today's flights.",
    severity: 'warning',
  },
  flightAndFrequency: {
    message:
      'The flight and itinerary was not created because they already exist',
    severity: 'error',
  },
  onlyFrequency: {
    message:
      'The itinerary was created, but the flight was not created because it already exists',
    severity: 'warning',
  },
  errBadRequest: {
    message: '',
    severity: 'error',
  },
};
