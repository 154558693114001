// External imports
import { useDispatch } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

// Actions redux imports
import { launchNewAlert } from '../../../redux/reducer/alerts/actions/alertsActions';

import DecryptedToken from '../decrypted_token/decrypted_token';

function ProtectedRoutes({
  children,
  isAllowed,
  redirectTo = '/',
  nameComponent,
}) {
  const dispatch = useDispatch();
  const authorization = DecryptedToken();

  if (authorization && nameComponent) {
    return <Navigate to={'/dashboard'} />;
  } else if (!authorization && !nameComponent) {
    return <Navigate to={'/login'} />;
  } else if (!isAllowed) {
    dispatch(
      launchNewAlert({
        launch: true,
        message: 'You do not have the right permissions to see this page',
        severity: 'error',
      }),
    );
    return <Navigate to={redirectTo} />;
  }

  return children ? children : <Outlet />;
}

export default ProtectedRoutes;
