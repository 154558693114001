import { axiosSesion } from '../axios';

// Get all roles
export const resultRoles = (Authorization) => {
  return axiosSesion.get('/api/v1/roles/', { headers: { Authorization } });
};

// Get rol by ID
export const resultGetRole = (Authorization, id) => {
  return axiosSesion.get(`/api/v1/roles/${id}`, {
    headers: { Authorization },
  });
};

// Create new rol
export const resultNewRole = (Authorization, newRole) => {
  return axiosSesion.post('/api/v1/roles', newRole, {
    headers: { Authorization },
  });
};

// Update rol
export const resultUpdateRole = (Authorization, id, roleUpdate) => {
  return axiosSesion.put(`/api/v1/roles/${id}`, roleUpdate, {
    headers: { Authorization },
  });
};

// Disable rol
export const resultDisableRole = (Authorization, nameController, id, route) => {
  return axiosSesion.patch(
    `/api/v1/${nameController}/${id}/${route}`,
    {},
    {
      headers: { Authorization },
    },
  );
};
