import { dateWithTimezone } from '../../../utils/dateFormatter';

export const areasAdapter = (data, handleModal) => {
  const language = localStorage.getItem('language');

  const response = [];
  data.forEach((item) => {
    if (item.name.includes('xxx')) return;

    response.push({
      id: item.id,
      isEnabled: item.is_enabled,
      name:
        language === 'en' ? item.name.split('||')[1] : item.name.split('||')[0],
      areaType:
        language === 'en'
          ? item.area_type.split('||')[1]
          : item.area_type.split('||')[0],
      createdAt: dateWithTimezone(item.created_at),
      handleModal,
    });
  });

  return response;
};
