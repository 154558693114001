import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonCancel from './ButtonCancel'
import ButtonGeneral from './ButtonGeneral'
import { LANGUAGES_FILES } from '../constants/enums'

import { AiOutlineClose } from 'react-icons/ai';
import { IoWarningOutline } from "react-icons/io5"

export default function PrivacyPolicy({ handleClick, handleClose, URL }) {
  const [t] = useTranslation(LANGUAGES_FILES.GLOBAL)
  const [isWarning, setIsWarning] = useState(false)

  const reject = () => {
    setIsWarning(true)
  }

  return (
    <main className="bg-white w-[450px] flex flex-col gap-4 rounded-b-[5px]">
      <header className="bg-[#273447] text-white flex flex-col gap-2 justify-center py-4 items-center relative">
        <span className="text-[24px] font-semibold">
          {t('global.layout.modals.privacy-policy.title')}
        </span>

        {isWarning ? (
          <div className='absolute right-2 top-2'>
            <button
              className='text-white hover:text-[#50B8E4]'
              onClick={handleClose}
            >
              <AiOutlineClose size={18} />
            </button>
          </div>
        ) : null}
      </header>

      {isWarning ? (
        <section className="flex flex-col px-6 pt-2 pb-8 gap-5 items-center">
          <div className="text-[#8B5502]">
            <IoWarningOutline size={70} />
          </div>

          <span className="text-[13px] font-semibold">
            {t('global.layout.modals.privacy-policy.warning')}
          </span>
        </section>
      ) : (
        <section className="flex flex-col px-6 pt-2 pb-6 gap-5 items-center">
          <div className="text-[13px]">
            <span>
              {t('global.layout.modals.privacy-policy.paragraph-1')}
            </span>

            <span className='ml-[4px] font-bold text-[#273447] hover:text-[#50B8E4] hover:underline'>
              <a
                href={URL}
                target='_blank'
              >
                {t('global.layout.modals.privacy-policy.link')}
              </a>
            </span>
          </div>

          <span className="text-[13px] font-semibold">
            {t('global.layout.modals.privacy-policy.paragraph-2')}
          </span>

          <div className="flex gap-5 mt-3">
            <ButtonGeneral
              value={t('global.layout.modals.privacy-policy.buttons.accept')}
              onClick={handleClick}
            />
            <ButtonCancel
              value={t('global.layout.modals.privacy-policy.buttons.reject')}
              onClick={reject}
            />
          </div>
        </section>
      )}
    </main>
  )
}
