import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LANGUAGES_FILES, ALERT_TYPE } from '../../../constants/enums';

import {
  getAllIataFlights,
  getFligthsConfigurations,
  getFlightsByParams,
} from '../../../services/api/v1/flight_requests/flightRequests';
import { resultUserPermissions } from '../../../services/api/v1/permission_requests/permissionRequests';
import { airlinesAllowedPerUser } from '../../../services/api/v1/user_requests/userRequests';
import { stationsAllowedPerUser } from '../../../services/api/v1/user_requests/userRequests';

import { createNewAlert } from '../../../redux/reducer/alert-messages/alerts.actions';
import {
  setNavbarInfo,
  clearNavbar,
} from '../../../redux/reducer/navbar/navbar.reducers';

import {
  airlinesAdapter,
  airportsAdapter,
  iataFlightsAdapter,
  flightParameterizationAdapter,
} from '../adapters/flights';

import RowsTableFlightParameterization from './table-flight-parameterization/RowsTableFlightParameterization';
import SecondLoading from '../../../components/SecondLoading';
import ButtonGeneral from '../../../components/ButtonGeneral';
import SelectAutocomplete from '../../../components/SelectAutocomplete';
import DataTable from '../../../components/data_table/DataTable';
import SearchBar from '../../../components/SearchBar';

import DecryptedToken from '../../../components/security_components/decrypted_token/decrypted_token';

const columnsToSearch = ['flightCode', 'flightDistribution', 'configuredAt'];

export default function FlightParameterization() {
  const [t, e] = useTranslation(LANGUAGES_FILES.FLIGHTS);
  const authorization = DecryptedToken();
  const dispatch = useDispatch();

  const [updateSearch, setUpdateSearch] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [canConfigurateFlight, setCanConfigurateFlight] = useState(false);

  const [flightsListConfig, setFlightsListConfig] = useState(null);
  const [allFlights, setAllFlights] = useState(null);
  const [copyRows, setCopyRows] = useState(null);

  const [airlines, setAirlines] = useState(null);
  const [airports, setAirports] = useState(null);
  const [iataFlights, setIataFlights] = useState(null);

  const [flights, setFlights] = useState({ columns: [], rows: [] });

  // State selected
  const [iataFlight, setIataFlight] = useState('');
  const [airlineId, setAirlineId] = useState('');
  const [stationId, setStationId] = useState('');
  const [configured, setConfigured] = useState('');

  const { id } = useSelector((state) => state.authorization.informationUser);

  const columns = [
    {
      id: 1,
      title: t(
        'flight-parameterization.views.index.layout.tables.table-flight-parameterization.tabla.header.code',
      ),
      sortable: true,
      sortBy: 'flightCode',
      width: 130,
    },
    {
      id: 2,
      title: t(
        'flight-parameterization.views.index.layout.tables.table-flight-parameterization.tabla.header.distribution',
      ),
      sortable: true,
      sortBy: 'flightDistribution',
      width: 160,
    },
    {
      id: 3,
      title: t(
        'flight-parameterization.views.index.layout.tables.table-flight-parameterization.tabla.header.configuration-status',
      ),
      sortable: true,
      sortBy: 'configurationStatus',
      width: 210,
    },
    {
      id: 4,
      title: t(
        'flight-parameterization.views.index.layout.tables.table-flight-parameterization.tabla.header.configured-at',
      ),
      sortable: true,
      sortBy: 'configuredAt',
      width: 210,
    },
  ];

  if (canConfigurateFlight)
    columns.push({
      id: 5,
      title: t(
        'flight-parameterization.views.index.layout.tables.table-flight-parameterization.tabla.header.edit',
      ),
      width: 100,
      action: true,
    });

  const flightConfigured = [
    {
      id: 'all',
      name: t(
        'flight-parameterization.views.index.layout.forms.filter-flights.inputs.flight-configured-select.all-option',
      ),
    },
    {
      id: 'configured',
      name: t(
        'flight-parameterization.views.index.layout.forms.filter-flights.inputs.flight-configured-select.configured',
      ),
    },
    {
      id: 'not-configured',
      name: t(
        'flight-parameterization.views.index.layout.forms.filter-flights.inputs.flight-configured-select.not-configured',
      ),
    },
  ];

  const title = t('flight-parameterization.views.index.layout.title');

  const breadcrumbs = [
    {
      id: 1,
      text: t('flight-parameterization.views.index.layout.breadcrumbs.home'),
      path: '/dashboard',
    },
    {
      id: 2,
      text: t(
        'flight-parameterization.views.index.layout.breadcrumbs.flight-parameterization',
      ),
    },
  ];

  const handleFilter = (rowsFiltereds) => {
    setFlights({
      ...flights,
      rows: rowsFiltereds,
    });
  };

  const getFlightsFiltereds = async () => {
    setIsLoading(true);

    try {
      const configFilter =
        !configured || configured === 'all' ? '' : configured;

      let flightsFilter = [...copyRows];

      if (configFilter) {
        const toFind = configured === 'configured' ? true : false;
        flightsFilter = flightsFilter.filter(
          (item) => item.configurationStatus === toFind,
        );
      }

      if (stationId) {
        flightsFilter = flightsFilter.filter(
          (item) => item.stationId === stationId,
        );
      }

      if (airlineId) {
        flightsFilter = flightsFilter.filter(
          (item) => item.airlineId === airlineId,
        );
      }

      if (iataFlight) {
        flightsFilter = flightsFilter.filter(
          (item) => item.flightCode === iataFlight,
        );
      }

      setFlights({ ...flights, rows: flightsFilter });
      setUpdateSearch(updateSearch + 1);
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t(
            'flight-parameterization.views.index.messages.api.error.flight-filter-no-found',
          ),
          languageFile: LANGUAGES_FILES.FLIGHTS,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const setParams = (setState) => (id) => {
    setState(id);
  };

  const getPermissions = async () => {
    try {
      const configurateFlight = await resultUserPermissions(
        authorization,
        id,
        'flight_update',
      );

      setCanConfigurateFlight(configurateFlight.data.can);
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t(
            'flight-parameterization.views.index.messages.api.error.connect',
          ),
          languageFile: LANGUAGES_FILES.FLIGHTS,
        }),
      );
    }
  };

  const getDataToFilter = async () => {
    try {
      const getAirlines = await airlinesAllowedPerUser(authorization, id);
      setAirlines(airlinesAdapter(getAirlines.data));

      const getAirports = await stationsAllowedPerUser(authorization, id);
      setAirports(airportsAdapter(getAirports.data));

      const getIataFlights = await getAllIataFlights(authorization, id);
      setIataFlights(iataFlightsAdapter(getIataFlights.data));
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t(
            'flight-parameterization.views.index.messages.api.error.no-data-to-filter',
          ),
          languageFile: LANGUAGES_FILES.FLIGHTS,
        }),
      );
    }
  };

  const getDataToTable = async () => {
    try {
      const { data } = await getFlightsByParams(authorization, 'type=select2');
      setAllFlights(data);
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t(
            'flight-parameterization.views.index.messages.api.error.connect',
          ),
          languageFile: LANGUAGES_FILES.FLIGHTS,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getFlightsConfig = async () => {
    try {
      const { data } = await getFligthsConfigurations(authorization);
      setFlightsListConfig(data);
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t(
            'flight-parameterization.views.index.messages.api.error.connect',
          ),
          languageFile: LANGUAGES_FILES.FLIGHTS,
        }),
      );
    }
  };

  useEffect(() => {
    getDataToFilter();
    getDataToTable();
    getFlightsConfig();
    getPermissions();
  }, []);

  useEffect(() => {
    if (allFlights && flightsListConfig) {
      setCopyRows(flightParameterizationAdapter(allFlights, flightsListConfig));
      setFlights({
        ...flights,
        rows: flightParameterizationAdapter(allFlights, flightsListConfig),
      });
    }
  }, [allFlights, flightsListConfig]);

  useEffect(() => {
    dispatch(setNavbarInfo({ title, breadcrumbs }));

    return () => {
      dispatch(clearNavbar());
    };
  }, [e.language]);

  return (
    <main className="w-full min-w-[280px] h-auto flex flex-col gap-1 pb-4">
      <section
        className="
          w-full bg-white grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3
          xl:px-5 gap-4 place-items-center shadow-md border py-5 mb-1 sm:rounded-[6px]
        "
      >
        <SelectAutocomplete
          data={flightConfigured}
          setParams={setParams(setConfigured)}
          label={t(
            'flight-parameterization.views.index.layout.forms.filter-flights.labels.flight-configured',
          )}
          disabled={isLoading}
        />

        <SelectAutocomplete
          data={airports}
          setParams={setParams(setStationId)}
          label={t(
            'flight-parameterization.views.index.layout.forms.filter-flights.labels.airport-select',
          )}
          disabled={isLoading}
        />

        <SelectAutocomplete
          data={airlines}
          setParams={setParams(setAirlineId)}
          label={t(
            'flight-parameterization.views.index.layout.forms.filter-flights.labels.airline-select',
          )}
          disabled={isLoading}
        />

        <SelectAutocomplete
          data={iataFlights}
          setParams={setParams(setIataFlight)}
          label={t(
            'flight-parameterization.views.index.layout.forms.filter-flights.labels.flight-code-select',
          )}
          disabled={isLoading}
        />

        <div className="col-span-1">
          <ButtonGeneral
            value={t(
              'flight-parameterization.views.index.layout.forms.filter-flights.buttons.filter',
            )}
            onClick={getFlightsFiltereds}
            width={130}
            disabled={isLoading}
          />
        </div>
      </section>

      <section className="w-full h-[75%] min-h-[500px] bg-white sm:rounded-[6px] shadow-md border pb-5 pt-4 relative">
        {isLoading && (
          <SecondLoading
            size={100}
            color={'#475569'}
            rounded={'6px'}
            opacity={'0.4'}
          />
        )}

        {columns.length ? (
          <div className="h-full flex flex-col items-center gap-4 xs:px-1 sm:px-4 md:px-5">
            <div className="w-full grid grid-cols-1 gap-3 md:grid-cols-3">
              <div className="w-full flex justify-center md:col-start-2">
                <SearchBar
                  list={flights.rows}
                  handleFilter={handleFilter}
                  columnsToSearch={columnsToSearch}
                  placeholder={t(
                    'flight-parameterization.views.index.layout.tables.table-flight-parameterization.searchbar',
                  )}
                  disabled={isLoading}
                  update={updateSearch}
                />
              </div>
            </div>

            <DataTable
              data={flights}
              headers={columns}
              RowsComponent={RowsTableFlightParameterization}
              permission={canConfigurateFlight}
              noData={t(
                'flight-parameterization.views.index.layout.tables.table-flight-parameterization.tabla.no-data',
              )}
            />
          </div>
        ) : null}
      </section>
    </main>
  );
}
