export default function InputLogin({
  icon: Icon,
  type,
  name,
  value,
  placeholder,
  onChange,
  error,
  disabled = false,
}) {
  return (
    <section className="w-9/12 flex flex-col gap-1">
      <div
        className="w-full relative"
        style={{ borderBottom: 'solid 2px #94a3b8' }}
      >
        <div className="absolute bottom-0 left-1">
          <Icon size={26} color="#94a3b8" />
        </div>
        <input
          className="focus:outline-none w-full pl-12 pt-2 disabled:opacity-60"
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          autoComplete={`${type === 'password' ? 'current-password' : 'on'}`}
          disabled={disabled}
        />
      </div>

      {error && <span className="text-[13px] text-red-500 pl-1">{error}</span>}
    </section>
  );
}
