import { axiosSesion } from '../axios';

// Get all identification types
export const resultIdentificationTypes = (Authorization) => {
  return axiosSesion.get('/api/v1/identification_types', {
    headers: { Authorization },
  });
};

// Get identification type by ID
export const resultGetUserIdentificationTypeId = (Authorization, id) => {
  return axiosSesion.get(`/api/v1/identification_types/${id}`, {
    headers: { Authorization },
  });
};

// Create new identification type
export const resultNewUserIdentificationType = (Authorization, newIdType) => {
  return axiosSesion.post('api/v1/identification_types', newIdType, {
    headers: { Authorization },
  });
};

// Update identification type
export const resultUpdateIdentificationType = (
  Authorization,
  id,
  idTypeUpdate,
) => {
  return axiosSesion.put(`/api/v1/identification_types/${id}`, idTypeUpdate, {
    headers: { Authorization },
  });
};
