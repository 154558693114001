import {
  SET_EDIT_POSITION,
  SET_GET_POSITIONS,
  SET_GET_POSITION_CHECKED,
  SET_GET_POSITION_ID,
  SET_NEW_POSITION,
  SET_POSITIONS,
  SET_POSITION_ID,
  SET_UPDATE_POSITION,
} from '../types/positionsTypes';

const initialState = {
  resultPositions: [],
  resultNewPosition: [],
  resultPositionId: [],
  resultEditPosition: [],
  resultGetPositions: [],
  resultGetPositionId: [],
  resultUpdatePosition: [],
  resultGetPositionChecked: [],
};

function positionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_POSITIONS:
      return { ...state, resultPositions: action.payload };

    case SET_NEW_POSITION:
      return { ...state, resultNewPosition: action.payload };

    case SET_POSITION_ID:
      return { ...state, resultPositionId: action.payload };

    case SET_EDIT_POSITION:
      return { ...state, resultEditPosition: action.payload };

    case SET_GET_POSITIONS:
      return { ...state, resultGetPositions: action.payload };

    case SET_GET_POSITION_ID:
      return { ...state, resultGetPositionId: action.payload };

    case SET_UPDATE_POSITION:
      return { ...state, resultUpdatePosition: action.payload };

    case SET_GET_POSITION_CHECKED:
      return { ...state, resultGetPositionChecked: action.payload };

    default:
      return state;
  }
}

export default positionsReducer;
