// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import PermissionTypes from '../../pages/permissions_page/components/PermissionTypes';

// Pages imports with lazy loading
const NewPermissionType = lazy(() =>
  import('../../pages/permissions_page/components/NewPermissionType'),
);
const EditPermissionType = lazy(() =>
  import('../../pages/permissions_page/components/EditPermissionType'),
);

function PermissionTypesRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const permissionTypesRoutes = [
    {
      path: 'configuration/permission_types',
      element: <PermissionTypes />,
      isAllowed: customPermission.permission_type_index,
    },
    {
      path: 'configuration/permission_types/new',
      element: <NewPermissionType />,
      isAllowed: customPermission.permission_type_create,
    },
    {
      path: 'configuration/permission_types/edit/:id',
      element: <EditPermissionType />,
      isAllowed: customPermission.permission_type_update,
    },
  ];

  return permissionTypesRoutes;
}

export default PermissionTypesRoutes;
