// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import Cities from '../../pages/cities_page/components/Cities';

// Pages imports with lazy loading
const NewCity = lazy(() =>
  import('../../pages/cities_page/components/NewCity'),
);
const EditCity = lazy(() =>
  import('../../pages/cities_page/components/EditCity'),
);

function CitiesRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const citiesRoutes = [
    {
      path: 'parameters/cities',
      element: <Cities />,
      isAllowed: customPermission.city_index,
    },
    {
      path: 'parameters/cities/new',
      element: <NewCity />,
      isAllowed: customPermission.city_create,
    },
    {
      path: 'parameters/cities/edit/:id',
      element: <EditCity />,
      isAllowed: customPermission.city_update,
    },
  ];

  return citiesRoutes;
}

export default CitiesRoutes;
