import { useRef } from 'react';
import { FaRegEdit } from 'react-icons/fa';

export default function EditRole({ roleId, isDisabled = false }) {
  const linkRef = useRef(null);

  const handleClick = (event) => {
    event.preventDefault();
    if (linkRef.current) {
      window.open(linkRef.current.href, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className="flex justify-center relative">
      <a
        className={`text-[#273447] hover:text-[#239DF7] ${isDisabled && 'pointer-events-none'}`}
        href={`/parameters/roles/edit/${roleId}`}
        ref={linkRef}
        onClick={handleClick}
      >
        <FaRegEdit size={18} />
      </a>
    </div>
  );
}
