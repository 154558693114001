import i18next from 'i18next';
import { LANGUAGES } from '../constants/enums';

import globalEn from './en/global.json';
import globalEs from './es/global.json';
import navbarEn from './en/navbar.json';
import navbarEs from './es/navbar.json';
import loginEn from './en/login.json';
import loginEs from './es/login.json';
import forgotPasswordEn from './en/forgot-password.json';
import forgotPasswordEs from './es/forgot-password.json';
import flightsEn from './en/flights.json';
import flightsEs from './es/flights.json';
import usersEn from './en/users.json';
import usersEs from './es/users.json';
import rolesEn from './en/roles.json';
import rolesEs from './es/roles.json';
import idTypesEn from './en/identification-types.json';
import idTypesEs from './es/identification-types.json';
import jobPositionsEn from './en/job-positions.json';
import jobPositionsEs from './es/job-positions.json';
import companiesEn from './en/companies.json';
import companiesEs from './es/companies.json';
import countriesEn from './en/countries.json';
import countriesEs from './es/countries.json';
import citiesEn from './en/cities.json';
import citiesEs from './es/cities.json';
import airportsEn from './en/airports.json';
import airportsEs from './es/airports.json';
import airlinesEn from './en/airlines.json';
import airlinesEs from './es/airlines.json';
import areasEn from './en/areas.json';
import areasEs from './es/areas.json';
import positionsEn from './en/positions.json';
import positionsEs from './es/positions.json';
import sectionsEn from './en/sections.json';
import sectionsEs from './es/sections.json';
import permissionsEn from './en/permissions.json';
import permissionsEs from './es/permissions.json';
import menusEn from './en/menus.json';
import menusEs from './es/menus.json';
import dashboardEn from './en/dashboard.json';
import dashboardEs from './es/dashboard.json';
import operatorsEn from './en/operators.json';
import operatorsEs from './es/operators.json';
import settingsEn from './en/settings.json';
import settingsEs from './es/settings.json';

let language = '';

if (localStorage.getItem('language')) {
  language = localStorage.getItem('language');
  language = validateLanguage(language);
} else {
  const ln = navigator.language;
  language = validateLanguage(ln);
  localStorage.setItem('language', language);
}

function validateLanguage(language) {
  if (language.includes('es')) {
    return LANGUAGES.ES;
  }
  return LANGUAGES.EN;
}

i18next.init({
  interpolation: { escapeValue: false },
  lng: language,
  resources: {
    en: {
      global: globalEn,
      navbar: navbarEn,
      login: loginEn,
      forgotPassword: forgotPasswordEn,
      flights: flightsEn,
      users: usersEn,
      roles: rolesEn,
      idTypes: idTypesEn,
      jobPositions: jobPositionsEn,
      companies: companiesEn,
      countries: countriesEn,
      cities: citiesEn,
      airports: airportsEn,
      airlines: airlinesEn,
      areas: areasEn,
      positions: positionsEn,
      sections: sectionsEn,
      permissions: permissionsEn,
      menus: menusEn,
      dashboard: dashboardEn,
      operators: operatorsEn,
      settings: settingsEn,
    },
    es: {
      global: globalEs,
      navbar: navbarEs,
      login: loginEs,
      forgotPassword: forgotPasswordEs,
      flights: flightsEs,
      users: usersEs,
      roles: rolesEs,
      idTypes: idTypesEs,
      jobPositions: jobPositionsEs,
      companies: companiesEs,
      countries: countriesEs,
      cities: citiesEs,
      airports: airportsEs,
      airlines: airlinesEs,
      areas: areasEs,
      positions: positionsEs,
      sections: sectionsEs,
      permissions: permissionsEs,
      menus: menusEs,
      dashboard: dashboardEs,
      operators: operatorsEs,
      settings: settingsEs,
    },
  },
});

export const translations = i18next;
