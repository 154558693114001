// External imports
import { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

// Components react imports
import Login from '../pages/login_page/components/Login';
import ForgotPassword from '../pages/forgot_password_page/components/ForgotPassword';
import PasswordRecovery from '../pages/forgot_password_page/components/PasswordRecovery';
import HomePage from '../pages/home_page/home_page';
import ProtectedRoutes from '../components/security_components/protected_routes/protected_routes';

// Routes imports
import HomeRoutes from './home_routes/home_routes';

import DecryptedToken from '../components/security_components/decrypted_token/decrypted_token';

function RoutesApp() {
  const homeRoutes = HomeRoutes();
  const authorization = DecryptedToken();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (authorization && pathname === '/') {
      navigate('/dashboard');
    }
  }, []);

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <ProtectedRoutes isAllowed={true} nameComponent="login">
            <Login />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/password_forgot"
        element={
          <ProtectedRoutes isAllowed={true} nameComponent="forgot">
            <ForgotPassword />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/password_recovery"
        element={
          <ProtectedRoutes isAllowed={true} nameComponent="recovery">
            <PasswordRecovery />
          </ProtectedRoutes>
        }
      />

      <Route
        path="*"
        element={
          <ProtectedRoutes isAllowed={true}>
            <h1>404 not found</h1>
          </ProtectedRoutes>
        }
      />

      {authorization && (
        <Route path="/" element={<HomePage />}>
          {homeRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <ProtectedRoutes isAllowed={route.isAllowed}>
                  <Suspense fallback={<div></div>}>{route.element}</Suspense>
                </ProtectedRoutes>
              }
            />
          ))}
        </Route>
      )}
    </Routes>
  );
}

export default RoutesApp;
