import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  selectMenu,
  selectSubMenu,
  selectLowMenu,
} from '../../../../redux/reducer/menu-selected/menuSelected.actions';
import { setOpenSidebarWithModal } from '../../../../redux/reducer/sidebar/sidebar.reducers';

export default function SimpleButton({
  icon: Icon,
  text,
  goTo,
  name,
  menuType,
  stroke,
  canShow = false,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isContractedMenu } = useSelector((state) => state.contractedMenu);

  const { menuSelected, subMenuSelected, menuLowSelected } = useSelector(
    (state) => state.selectMenus,
  );

  const handleClick = () => {
    const toDispatch =
      menuType === 'low'
        ? selectLowMenu
        : menuType === 'medium'
        ? selectSubMenu
        : selectMenu;

    dispatch(toDispatch(name));
    navigate(goTo);
    dispatch(setOpenSidebarWithModal(false));
  };

  return (
    <>
      {canShow ? (
        <button
          className={`text-[13px] text-[#FFFFFF] py-[9px] flex ${
            isContractedMenu && 'xl:justify-center'
          } gap-2 w-full ${
            menuSelected === name ||
            subMenuSelected === name ||
            menuLowSelected === name
              ? 'bg-[#303750]'
              : 'bg-transparent'
          }`}
          style={{
            paddingLeft: `${
              isContractedMenu
                ? '0'
                : menuType === 'low'
                ? '30px'
                : menuType === 'medium'
                ? '20px'
                : '10px'
            }`,
            borderLeft: `${
              menuSelected === name ||
              subMenuSelected === name ||
              menuLowSelected === name
                ? '5px solid #50B8E4'
                : '5px solid transparent'
            }`,
            paddingRight: '5px',
          }}
          onClick={handleClick}
        >
          <Icon
            size={21}
            fill={
              stroke
                ? 'transparent'
                : menuSelected === name ||
                  subMenuSelected === name ||
                  menuLowSelected === name
                ? '#FFFFFF'
                : '#50B8E4'
            }
            stroke={
              !stroke
                ? 'none'
                : menuSelected === name ||
                  subMenuSelected === name ||
                  menuLowSelected === name
                ? '#FFFFFF'
                : '#50B8E4'
            }
          />

          {!isContractedMenu ? <span>{text}</span> : null}
        </button>
      ) : null}
    </>
  );
}
