// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import Airports from '../../pages/airports_page/components/Airports';

// Pages imports with lazy loading
const NewAirport = lazy(() =>
  import('../../pages/airports_page/components/NewAirport'),
);
const EditAirport = lazy(() =>
  import('../../pages/airports_page/components/EditAirport'),
);

function AirportsRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const airportsRoutes = [
    {
      path: 'parameters/airports',
      element: <Airports />,
      isAllowed: customPermission.station_index,
    },
    {
      path: 'parameters/airports/new',
      element: <NewAirport />,
      isAllowed: customPermission.station_create,
    },
    {
      path: 'parameters/airports/edit/:id',
      element: <EditAirport />,
      isAllowed: customPermission.station_update,
    },
  ];

  return airportsRoutes;
}

export default AirportsRoutes;
