import EnableDisableAction from '../../../../components/data_table/EnableDisableAction';
import EditPermission from './EditPermission';

export default function RowTablePermissions({ rows, permission }) {
  return (
    <>
      {rows.map((row) => (
        <tr
          key={row.id}
          className={`text-sm even:bg-slate-50 hover:bg-[#cdd5e4] ${
            row.isEnabled
              ? 'text-slate-800'
              : 'line-through text-opacity-70 text-slate-950'
          }`}
        >
          <td
            className="p-2.5 select-none"
            style={{
              borderTop: '1px solid #e2e8f0',
              borderBottom: '1px solid #e2e8f0',
            }}
          >
            {row.code}
          </td>

          <td
            className="p-2.5 select-none"
            style={{ border: '1px solid #e2e8f0' }}
          >
            {row.name}
          </td>

          <td
            className="p-2.5 select-none"
            style={{ border: '1px solid #e2e8f0' }}
          >
            {row.description}
          </td>

          <td
            className="p-2.5 select-none"
            style={{ border: '1px solid #e2e8f0' }}
          >
            {row.permissionLevel}
          </td>

          <td
            className="p-2.5 select-none"
            style={{ border: '1px solid #e2e8f0' }}
          >
            {row.sectionName}
          </td>

          <td
            className="p-2.5 select-none"
            style={{
              borderTop: '1px solid #e2e8f0',
              borderBottom: '1px solid #e2e8f0',
              borderRight: `${permission ? '1px solid #e2e8f0' : 'none'}`,
            }}
          >
            {row.createdAt}
          </td>

          {permission.canUpdatePermissions && (
            <td
              style={{
                borderTop: '1px solid #e2e8f0',
                borderBottom: '1px solid #e2e8f0',
                borderRight: `${
                  permission.canEnableDisablePermission
                    ? '1px solid #e2e8f0'
                    : 'none'
                }`,
              }}
            >
              <EditPermission permissionId={row.id} />
            </td>
          )}

          {permission.canEnableDisablePermission && (
            <td
              style={{
                borderTop: '1px solid #e2e8f0',
                borderBottom: '1px solid #e2e8f0',
              }}
            >
              <EnableDisableAction
                isEnabled={row.isEnabled}
                infoModal={{
                  handleModal: row.handleModal,
                  info: {
                    id: row.id,
                    name: row.name,
                    isEnabled: row.isEnabled,
                  },
                }}
              />
            </td>
          )}
        </tr>
      ))}
    </>
  );
}
