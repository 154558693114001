import { axiosSesion } from '../axios';

// Get all countries
export const resultCountries = (Authorization) => {
  return axiosSesion.get('/api/v1/countries', { headers: { Authorization } });
};

// Get countries enables
export const getCountriesEnables = (Authorization) => {
  return axiosSesion.get('/api/v1/countries/without_disables', {
    headers: { Authorization },
  });
};

// Get country by ID
export const resultGetCountry = (Authorization, id) => {
  return axiosSesion.get(`/api/v1/countries/${id}`, {
    headers: { Authorization },
  });
};

// Create new country
export const resultNewCountry = (Authorization, newCountry) => {
  return axiosSesion.post('/api/v1/countries', newCountry, {
    headers: { Authorization },
  });
};

// Update country
export const resultEditCountry = (Authorization, id, countryUpdate) => {
  return axiosSesion.put(`/api/v1/countries/${id}`, countryUpdate, {
    headers: { Authorization },
  });
};

// Get all time zones
export const resultGetTimezones = (Authorization) => {
  return axiosSesion.get('/api/v1/countries/timezones', {
    headers: { Authorization },
  });
};
