import { axiosSesion } from '../axios';

// Get all companies
export const resultGetCompanies = (Authorization) => {
  return axiosSesion.get('/api/v1/companies', {
    headers: { Authorization },
  });
};

// Get company by ID
export const resultGetCompanyId = (Authorization, id) => {
  return axiosSesion.get(`/api/v1/companies/${id}`, {
    headers: { Authorization },
  });
};

// Create a new company
export const resultNewCompany = (
  Authorization,
  country_id,
  company_service_id,
  name,
  logo,
) => {
  return axiosSesion.post(
    '/api/v1/companies',
    {
      company: {
        country_id,
        company_service_id,
        name,
        logo,
      },
    },
    {
      headers: { Authorization },
    },
  );
};

// Update company
export const resultUpdateCompany = (Authorization, id, company) => {
  return axiosSesion.put(`/api/v1/companies/${id}`, company, {
    headers: { Authorization },
  });
};

// Change company image
export const resultUpdateCompanyImage = (Authorization, id, company) => {
  return axiosSesion.put(`/api/v1/companies/${id}`, company, {
    headers: { Authorization },
  });
};
