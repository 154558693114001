import { combineReducers } from 'redux';

import { alerts } from './alert-messages/alerts.reducers';
import { selectMenus } from './menu-selected/menuSelected.reducers';
import { elementChecked } from './element-checked/elementChecked.reducers';
import { contractedMenu } from './contracted-menu/contractedMenuReducer';
import { googleMaps } from './google-maps/googleMapsReducer';
import { navbar } from './navbar/navbar.reducers';
import { sidebar } from './sidebar/sidebar.reducers';

import airlinesReducer from './airlines/reducers/airlinesReducer';
import airportsReducer from './airports/reducers/airportsReducer';
import alertsReducer from './alerts/reducers/alertsReducer';
import areasReducer from './areas/reducers/areasReducer';
import citiesReducer from './cities/reducers/citiesReducer';
import companiesReducer from './companies/reducers/companiesReducer';
import countriesReducer from './countries/reducers/countriesReducer';
import errorsReducer from './errors/reducer/errorsReducer';
import flightsReducer from './flights/reducers/flightsReducer';
import footerReducer from './footer/reducers/footerReducer';
import ganttDataReducer from './gantt_data/reducers/ganttDataReducer';
import headerReducer from './header/reducers/headerReducer';
import identificationsReducer from './identifications/reducers/identificationsReducer';
import jobPositionsReducer from './job_positions/reducers/jobPositionsReducer';
import menusReducer from './menus/reducers/menusReducer';
import operationsReducer from './operations/reducers/operationsReducer';
import permissionsReducer from './permissions/reducers/permissionsReducer';
import positionsReducer from './positions/reducers/positionsReducer';
import profileReducer from './profile/reducers/profileReducer';
import recordsAndReportsReducer from './records_and_reports/reducers/recordsAndReportsReducer';
import rolesReducer from './roles/reducers/rolesReducer';
import sectionsReducer from './sections/reducers/sectionsReducer';
import stationsReducer from './stations/reducers/stationsReducer';
import usersReducer from './users/reducers/usersReducer';
import frequencyFlightsReducer from './frequency_flights/reducers/frequencyFlightsReducers';
import authorizationsReducer from './authorization/reducers/authorizationsReducer';

const reducer = combineReducers({
  alertsMessages: alerts,
  selectMenus,
  elementChecked,
  contractedMenu,
  googleMaps,
  navbar,
  sidebar,
  authorization: authorizationsReducer,
  airports: airportsReducer,
  airlines: airlinesReducer,
  users: usersReducer,
  flights: flightsReducer,
  roles: rolesReducer,
  permissions: permissionsReducer,
  companies: companiesReducer,
  countries: countriesReducer,
  cities: citiesReducer,
  gantt: ganttDataReducer,
  areas: areasReducer,
  positions: positionsReducer,
  jobPositions: jobPositionsReducer,
  identifications: identificationsReducer,
  operations: operationsReducer,
  menus: menusReducer,
  profile: profileReducer,
  recordsAndReports: recordsAndReportsReducer,
  stations: stationsReducer,
  sections: sectionsReducer,
  header: headerReducer,
  footer: footerReducer,
  errors: errorsReducer,
  alerts: alertsReducer,
  frequencyFlights: frequencyFlightsReducer,
});

export default reducer;
