import {
  SET_FREQUENCY_FLIGHTS_COLUMNS,
  SET_FREQUENCY_FLIGHTS_PARAMS,
  SET_FREQUENCY_FLIGHTS_ROWS,
} from '../types/frequencyFlightsTypes';

const initialState = {
  table: {
    columns: [],
    rows: [],
  },
  params: '',
};

function frequencyFlightsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FREQUENCY_FLIGHTS_PARAMS:
      return { ...state, params: action.payload };

    case SET_FREQUENCY_FLIGHTS_COLUMNS:
      return { ...state, table: { ...state.table, columns: action.payload } };

    case SET_FREQUENCY_FLIGHTS_ROWS:
      return { ...state, table: { ...state.table, rows: action.payload } };

    default:
      return state;
  }
}

export default frequencyFlightsReducer;
