import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { resultCustomPermissionsByUser } from '../../services/api/v1/permission_requests/permissionRequests';
import { setCustomPermissions } from '../../redux/reducer/permissions/actions/permissionsActions';

import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import SidebarWithModal from './components/SidebarWithModal';
import Profile from './components/profile/Profile';

import { LANGUAGES_FILES, ALERT_TYPE } from '../../constants/enums';

import { createNewAlert } from '../../redux/reducer/alert-messages/alerts.actions';

import DecryptedToken from '../../components/security_components/decrypted_token/decrypted_token';

export default function HomePage() {
  const authorization = DecryptedToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t] = useTranslation(LANGUAGES_FILES.GLOBAL)

  const [openProfile, setOpenProfile] = useState();

  const { id } = useSelector((state) => state.authorization.informationUser);
  const { openSidebarWithModal } = useSelector((state) => state.sidebar);

  const updateSelfPermissions = async () => {    
    try {
      const { data } = await resultCustomPermissionsByUser(id, authorization)
      dispatch(setCustomPermissions(data))
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('global.messages.api.error.connection'),
          languageFile: LANGUAGES_FILES.GLOBAL,
        }),
      )
    }
  }

  const handleOpenProfile = () => {
    setOpenProfile(!openProfile);
  };

  useEffect(() => {
    if (!authorization) navigate('/login');
    else updateSelfPermissions();
  }, []);

  return (
    <main className="bg-[#F3F6FE] w-full h-full p-0 m-0 flex overflow-hidden">
      <Sidebar isXl={true} />
      {openSidebarWithModal ? <SidebarWithModal /> : null}

      {openProfile ? <Profile handleCloseProfile={handleOpenProfile} /> : null}

      <main className="overflow-hidden w-full h-full flex flex-col">
        <Navbar handleOpenProfile={handleOpenProfile} />

        <section className="w-full h-full pt-2 pb-2 overflow-auto sm:px-3">
          <Outlet />
        </section>
      </main>
    </main>
  );
}
