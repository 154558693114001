import {
  SET_CITIES,
  SET_CITY_ID,
  SET_EDIT_CITY,
  SET_NEW_CITY,
} from '../types/citiesTypes';

const initialState = {
  resultCities: [],
  resultNewCity: [],
  resultCityId: [],
  resultEditCity: [],
};

function citiesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CITIES:
      return { ...state, resultCities: action.payload };

    case SET_NEW_CITY:
      return { ...state, resultNewCity: action.payload };

    case SET_CITY_ID:
      return { ...state, resultCityId: action.payload };

    case SET_EDIT_CITY:
      return { ...state, resultEditCity: action.payload };

    default:
      return state;
  }
}

export default citiesReducer;
