import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import './calendar.css';

import { dateBasic } from '../../utils/dateFormatter';

import { AiOutlineClear } from 'react-icons/ai';
import { RxCalendar } from 'react-icons/rx';

export default function DatePicker({
  setParams,
  defaultValue = false,
  min,
  max,
  placeholder,
  label,
  disabled,
}) {
  const language = localStorage.getItem('language');

  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [inputText, setInputText] = useState('');
  const [selected, setSelected] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleSelected = (value) => {
    const valueFormated = dateBasic(value);
    setInputText(valueFormated);
    setParams(valueFormated);
    setDate(value);
    setOpen(false);
    setSelected(true);
  };

  const handleClear = () => {
    setInputText('');
    setParams('');
    setDate(new Date());
    setSelected(false);
  };

  const handleChange = () => {};

  useEffect(() => {
    if (defaultValue) {
      setInputText(defaultValue);
      setSelected(true);
    }
  }, [defaultValue]);

  return (
    <main className="relative min-w-[250px] w-1/2 text-sm col-span-1 md:w-5/6">
      <span
        className={`font-semibold pl-1 ${
          disabled ? 'text-gray-300' : 'text-[#273447]'
        }`}
      >
        {label ? label : ''}
      </span>

      <section
        className="w-full h-8 flex justify-between pr-2 pl-[12px] py-[1px] mb-[3px] mt-1 rounded-[10px] shadow-md"
        style={{
          border: `${disabled ? '1px solid #d1d5db' : '1px solid #a3a3a3'}`,
        }}
      >
        <input
          className={`bg-transparent focus:outline-none w-full pt-[2px] pr-2 ${
            disabled && 'text-gray-300'
          }`}
          type="text"
          value={inputText}
          placeholder={placeholder ? placeholder : ''}
          disabled
          onChange={handleChange}
        />

        <button
          className={`${!selected ? 'hidden' : ''} ${
            disabled ? 'text-gray-300' : 'text-[#50B8E4] hover:text-[#273447]'
          }`}
          onClick={handleClear}
          disabled={disabled}
        >
          <AiOutlineClear size={20} />
        </button>

        <button
          className={`${selected && 'hidden'} ${
            disabled ? 'text-gray-300' : 'text-[#50B8E4] hover:text-[#273447]'
          }`}
          onClick={handleOpen}
          disabled={disabled}
        >
          <RxCalendar size={20} />
        </button>
      </section>

      {open && (
        <div
          className="absolute bg-white w-full rounded-[10px] max-h-auto overflow-hidden shadow-md z-40"
          style={{ border: '1px solid #50B8E4' }}
        >
          <Calendar
            className={`react-calendar`}
            onChange={handleSelected}
            value={date}
            locale={language}
            minDate={min ? min : ''}
            maxDate={max ? max : ''}
          />
        </div>
      )}
    </main>
  );
}
