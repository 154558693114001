import { useEffect, useState } from 'react';

import ShowFlightDetail from './ShowFlightDetail';
import ServiceToolTip from './ServiceToolTip';

export default function RowsTableFlightsToday({ rows, permission }) {
  const [activeRowColor, setActiveRowColor] = useState(null);
  const [cellColor, setCellColor] = useState({
    execute: (params) => choiseBgColor(params),
  });

  const choiseBgColor = ({ index, delayed, manHours }) => {
    if (activeRowColor === index) return '#dfe5ee';
    else if (delayed && delayed !== '00:00:00') return '#fecaca';
    else if (manHours && manHours > 0) return '#bbf7d0';
    else if (!manHours && manHours <= 0) return '#fef08a';
    else if (index === 0 || index % 2 === 0) return '#ffff';
    else return '#f8fafc';
  };

  useEffect(() => {
    if (activeRowColor >= 0) {
      setCellColor({ execute: (params) => choiseBgColor(params) });
    }
  }, [activeRowColor]);

  return (
    <>
      {rows.map((row, rowIndex) => (
        <tr
          key={row.id}
          className="text-slate-800 text-sm"
          onMouseEnter={() => setActiveRowColor(rowIndex)}
        >
          <td
            className="p-2.5 select-none sticky -left-[16px] z-10"
            style={{
              borderRight: '1px solid #dddddd',
              borderBottom: '1px solid #dddddd',
              backgroundColor: `${cellColor.execute({ index: rowIndex })}`,
            }}
          >
            {!permission.canShowFlight ? (
              row.flightCode
            ) : (
              <ShowFlightDetail flightId={row.id} text={row.flightCode} />
            )}
          </td>

          <td
            className="p-2.5 select-none text-center sticky left-[170px] z-10"
            style={{
              borderRight: '1px solid #dddddd',
              borderBottom: '1px solid #dddddd',
              backgroundColor: `${cellColor.execute({ index: rowIndex })}`,
            }}
          >
            {row.scheduledDepartureTime}
          </td>

          <td
            className="p-2.5 select-none text-center sticky left-[381px] z-10"
            style={{
              borderRight: '1px solid #dddddd',
              borderBottom: '1px solid #dddddd',
              backgroundColor: `${cellColor.execute({ index: rowIndex })}`,
            }}
          >
            {row.scheduledActualTime}
          </td>

          <td
            className="p-2.5 select-none sticky left-[562px] z-10"
            style={{
              borderBottom: '1px solid #dddddd',
              borderRight: `${permission ? '1px solid #dddddd' : 'none'}`,
              backgroundColor: `${cellColor.execute({
                index: rowIndex,
                delayed: row.delayed,
              })}`,
            }}
          >
            <div className="flex gap-[7px]">
              <span>{row.departureState}</span>
              <span>
                {row.delayed !== '00:00:00' ? `(${row.delayed})` : ''}
              </span>
            </div>
          </td>

          {permission.services.length &&
            permission.services.map((item, index) => {
              const findService = row.companyServices.find(
                (element) => element.id === item.id,
              );
              return (
                <td
                  key={item.id}
                  className="relative"
                  style={{
                    borderBottom: '1px solid #dddddd',
                    borderRight: '1px solid #dddddd',
                    backgroundColor: `${cellColor.execute({
                      index: rowIndex,
                      manHours: findService?.man_hours,
                    })}`,
                  }}
                >
                  {findService ? (
                    <div className="group">
                      <div className="hidden group-hover:block">
                        <ServiceToolTip
                          row={rowIndex}
                          isLast={permission.services.length - 1 === index}
                          start={findService?.start_at}
                          end={findService?.end_at}
                          timeFormat={findService?.man_hours_format}
                          totalTime={findService?.man_hours.toFixed(2)}
                          companies={findService?.companies}
                        />
                      </div>
                      <p className="text-center font-semibold text-slate-800 text-xs">
                        {findService.man_hours.toFixed(2)}
                      </p>
                    </div>
                  ) : (
                    <p className="text-center font-semibold text-slate-800 text-xs">
                      {'--'}
                    </p>
                  )}
                </td>
              );
            })}

          {permission.services.length ? (
            <td
              className="text-center font-semibold text-slate-800 text-xs"
              style={{
                borderBottom: '1px solid #dddddd',
                backgroundColor: `${cellColor.execute({ index: rowIndex })}`,
              }}
            >
              {row.total}
            </td>
          ) : null}
        </tr>
      ))}
    </>
  );
}
