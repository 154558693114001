import {
  SET_GET_ROLE_ID,
  SET_NEW_ROLES,
  SET_ROLES,
  SET_ROLE_DISABLE,
  SET_UPDATE_ROLE,
  APPLY_PERMISSION_TO_ROLE,
} from '../types/rolesTypes';

const initialState = {
  resultRoles: [],
  resultGetRoleId: [],
  resultUpdateRole: [],
  resultRoleDisable: [],
  resultNewRole: [],
  applyPermissionToRole: null,
};

function rolesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ROLES:
      return { ...state, resultRoles: action.payload };

    case SET_GET_ROLE_ID:
      return { ...state, resultGetRoleId: action.payload };

    case SET_UPDATE_ROLE:
      return { ...state, resultUpdateRole: action.payload };

    case SET_ROLE_DISABLE:
      return { ...state, resultRoleDisable: action.payload };

    case SET_NEW_ROLES:
      return { ...state, resultNewRole: action.payload };

    case APPLY_PERMISSION_TO_ROLE:
      return {
       ...state,
        applyPermissionToRole: action.payload,
      };

    default:
      return state;
  }
}

export default rolesReducer;
