import { SET_NEW_SECTION, SET_SECTIONS } from '../types/sectionsTypes';

const initialState = {
  resultSections: [],
  resultNewSection: [],
};

function sectionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SECTIONS:
      return { ...state, resultSections: action.payload };

    case SET_NEW_SECTION:
      return { ...state, resultNewSection: action.payload };

    default:
      return state;
  }
}

export default sectionsReducer;
