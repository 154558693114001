import { dateWithOutTimezone } from '../../../utils/dateFormatter';

export const flightsAdapter = (data) => {
  const response = data.map((item) => ({
    id: item.id,
    flightCode: item.flight,
    flightDistribution: item.flight_distribution,
    scheduledDepartureTime: dateWithOutTimezone(item.departure_scheduled_time),
    scheduledActualTime: item.departure_actual_time
      ? dateWithOutTimezone(item.departure_actual_time)
      : '--',
    departureState: item.departure_state,
    companyServices: item.services.services_delivered,
    total: item.services.total_man_hours,
    delayed: item.delayed,
  }));

  return response;
};

export const companyServicesAdapter = (data) => {
  const copyData = [];
  const dataOrder = [
    { name: 'Security', order: 1 },
    { name: 'Ground Handling', order: 2 },
    { name: 'Fuel', order: 3 },
    { name: 'Catering', order: 4 },
    { name: 'Maintenance', order: 5 },
    { name: 'Airline', order: 6 },
    { name: 'Authority', order: 7 },
    { name: 'Cargo', order: 8 },
    { name: 'Freight', order: 9 },
    { name: 'Law firm', order: 10 },
    { name: 'Pax Service', order: 11 },
  ];

  data.forEach((item) => {
    const foundOrder = dataOrder.find((data) => data.name === item.name_en);
    if (foundOrder) {
      const copyItem = { ...item };
      copyItem.order = foundOrder.order;
      copyData.push(copyItem);
    }
  });

  copyData.sort((a, b) => a.order - b.order);
  const response = [];
  copyData.forEach((item) => {
    if (item.is_enabled) {
      response.push({
        id: item.id,
        name: item.name_en.toLowerCase().split(' ').join('-'),
        isEnabled: item.is_enabled,
      });
    }
  });

  return response;
};

export const airportsAdapter = (data) => {
  const response = [];
  data.forEach((item) => {
    if (item.name.includes('xxx')) return;

    response.push({
      id: item.id,
      name: `[${item.iata_code}] ${item.name}`,
    });
  });

  return response;
};

export const airlinesAdapter = (data) => {
  const response = [];
  data.forEach((item) => {
    if (item.name.includes('xxx')) return;

    response.push({
      id: item.id,
      name: `[${item.iata_code}] ${item.name}`,
    });
  });

  return response;
};

export const iataFlightsAdapter = (data) => {
  const response = data.map((item) => ({
    id: item,
    name: item,
  }));

  return response;
};
