import { LOAD_ELEMENTS } from './elementChecked.types';

const initialState = {
  object: '',
  elements: null,
};

export const elementChecked = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ELEMENTS:
      return {
        object: action.payload.object,
        elements: action.payload.elements,
      };

    default:
      return state;
  }
};
