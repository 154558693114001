import { dateWithTimezone } from '../../../utils/dateFormatter';

export const countriesAdapter = (data, handleModal) => {
  const language = localStorage.getItem('language');

  const response = [];
  data.forEach((item) => {
    if (item.name.includes('xxx')) return;

    response.push({
      id: item.id,
      isEnabled: item.is_enabled,
      isoCode: item.iso_code,
      name:
        language === 'en' ? item.name.split('||')[1] : item.name.split('||')[0],
      createdAt: dateWithTimezone(item.created_at),
      handleModal,
    });
  });

  return response;
};

export const currentCountryAdapter = (data) => {
  return {
    code: data.iso_code,
    fullName: data.name,
    nameEn: data.name.split('||')[1],
    nameEs: data.name.split('||')[0],
  };
};
