import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../constants/enums';

import es from '../assets/imgs/es.png';
import en from '../assets/imgs/en.png';

export default function SelectorLanguage() {
  const ln = localStorage.getItem('language');
  const languageSelected = ln.includes(LANGUAGES.EN)
    ? LANGUAGES.EN
    : LANGUAGES.ES;

  const [language, setLanguage] = useState(languageSelected);
  const [t, i18n] = useTranslation();

  const handleChangeLanguage = () => {
    if (language === LANGUAGES.EN) {
      setLanguage(LANGUAGES.ES);
      localStorage.setItem('language', LANGUAGES.ES);
      i18n.changeLanguage(LANGUAGES.ES);
    } else {
      setLanguage(LANGUAGES.EN);
      localStorage.setItem('language', LANGUAGES.EN);
      i18n.changeLanguage(LANGUAGES.EN);
    }
  };

  return (
    <button
      className="w-6 rounded-full hover:opacity-60"
      onClick={handleChangeLanguage}
    >
      {language === LANGUAGES.EN && <img src={en} alt="Language english" />}
      {language === LANGUAGES.ES && <img src={es} alt="Language spanish" />}
    </button>
  );
}
