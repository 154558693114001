import { axiosSesion } from '../axios';

// Get all cities
export const resultCities = (Authorization) => {
  return axiosSesion.get('/api/v1/cities', { headers: { Authorization } });
};

// Get city by ID
export const resultCityId = (Authorization, id) => {
  return axiosSesion.get(`/api/v1/cities/${id}`, {
    headers: { Authorization },
  });
};

// Create new city
export const resultNewCity = (Authorization, newCity) => {
  return axiosSesion.post('/api/v1/cities', newCity, {
    headers: { Authorization },
  });
};

// Update city
export const resultEditCity = (Authorization, id, cityUpdate) => {
  return axiosSesion.put(`/api/v1/cities/${id}`, cityUpdate, {
    headers: { Authorization },
  });
};
