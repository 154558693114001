import {
  SET_CUSTOM_PERMISSIONS,
  SET_APPLY_PERMISSION,
  SET_PERMISSION_TYPES,
  SET_NEW_PERMISSION_TYPE,
  SET_GET_PERMISSION_ID,
  SET_UPDATE_PERMISSION_TYPE,
  SET_PERMISSIONS,
  SET_NEW_PERMISSION,
  SET_GET_PERMISSION_TYPE_ID,
  SET_UPDATE_PERMISSION,
  SET_APPLY_PERMISSION_USER,
  SET_FLIGHT_PERMISSION,
  SET_PERMISSION_APPLY,
} from '../types/permissionsTypes';

const initialState = {
  resultCustomPermission: {},
  resultApplyPermission: [],
  resultPermissionTypes: [],
  resultNewPermissionType: [],
  resultGetPermissionTypeId: [],
  resultUpdatePermissionType: [],
  resultPermission: [],
  resultNewPermission: [],
  resultPermissionId: [],
  resultUpdatePermission: [],
  resultApplyPermissionUser: [],
  resultFlightPermission: [],
  resultPermissionApply: [],
};

function permissionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOM_PERMISSIONS:
      return { ...state, resultCustomPermission: action.payload };

    case SET_APPLY_PERMISSION:
      return { ...state, resultApplyPermission: action.payload };

    case SET_PERMISSION_TYPES:
      return { ...state, resultPermissionTypes: action.payload };

    case SET_NEW_PERMISSION_TYPE:
      return { ...state, resultNewPermissionType: action.payload };

    case SET_GET_PERMISSION_TYPE_ID:
      return { ...state, resultGetPermissionTypeId: action.payload };

    case SET_UPDATE_PERMISSION_TYPE:
      return { ...state, resultUpdatePermissionType: action.payload };

    case SET_PERMISSIONS:
      return { ...state, resultPermission: action.payload };

    case SET_NEW_PERMISSION:
      return { ...state, resultNewPermission: action.payload };

    case SET_GET_PERMISSION_ID:
      return { ...state, resultPermissionId: action.payload };

    case SET_UPDATE_PERMISSION:
      return { ...state, resultUpdatePermission: action.payload };

    case SET_APPLY_PERMISSION_USER:
      return { ...state, resultApplyPermissionUser: action.payload };

    case SET_FLIGHT_PERMISSION:
      return { ...state, resultFlightPermission: action.payload };

    case SET_PERMISSION_APPLY:
      return { ...state, resultPermissionApply: action.payload };

    default:
      return state;
  }
}

export default permissionsReducer;
