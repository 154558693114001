import {
  SELECT_LOWMENU,
  SELECT_MENU,
  SELECT_SUBMENU,
} from './menuSelected.types';

export const selectMenu = (payload) => ({ type: SELECT_MENU, payload });

export const selectSubMenu = (payload) => ({ type: SELECT_SUBMENU, payload });

export const selectLowMenu = (payload) => ({ type: SELECT_LOWMENU, payload });
