import { useSelector, useDispatch } from 'react-redux';

import { clearAlert } from '../redux/reducer/alert-messages/alerts.actions';

export const useErrorBoundary = () => {
  const dispatch = useDispatch();

  const { newAlert, type, message, languageFile, link } = useSelector(
    (state) => state.alertsMessages,
  );

  const closeAlert = () => {
    dispatch(clearAlert());
  };

  return { newAlert, type, message, languageFile, link, closeAlert };
};
