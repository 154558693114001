import { useEffect, useState } from 'react';
import {
  BsChevronLeft,
  BsChevronBarLeft,
  BsChevronRight,
  BsChevronBarRight,
} from 'react-icons/bs';

export default function PaginateTable({ pages, handleShowPage }) {
  const [pageSelected, setPageSelected] = useState(1);
  const [quantityPages, setQuantityPages] = useState([]);
  const [pagesVisibles, setPagesVisibles] = useState([]);
  const [rangeReference, setRangeReference] = useState({});

  const handleSelected = (item) => {
    setPageSelected(item);
    handleShowPage(item);

    if (item === quantityPages.length) {
      setPagesVisibles(rangeReference.end);
    }
  };

  const handleChangePage = (value) => {
    if (value === '+') {
      handleSelected(pageSelected + 1);
      if (pageSelected === pagesVisibles[pagesVisibles.length - 1]) {
        const copyPages = [...pagesVisibles];
        copyPages.push(pageSelected + 1);
        copyPages.shift();
        setPagesVisibles(copyPages);
      }
    } else if (value === 'last') {
      handleSelected(quantityPages.length);
      setPagesVisibles(rangeReference.end);
    } else if (value === '-') {
      handleSelected(pageSelected - 1);
      if (pageSelected === pagesVisibles[0]) {
        const copyPages = [...pagesVisibles];
        copyPages.unshift(pageSelected - 1);
        copyPages.pop();
        setPagesVisibles(copyPages);
      }
    } else if (value === 'first') {
      handleSelected(1);
      setPagesVisibles(rangeReference.start);
    }
  };

  const setArraysPages = () => {
    const array1 = [];
    const array2 = [];

    for (let i = 1; i <= pages; i++) {
      array1.push(i);
      if (i < 7) array2.push(i);
    }

    setQuantityPages(array1);
    setPagesVisibles(array2);
    setRangeReference({
      start: array2,
      end:
        array1.length < 6
          ? array1
          : array1.slice(array1.length - 6, array1.length),
    });
  };

  useEffect(() => {
    handleSelected(1);
    setArraysPages();
  }, [pages]);

  return (
    <section className="flex items-center">
      <button
        className="text-[#273447] hover:text-[#50B8E4] disabled:text-gray-300"
        onClick={() => handleChangePage('first')}
        disabled={pageSelected === 1}
      >
        <BsChevronBarLeft size={24} />
      </button>

      <button
        className="text-[#273447] hover:text-[#50B8E4] disabled:text-gray-300"
        onClick={() => handleChangePage('-')}
        disabled={pageSelected === 1}
      >
        <BsChevronLeft size={24} />
      </button>

      <section className="flex px-2 gap-2">
        {quantityPages.length && (
          <>
            {quantityPages.map((item) => (
              <div
                key={item}
                className={`text-[13px] text-[#273447] flex flex-col items-center select-none ${
                  !pagesVisibles.includes(item) ? 'hidden' : ''
                }`}
              >
                <span>{item}</span>
                <button
                  className={`w-[9px] h-[9px] rounded-full ${
                    pageSelected === item
                      ? 'bg-[#273447]'
                      : 'bg-gray-300 hover:bg-[#50B8E4]'
                  }`}
                  disabled={pageSelected === item}
                  onClick={() => handleSelected(item)}
                ></button>
              </div>
            ))}

            {!pagesVisibles.includes(quantityPages.length) &&
            pageSelected !== quantityPages.length ? (
              <div
                className={`text-[13px] text-[#273447] flex items-end select-none ${
                  quantityPages.includes('item') ? 'hidden' : ''
                }`}
              >
                <span className="flex items-start h-[28px]">{'...'}</span>
                <div className="flex flex-col items-center">
                  <span>{quantityPages.length}</span>
                  <button
                    className={`w-[9px] h-[9px] rounded-full ${
                      pageSelected === quantityPages.length
                        ? 'bg-[#273447]'
                        : 'bg-gray-300 hover:bg-[#50B8E4]'
                    }`}
                    disabled={pageSelected === quantityPages.length}
                    onClick={() => handleSelected(quantityPages.length)}
                  ></button>
                </div>
              </div>
            ) : null}
          </>
        )}
      </section>

      <button
        className="text-[#273447] hover:text-[#50B8E4] disabled:text-gray-300"
        onClick={() => handleChangePage('+')}
        disabled={pageSelected === quantityPages.length}
      >
        <BsChevronRight size={24} />
      </button>

      <button
        className="text-[#273447] hover:text-[#50B8E4] disabled:text-gray-300"
        onClick={() => handleChangePage('last')}
        disabled={pageSelected === quantityPages.length}
      >
        <BsChevronBarRight size={24} />
      </button>
    </section>
  );
}
