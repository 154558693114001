import { useNavigate } from 'react-router-dom';

export default function IconTextLink({ icon: Icon, text, path, textColor }) {
  const navigate = useNavigate();

  return (
    <button
      className={`
        w-fit flex gap-2 text-sm group md:w-full md:justify-center md:bg-white
        md:bg-opacity-50 md:py-[10px] md:font-semibold text-${textColor}
      `}
      onClick={() => navigate(path)}
    >
      <div>
        <Icon size={20} />
      </div>
      <span className="group-hover:underline">{text}</span>
    </button>
  );
}
