// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import Menus from '../../pages/menus_page/components/Menus';

// Pages imports with lazy loading
const NewMenu = lazy(() => import('../../pages/menus_page/components/NewMenu'));
const EditMenu = lazy(() =>
  import('../../pages/menus_page/components/EditMenu'),
);

function MenusRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const menusRoutes = [
    {
      path: 'configuration/menus',
      element: <Menus />,
      isAllowed: customPermission.menu_index,
    },
    {
      path: 'configuration/menus/new',
      element: <NewMenu />,
      isAllowed: customPermission.menu_create,
    },
    {
      path: 'configuration/menus/edit/:id',
      element: <EditMenu />,
      isAllowed: customPermission.menu_update,
    },
  ];

  return menusRoutes;
}

export default MenusRoutes;
