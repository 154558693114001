import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LOGO_TECH } from '../../../constants/logoTech';
import { EMAIL_REGUEX } from '../../../constants/regex';
import { ALERT_TYPE, LANGUAGES_FILES } from '../../../constants/enums';

import backImage from '../../../assets/imgs/back_image.png';

import { HiOutlineMail } from 'react-icons/hi';
import { FaAngleLeft } from 'react-icons/fa';

import { forgotPasswordMessages } from '../../../utils/errorMessages';

import { forgotPassword } from '../../../services/api/v1/user_requests/userRequests';

import {
  createNewAlert,
  clearAlert,
} from '../../../redux/reducer/alert-messages/alerts.actions';

import InputLogin from '../../login_page/components/InputLogin';
import ButtonGeneral from '../../../components/ButtonGeneral';
import FooterForm from '../../../components/FooterForm';
import IconTextLink from '../../../components/IconTextLink';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const [t, e] = useTranslation(LANGUAGES_FILES.FORGOT_PASSWORD);

  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendData = async () => {
    try {
      const res = await forgotPassword({ email, locale: e.language });
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.DONE,
          message: t('forgot-password.messages.api.correct.email-found'),
          languageFile: LANGUAGES_FILES.FORGOT_PASSWORD,
        }),
      );

      setDisabled(true);
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('forgot-password.messages.api.error.email-not-found'),
          languageFile: LANGUAGES_FILES.FORGOT_PASSWORD,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const validateErrors = (name, value) => {
    let newMessage = '';

    if (!value) newMessage = forgotPasswordMessages.web.emailEmpty;
    else if (!EMAIL_REGUEX.test(value))
      newMessage = forgotPasswordMessages.web.emailInvalid;

    if (newMessage) setErrors({ ...errors, [name]: newMessage });
    else setErrors({ ...errors, [name]: '' });
  };

  const closeAlert = () => {
    dispatch(clearAlert());
  };

  const handleChange = (setState) => (event) => {
    const { name, value } = event.target;
    validateErrors(name, value);
    setState(value);
    closeAlert();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const message = errors.email
      ? errors.email
      : !email
      ? forgotPasswordMessages.web.emailEmpty
      : '';

    if (message) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.WARNING,
          message,
          languageFile: LANGUAGES_FILES.FORGOT_PASSWORD,
        }),
      );
      return;
    }

    setIsLoading(true);
    sendData();
  };

  useEffect(() => {
    return () => {
      closeAlert();
    };
  }, []);

  return (
    <main className="h-full w-full flex flex-col items-center justify-center gap-3 sm:px-16 md:bg-[#273447] md:px-0">
      {isLoading && (
        <Modal>
          <Loading />
        </Modal>
      )}

      <div className="bg-white min-w-[300px] w-full h-full md:h-[501px] md:flex md:justify-between md:max-w-[660px] z-10">
        <section className="hidden h-full md:block relative">
          <div>
            <img src={backImage} alt="Background airplane" />
          </div>

          <div className="w-[263px] hidden absolute bottom-3 md:flex justify-center">
            <IconTextLink
              icon={FaAngleLeft}
              text={t('forgot-password.layout.links.to-login-page')}
              path={'/login'}
              textColor={'white'}
            />
          </div>
        </section>

        <section className="pt-12 pb-3 h-full flex flex-col items-center justify-between md:w-3/5">
          <div className="w-52 md:w-40">
            <img src={LOGO_TECH} alt="logo-longport-tech" />
          </div>

          <form
            className="m-0 py-4 w-full flex flex-col items-center gap-5"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col items-center gap-14 md:gap-8 w-full">
              <span className="text-sm text-slate-500">
                {t('forgot-password.layout.forms.labels.email')}
              </span>

              <InputLogin
                icon={HiOutlineMail}
                type="text"
                name="email"
                value={email}
                placeholder={t('forgot-password.layout.forms.inputs.email')}
                onChange={handleChange(setEmail)}
                error={errors.email && t(errors.email)}
                disabled={disabled}
              />
            </div>

            <div className="w-9/12 md:hidden">
              <IconTextLink
                icon={FaAngleLeft}
                text={t('forgot-password.layout.links.to-login-page')}
                path={'/login'}
                textColor={'slate-500'}
              />
            </div>

            <div className="w-full flex justify-center mt-24 md:mt-10">
              <ButtonGeneral
                value={t('forgot-password.layout.forms.buttons.send-email')}
                disabled={disabled}
              />
            </div>
          </form>

          <FooterForm />
        </section>
      </div>

      <p className="text-sm text-white font-light hidden md:block z-10">
        {`${t('forgot-password.layout.footer.part-1')} ${new Date().getFullYear()} ${t('forgot-password.layout.footer.part-2')}`}
      </p>

      <div className="w-1/2 h-1/2 hidden md:block absolute overflow-hidden right-0 bottom-0">
        <div className="bg-[#239DF7] opacity-60 rounded-full w-[500px] h-[500px] absolute left-56 top-28"></div>
      </div>
      <div className="w-1/2 h-1/2 hidden md:block absolute overflow-hidden left-0 top-0">
        <div className="bg-[#F3F6FE] opacity-60 rounded-full w-[500px] h-[500px] absolute right-56 bottom-28"></div>
      </div>
    </main>
  );
}
