import { SET_ALERT, SET_ERROR } from '../types/errorsTypes';

const initialState = {
  error: {},
  alert: false,
};

function errorsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ERROR:
      return { ...state, error: action.payload };

    case SET_ALERT:
      return { ...state, alert: action.payload };

    default:
      return state;
  }
}

export default errorsReducer;
