import { dateWithTimezone } from '../../../utils/dateFormatter';

export const permissionTypesAdapter = (data, handleModal) => {
  const language = localStorage.getItem('language');

  const response = [];
  data.forEach((item) => {
    if (item.code.includes('xxx')) return;

    response.push({
      id: item.id,
      isEnabled: item.is_enabled,
      code: item.code,
      name: language === 'en' ? item.name_en : item.name_es,
      createdAt: dateWithTimezone(item.created_at),
      handleModal,
    });
  });

  return response;
};
