import { BiBlock } from 'react-icons/bi';
import { MdDoneAll } from 'react-icons/md';

export default function EnableDisableAction({ isEnabled, infoModal }) {
  return (
    <div className="flex justify-center relative">
      <button
        className="text-[#273447] hover:text-[#50B8E4] absolute bottom-[-4px]"
        onClick={() => infoModal.handleModal(infoModal.info)}
      >
        {isEnabled ? <BiBlock size={18} /> : <MdDoneAll size={18} />}
      </button>
    </div>
  );
}
