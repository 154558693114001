import {
  SET_AUTHORIZATION,
  SET_INFORMATION_USER,
} from '../types/authorizationsTypes';

export const setAuthorization = (payload) => ({
  type: SET_AUTHORIZATION,
  payload,
});

export const setInformationUser = (payload) => ({
  type: SET_INFORMATION_USER,
  payload,
});
