import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ALERT_TYPE, LANGUAGES_FILES } from '../../../constants/enums';

import { resultUserPermissions } from '../../../services/api/v1/permission_requests/permissionRequests';
import { resultCompanyServices } from '../../../services/api/v1/company_services_requests/companyServicesRequests';
import { resultDisableRole } from '../../../services/api/v1/rol_requests/rolRequests';

import { createNewAlert } from '../../../redux/reducer/alert-messages/alerts.actions';
import {
  setNavbarInfo,
  clearNavbar,
} from '../../../redux/reducer/navbar/navbar.reducers';
import { companyServicesAdapter } from '../adapters/companyServices';

import RowsTableCompanyServices from './table-company-services/RowsTableCompanyServices';
import SecondLoading from '../../../components/SecondLoading';
import ButtonGeneral from '../../../components/ButtonGeneral';
import SearchBar from '../../../components/SearchBar';
import DataTable from '../../../components/data_table/DataTable';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';

import DecryptedToken from '../../../components/security_components/decrypted_token/decrypted_token';
import EnableDisableAlert from '../../../components/EnableDisableAlert';

const columnsToSearch = ['nameEn', 'nameEs', 'description', 'createdAt'];

export default function CompanyServices() {
  const [t, e] = useTranslation(LANGUAGES_FILES.COMPANIES);
  const authorization = DecryptedToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateSearch, setUpdateSearch] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [openDisableEnableModal, setOpenDisableEnableModal] = useState(false);

  const [companyServices, setCompanyServices] = useState([]);
  const [companyServiceInfo, setCompanyServiceInfo] = useState({
    id: null,
    name: null,
    isEnabled: null,
  });

  const [canUpdateCompanyServices, setCanUpdateCompanyServices] =
    useState(false);
  const [canEnableDisableCompanyService, setCanEnableDisableCompanyService] =
    useState(false);
  const [canCreateCompanyService, setCanCreateCompanyService] = useState(false);

  const { id } = useSelector((state) => state.authorization.informationUser);

  const columns = [
    {
      id: 1,
      title: t(
        'company-services.views.index.layout.tables.table-company-services.table.header.name-en',
      ),
      sortable: true,
      sortBy: 'nameEn',
      width: 150,
    },
    {
      id: 2,
      title: t(
        'company-services.views.index.layout.tables.table-company-services.table.header.name-es',
      ),
      sortable: true,
      sortBy: 'nameEs',
      width: 160,
    },
    {
      id: 3,
      title: t(
        'company-services.views.index.layout.tables.table-company-services.table.header.description',
      ),
      sortable: true,
      sortBy: 'description',
      width: 340,
    },
    {
      id: 4,
      title: t(
        'company-services.views.index.layout.tables.table-company-services.table.header.created-at',
      ),
      sortable: true,
      sortBy: 'createdAt',
      width: 195,
    },
  ];

  if (canUpdateCompanyServices)
    columns.push({
      id: 5,
      title: t(
        'company-services.views.index.layout.tables.table-company-services.table.header.edit',
      ),
      width: 90,
      action: true,
    });

  if (canEnableDisableCompanyService)
    columns.push({
      id: 6,
      title: t(
        'company-services.views.index.layout.tables.table-company-services.table.header.enabled',
      ),
      width: 180,
      action: true,
    });

  const title = t('company-services.views.index.layout.title');

  const breadcrumbs = [
    {
      id: 1,
      text: t('company-services.views.index.layout.breadcrumbs.home'),
      path: '/dashboard',
    },
    {
      id: 2,
      text: t(
        'company-services.views.index.layout.breadcrumbs.company-services',
      ),
    },
  ];

  const openModal = (value) => {
    setOpenDisableEnableModal(true);
    setCompanyServiceInfo(value);
  };

  const closeModal = () => {
    setOpenDisableEnableModal(false);
  };

  const handleEnableDisableCompanyService = async (id, action) => {
    setLoadingRequest(true);
    try {
      const { data } = await resultDisableRole(
        authorization,
        'company_services',
        id,
        action,
      );
      await getDataToTable();
      setUpdateSearch(updateSearch + 1);

      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.DONE,
          message: data.is_enabled
            ? t('company-services.views.index.messages.api.correct.enable')
            : t('company-services.views.index.messages.api.correct.disable'),
          languageFile: LANGUAGES_FILES.COMPANIES,
        }),
      );

      closeModal();
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t(
            'company-services.views.index.messages.api.error.no-update',
          ),
          languageFile: LANGUAGES_FILES.COMPANIES,
        }),
      );
    } finally {
      setLoadingRequest(false);
    }
  };

  const handleFilter = (rowsFiltereds) => {
    setCompanyServices(rowsFiltereds);
  };

  const getPermissions = async () => {
    try {
      const updateCompanyServices = await resultUserPermissions(
        authorization,
        id,
        'company_service_update',
      );
      const enableDisableCompanyService = await resultUserPermissions(
        authorization,
        id,
        'company_service_enable_disable',
      );
      const createCompanyService = await resultUserPermissions(
        authorization,
        id,
        'company_service_create',
      );
  
      setCanUpdateCompanyServices(updateCompanyServices.data.can);
      setCanEnableDisableCompanyService(enableDisableCompanyService.data.can);
      setCanCreateCompanyService(createCompanyService.data.can);
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('company-services.views.update.messages.api.error.connect'),
          languageFile: LANGUAGES_FILES.COMPANIES,
        }),
      )
    }
  };

  const getDataToTable = async () => {
    try {
      const getCompanyServices = await resultCompanyServices(authorization);
      setCompanyServices(
        companyServicesAdapter(getCompanyServices.data, openModal),
      );
      setIsLoading(false);
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('company-services.views.update.messages.api.error.connect'),
          languageFile: LANGUAGES_FILES.COMPANIES,
        }),
      )
    }
  };

  useEffect(() => {
    getPermissions();
    getDataToTable();
  }, []);

  useEffect(() => {
    dispatch(setNavbarInfo({ title, breadcrumbs }));

    return () => {
      dispatch(clearNavbar());
    };
  }, [e.language]);

  return (
    <main className="w-full min-w-[280px] h-full flex flex-col gap-1">
      {openDisableEnableModal ? (
        <Modal>
          <EnableDisableAlert
            handleClick={handleEnableDisableCompanyService}
            handleClose={closeModal}
            itemInfo={companyServiceInfo}
            itemType={t(
              'company-services.views.index.layout.modals.enable-disable.title',
            )}
          />
        </Modal>
      ) : null}

      {loadingRequest ? (
        <Modal>
          <Loading />
        </Modal>
      ) : null}

      <section className="w-full h-[95%] min-h-[550px] bg-white sm:rounded-[6px] shadow-md border pb-[28px] pt-4 relative">
        {isLoading && (
          <SecondLoading
            size={100}
            color={'#475569'}
            rounded={'6px'}
            opacity={'0.4'}
          />
        )}

        {columns.length ? (
          <div className="h-full flex flex-col items-center gap-4 xs:px-1 sm:px-4 md:px-5">
            <div className="w-full grid grid-cols-1 gap-3 md:grid-cols-3">
              {canCreateCompanyService ? (
                <div className="w-full flex items-center justify-center md:order-2 md:justify-end md:pr-3">
                  <ButtonGeneral
                    value={t(
                      'company-services.views.index.layout.buttons.new-company-service',
                    )}
                    width={120}
                    onClick={() => navigate('/parameters/company_services/new')}
                  />
                </div>
              ) : null}

              <div className="w-full flex justify-center md:col-start-2">
                <SearchBar
                  list={companyServices}
                  handleFilter={handleFilter}
                  columnsToSearch={columnsToSearch}
                  placeholder={t(
                    'company-services.views.index.layout.tables.table-company-services.searchbar',
                  )}
                  disabled={isLoading}
                  update={updateSearch}
                />
              </div>
            </div>

            <DataTable
              data={{ rows: companyServices }}
              headers={columns}
              RowsComponent={RowsTableCompanyServices}
              permission={{
                canUpdateCompanyServices,
                canEnableDisableCompanyService,
              }}
              noData={t(
                'company-services.views.index.layout.tables.table-company-services.table.no-data',
              )}
            />
          </div>
        ) : null}
      </section>
    </main>
  );
}
