import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LANGUAGES_FILES, ALERT_TYPE } from '../../../constants/enums';
import { dateBasic } from '../../../utils/dateFormatter';

import { getAllIataFlights } from '../../../services/api/v1/flight_requests/flightRequests';
import { resultUserPermissions } from '../../../services/api/v1/permission_requests/permissionRequests';
import { flightsByFilters } from '../../../services/api/v1/flight_requests/flightRequests';
import { resultCompanyServices } from '../../../services/api/v1/company_services_requests/companyServicesRequests';
import { airlinesAllowedPerUser, stationsAllowedPerUser } from '../../../services/api/v1/user_requests/userRequests';

import { createNewAlert } from '../../../redux/reducer/alert-messages/alerts.actions';

import {
  flightsAdapter,
  airportsAdapter,
  airlinesAdapter,
  iataFlightsAdapter,
  companyServicesAdapter,
} from '../adapters/flights';

import RowsTableFlightsToday from './table-flights-day/RowsTableFlightsToday';
import SecondLoading from '../../../components/SecondLoading';
import ButtonGeneral from '../../../components/ButtonGeneral';
import DatePicker from '../../../components/date_picker/DatePicker';
import SelectAutocomplete from '../../../components/SelectAutocomplete';
import DataTable from '../../../components/data_table/DataTable';
import SearchBar from '../../../components/SearchBar';

import DecryptedToken from '../../../components/security_components/decrypted_token/decrypted_token';

const columnsToSearch = [
  'flightCode',
  'scheduledDepartureTime',
  'scheduledActualTime',
  'departureState',
];

export default function TableFlightsDay() {
  const [t, e] = useTranslation(LANGUAGES_FILES.DASHBOARD);
  const authorization = DecryptedToken();
  const dispatch = useDispatch();
  const defaultDate = dateBasic(new Date());

  const [updateSearch, setUpdateSearch] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [canShowFlight, setCanShowFlight] = useState(false);

  const [flights, setFlights] = useState({ columns: [], rows: [] });

  const [airlines, setAirlines] = useState(null);
  const [airports, setAirports] = useState(null);
  const [iataFlights, setIataFlights] = useState(null);

  const [startDate, setStartDate] = useState(defaultDate);
  const [endDate, setEndDate] = useState(defaultDate);
  const [stationId, setStationId] = useState('');
  const [airlineId, setAirlineId] = useState('');
  const [iataFlight, setIataFlight] = useState('');

  const [columns, setColumns] = useState([]);
  const [services, setServices] = useState([]);
  const [groupColumns, setGroupColumns] = useState([]);

  const [minStart] = useState(() => {
    let valueMin = new Date(endDate);
    valueMin.setDate(valueMin.getDate() + 1);
    valueMin.setMonth(valueMin.getMonth() - 3);
    return valueMin;
  });
  const [maxStart] = useState(new Date());

  const { id } = useSelector((state) => state.authorization.informationUser);

  const generateColumns = [
    {
      id: 1,
      title: t('dashboard.flights.table.header.flight'),
      sortable: true,
      sortBy: 'flightCode',
      width: 165,
    },
    {
      id: 2,
      title: t('dashboard.flights.table.header.scheduled-departure-time'),
      sortable: true,
      sortBy: 'scheduledDepartureTime',
      width: 190,
    },
    {
      id: 3,
      title: t('dashboard.flights.table.header.scheduled-arrival-time'),
      sortable: true,
      sortBy: 'scheduledActualTime',
      width: 160,
    },
    {
      id: 4,
      title: t('dashboard.flights.table.header.departure-state'),
      sortable: true,
      sortBy: 'departureState',
      width: 115,
    },
  ];

  const group = [
    {
      id: 1,
      groupTitle: t(
        'dashboard.flights.table.header.header-group.on-time-performance',
      ),
      colspan: 4,
    },
  ];

  const handleFilter = (rowsFiltereds) => {
    setFlights({
      ...flights,
      rows: rowsFiltereds,
    });
  };

  const setParams = (setState) => (id) => {
    setState(id);
  };

  const getPermissions = async () => {
    try {
      const showFlights = await resultUserPermissions(
        authorization,
        id,
        'flight_show',
      );

      setCanShowFlight(showFlights.data.can);
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('dashboard.flights.messages.api.error.connect'),
          languageFile: LANGUAGES_FILES.DASHBOARD,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const validateErrors = () => {
    let newError = '';

    if (!startDate && !endDate) {
      setStartDate(defaultDate);
      setEndDate(defaultDate);
    } else if (startDate && !endDate) {
      newError = t('dashboard.flights.messages.web.error.fields-date');
    } else if (!startDate && endDate) {
      newError = t('dashboard.flights.messages.web.error.fields-date');
    } else {
      const startDateFormat = new Date(startDate);
      const endDateFormat = new Date(endDate);

      if (startDateFormat > endDateFormat) {
        newError = t('dashboard.flights.messages.web.error.start-date-greater');
      }
    }

    if (newError) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: newError,
          languageFile: LANGUAGES_FILES.DASHBOARD,
        }),
      );
    }
    return newError;
  };

  const getDataToTable = async () => {
    if (validateErrors()) {
      return;
    } else {
      setIsLoading(true);
      try {
        const allCompanyServices = await resultCompanyServices(authorization);
        setServices(companyServicesAdapter(allCompanyServices.data));

        const response = await flightsByFilters(authorization, {
          user_id: id,
          start_date: startDate ? startDate : defaultDate,
          end_date: endDate ? endDate : defaultDate,
          station_id: stationId,
          airline_id: airlineId,
          iata_flight: iataFlight,
          with_services: true,
        });
        setFlights({ ...flights, rows: flightsAdapter(response.data) });
        setUpdateSearch(updateSearch + 1);
      } catch (error) {
        dispatch(
          createNewAlert({
            newAlert: true,
            type: ALERT_TYPE.ERROR,
            message: t(
              'dashboard.flights.messages.api.error.flight-filter-no-found',
            ),
            languageFile: LANGUAGES_FILES.DASHBOARD,
          }),
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getDataToFilter = async () => {
    try {
      const getAirports = await stationsAllowedPerUser(authorization, id);
      setAirports(airportsAdapter(getAirports.data));

      const getAirlines = await airlinesAllowedPerUser(authorization, id);
      setAirlines(airlinesAdapter(getAirlines.data));

      const getIataFlights = await getAllIataFlights(authorization, id);
      setIataFlights(iataFlightsAdapter(getIataFlights.data));
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('dashboard.flights.messages.api.error.no-data-to-filter'),
          languageFile: LANGUAGES_FILES.DASHBOARD,
        }),
      );
    }
  };

  const adjustWidth = (name) => {
    switch (name) {
      case 'security' || 'airline':
        return 85;
      case 'ground-handling':
        return 145;
      case 'fuel':
        return 105;
      case 'maintenance':
        return 120;
      case 'authority':
        return 90;
      default:
        return 80;
    }
  };

  useEffect(() => {
    getPermissions();
    getDataToTable();
    getDataToFilter();
  }, []);

  useEffect(() => {
    if (services.length) {
      services.forEach((service) => {
        generateColumns.push({
          id: generateColumns.length + 1,
          title: t(
            `dashboard.flights.table.header.company-services.${service.name}`,
          ),
          width: adjustWidth(service.name),
          action: true,
        });
      });

      generateColumns.push({
        id: generateColumns.length + 1,
        title: t(`dashboard.flights.table.header.company-services.total`),
        width: 80,
        action: true,
      });

      group.push({
        id: 2,
        groupTitle: t(
          'dashboard.flights.table.header.header-group.services-delivery-hours',
        ),
        colspan: services.length + 1,
      });

      setColumns(generateColumns);
      setGroupColumns(group);
    }
  }, [services, e.language]);

  return (
    <main className="w-full min-w-[280px] h-full flex flex-col gap-2">
      <section
        className="
          w-full bg-white grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3
          xl:px-5 gap-3 place-items-center shadow-md border py-3 sm:rounded-[6px]
        "
      >
        <DatePicker
          setParams={setParams(setStartDate)}
          defaultValue={startDate}
          min={minStart}
          max={maxStart}
          label={t('dashboard.flights.filters.labels.start-date')}
          disabled={isLoading}
        />

        <DatePicker
          setParams={setParams(setEndDate)}
          defaultValue={endDate}
          min={minStart}
          max={maxStart}
          label={t('dashboard.flights.filters.labels.end-date')}
          disabled={isLoading}
        />

        <SelectAutocomplete
          data={airports}
          setParams={setParams(setStationId)}
          label={t('dashboard.flights.filters.labels.airport-select')}
          disabled={isLoading}
        />

        <SelectAutocomplete
          data={airlines}
          setParams={setParams(setAirlineId)}
          label={t('dashboard.flights.filters.labels.airline-select')}
          disabled={isLoading}
        />

        <SelectAutocomplete
          data={iataFlights}
          setParams={setParams(setIataFlight)}
          label={t('dashboard.flights.filters.labels.flight-code-select')}
          disabled={isLoading}
        />

        <div className="col-span-1">
          <ButtonGeneral
            value={t('dashboard.flights.filters.buttons.filter')}
            onClick={getDataToTable}
            width={130}
            disabled={isLoading}
          />
        </div>
      </section>

      <section className="w-full min-h-[550px] bg-white sm:rounded-[6px] shadow-md border pb-[14px] pt-[12px] relative">
        {isLoading && (
          <SecondLoading
            size={100}
            color={'#475569'}
            rounded={'6px'}
            opacity={'0.4'}
          />
        )}

        {columns.length ? (
          <div className="h-full flex flex-col items-center gap-[8px] xs:px-1 sm:px-4 md:px-5">
            <div className="w-full flex justify-end">
              <div className="w-fit pr-2">
                <SearchBar
                  list={flights.rows}
                  handleFilter={handleFilter}
                  columnsToSearch={columnsToSearch}
                  placeholder={t('dashboard.flights.table.searchbar')}
                  disabled={isLoading}
                  update={updateSearch}
                />
              </div>
            </div>

            <DataTable
              data={flights}
              headers={columns}
              RowsComponent={RowsTableFlightsToday}
              permission={{ canShowFlight, services }}
              noData={t('dashboard.flights.table.no-data')}
              group={groupColumns}
            />
          </div>
        ) : null}
      </section>
    </main>
  );
}
