import { dateWithTimezone } from '../../../utils/dateFormatter';

export const airportsAdapter = (data, handleModal) => {
  const language = localStorage.getItem('language');

  const response = [];
  data.forEach((item) => {
    if (item.name.includes('xxx')) return;

    response.push({
      id: item.id,
      isEnabled: item.is_enabled,
      iataCode: item.iata_code,
      name: item.name,
      timezone: item.timezone,
      countryName:
        language === 'en'
          ? `${item.country_iso_code} - ${item.country_name.split('||')[1]}`
          : `${item.country_iso_code} - ${item.country_name.split('||')[0]}`,
      cityName:
        language === 'en'
          ? `${item.city_iata_code} - ${item.city_name.split('||')[1]}`
          : `${item.city_iata_code} - ${item.city_name.split('||')[0]}`,
      createdAt: dateWithTimezone(item.created_at),
      handleModal,
    });
  });

  return response;
};
