import {
  SET_JOB_POSITIONS,
  SET_JOB_POSITION_ID,
  SET_NEW_JOB_POSITION,
  SET_UPDATE_JOB_POSITION,
} from '../types/jobPositionsTypes';

const initialState = {
  resulJobPositions: [],
  resultJobPositionId: [],
  resulUpdateJobPosition: [],
  resultNewJobPosition: [],
};

function jobPositionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_JOB_POSITIONS:
      return { ...state, resulJobPositions: action.payload };

    case SET_JOB_POSITION_ID:
      return { ...state, resultJobPositionId: action.payload };

    case SET_UPDATE_JOB_POSITION:
      return { ...state, resulUpdateJobPosition: action.payload };

    case SET_NEW_JOB_POSITION:
      return { ...state, resultNewJobPosition: action.payload };

    default:
      return state;
  }
}

export default jobPositionsReducer;
