import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { ALERT_TYPE } from '../constants/enums';
import { useTranslation } from 'react-i18next';

export default function Alert({ type, message, languageFile, link, close }) {
  const [t] = useTranslation(languageFile);
  const navigate = useNavigate();

  const goToLink = () => {
    navigate(link.linkTo);
    close();
  };


  const customAlert = {
    background: '',
    border: '',
    icon: '',
  };

  switch (type) {
    case ALERT_TYPE.ERROR:
      customAlert.background = '#F6A7A3';
      customAlert.border = '#91110E';
      customAlert.icon = '#B3625E';
      break;

    case ALERT_TYPE.DONE:
      customAlert.background = '#A8F1C6';
      customAlert.border = '#198343';
      customAlert.icon = '#69B68A';
      break;

    case ALERT_TYPE.WARNING:
      customAlert.background = '#FFD38A';
      customAlert.border = '#8B5502';
      customAlert.icon = '#C3974C';
      break;

    default:
      customAlert.background = '#EBEBEB';
      customAlert.border = '#6B6B6B';
      customAlert.icon = '#C6C6C6';
      break;
  }

  useEffect(() => {
    const time = link.subString ? 10000 : 4000;
    const timer = setTimeout(() => {
      close();
    }, time);

    return () => clearTimeout(timer);
  }, []);

  return (
    <section
      className="w-full h-[7%] absolute top-[93%] shadow-lg flex justify-center items-center pl-4 pr-5
      md:w-11/12 md:top-[92%] md:left-[4.17%] md:h-[5%] md:rounded-[6px] lg:w-2/3 lg:left-[16.67%] z-50"
      style={{
        borderLeft: `13px solid ${customAlert.border}`,
        backgroundColor: `${customAlert.background}`,
      }}
    >
      <button
        className={`
          absolute top-2 right-2
          ${
            type === ALERT_TYPE.ERROR
              ? 'text-[#B3625E] hover:text-[#91110E]'
              : type === ALERT_TYPE.DONE
              ? 'text-[#69B68A] hover:text-[#198343]'
              : type === ALERT_TYPE.WARNING
              ? 'text-[#C3974C] hover:text-[#8B5502]'
              : 'text-[#C6C6C6] hover:text-[#6B6B6B]'
          }
        `}
        onClick={close}
      >
        <AiOutlineCloseCircle size={22} />
      </button>

      {link.linkTo ? (
        <div className="flex text-[#273447]">
          <h4>{message.split(link.subString)[0]}</h4>
          <button
            className='text-[#50B8E4] ml-[5px] underline'
            onClick={goToLink}
          >
            {link.subString}
          </button>
          <h4>{message.split(link.subString)[1]}</h4>
        </div>
      ) : (
        <h4 className="text-[#273447]">{t(message)}</h4>
      )}

    </section>
  );
}
