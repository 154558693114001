import {
  SET_AUTHORIZATION,
  SET_INFORMATION_USER,
} from '../types/authorizationsTypes';

const initialState = {
  authorization: '',
  informationUser: [],
};

function authorizationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHORIZATION:
      return { ...state, authorization: action.payload };

    case SET_INFORMATION_USER:
      return { ...state, informationUser: action.payload };

    default:
      return state;
  }
}

export default authorizationsReducer;
