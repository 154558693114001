// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import Sections from '../../pages/sections_page/components/Sections';

// Pages imports with lazy loading
const NewSection = lazy(() =>
  import('../../pages/sections_page/components/NewSection'),
);
const EditSection = lazy(() =>
  import('../../pages/sections_page/components/EditSection'),
);

function SectionsRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const sectionsRoutes = [
    {
      path: 'configuration/sections',
      element: <Sections />,
      isAllowed: customPermission.section_index,
    },
    {
      path: 'configuration/sections/new',
      element: <NewSection />,
      isAllowed: customPermission.section_create,
    },
    {
      path: 'configuration/sections/edit/:id',
      element: <EditSection />,
      isAllowed: customPermission.section_update,
    },
  ];

  return sectionsRoutes;
}

export default SectionsRoutes;
