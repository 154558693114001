import { useTranslation } from 'react-i18next';
import ButtonCancel from './ButtonCancel';
import ButtonGeneral from './ButtonGeneral';
import { LANGUAGES_FILES } from '../constants/enums';

export default function EnableDisableAlert({
  handleClick,
  handleClose,
  itemInfo,
  itemType,
}) {
  const [t] = useTranslation(LANGUAGES_FILES.GLOBAL);
  const action = itemInfo.isEnabled ? 'disable' : 'enable';

  return (
    <main className="bg-white w-[350px] flex flex-col gap-4 rounded-b-[5px]">
      <header className="bg-[#273447] text-white flex flex-col gap-2 justify-center py-3 items-center">
        <span className="text-lg font-semibold">{itemType}</span>
        <span className="text-sm">{itemInfo.name}</span>
      </header>

      <section className="flex flex-col px-8 py-6 gap-5 items-center">
        <span className="text-sm">
          {itemInfo.isEnabled
            ? t('global.layout.modals.enable-disable.questions.to-disable')
            : t('global.layout.modals.enable-disable.questions.to-enable')}
        </span>

        <div className="flex gap-5">
          <ButtonGeneral
            value={t('global.layout.modals.enable-disable.buttons.accept')}
            onClick={() => handleClick(itemInfo.id, action)}
          />
          <ButtonCancel
            value={t('global.layout.modals.enable-disable.buttons.cancel')}
            onClick={handleClose}
          />
        </div>
      </section>
    </main>
  );
}
