import {
  SET_GET_GANTT_DATA_BY_AREA,
  SET_GET_GANTT_DATA_BY_COMPANY,
  SET_GET_GANTT_DATA_BY_COMPANY_AREA,
} from '../types/ganttDataTypes';

const initialState = {
  resultGanttDataByCompany: [],
  resultGanttDataByCompanyArea: [],
  resultGanttDataByArea: [],
};

function ganttDataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_GET_GANTT_DATA_BY_COMPANY:
      return { ...state, resultGanttDataByCompany: action.payload };

    case SET_GET_GANTT_DATA_BY_COMPANY_AREA:
      return { ...state, resultGanttDataByCompanyArea: action.payload };

    case SET_GET_GANTT_DATA_BY_AREA:
      return { ...state, resultGanttDataByArea: action.payload };

    default:
      return state;
  }
}

export default ganttDataReducer;
