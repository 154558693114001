import { CLEAR_ALERT, CREATE_NEW_ALERT } from './alerts.types';

const initialState = {
  newAlert: false,
  type: 'default',
  message: '',
  languageFile: '',
  link: {
    linkTo: '',
    subString: '',
  },
};

export const alerts = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_ALERT:
      if (!state.newAlert) {
        const newState = {
          ...state,
          newAlert: action.payload.newAlert,
          type: action.payload.type,
          message: action.payload.message,
          languageFile: action.payload.languageFile,
        };

        if (action.payload.link) {
          newState.link.linkTo = action.payload.link.linkTo;
          newState.link.subString = action.payload.link.subString;
        }

        return newState;
      }
      return state;

    case CLEAR_ALERT:
      return {
        newAlert: false,
        type: 'default',
        message: '',
        languageFile: '',
        link: {
          linkTo: '',
          subString: '',
        },
      };

    default:
      return state;
  }
};
