// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import Airlines from '../../pages/airlines_page/components/Airlines';

// Pages imports with lazy loading
const NewAirline = lazy(() =>
  import('../../pages/airlines_page/components/NewAirline'),
);
const EditAirline = lazy(() =>
  import('../../pages/airlines_page/components/EditAirline'),
);

function AirlinesRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const airlinesRoutes = [
    {
      path: 'parameters/airlines',
      element: <Airlines />,
      isAllowed: customPermission.airline_index,
    },
    {
      path: 'parameters/airlines/new',
      element: <NewAirline />,
      isAllowed: customPermission.airline_create,
    },
    {
      path: 'parameters/airlines/edit/:id',
      element: <EditAirline />,
      isAllowed: customPermission.airline_update,
    },
  ];

  return airlinesRoutes;
}

export default AirlinesRoutes;
