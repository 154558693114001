// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import Settings from '../../pages/settings_page/components/Settings';

// Pages imports with lazy loading
const EditSetting = lazy(() => import('../../pages/settings_page/components/EditSetting'))

function SettingsRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const settingsRoutes = [
    {
      path: 'configuration/settings',
      element: <Settings />,
      isAllowed: customPermission.setting_index,
    },
    {
      path: 'configuration/settings/edit/:id',
      element: <EditSetting />,
      isAllowed: customPermission.setting_update,
    },
  ];

  return settingsRoutes;
}

export default SettingsRoutes;
