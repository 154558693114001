import { axiosSesion } from '../axios';

// Get all sections
export const resultSections = (Authorization) => {
  return axiosSesion.get('/api/v1/sections', { headers: { Authorization } });
};

// Get section by ID
export const resultGetSectionId = (Authorization, id) => {
  return axiosSesion.get(`/api/v1/sections/${id}`, {
    headers: { Authorization },
  });
};

// Create new section
export const resultNewSection = (
  Authorization,
  nameEn,
  nameEs,
  parentSectionId,
) => {
  return axiosSesion.post(
    '/api/v1/sections',
    {
      section: {
        name_en: nameEn,
        name_es: nameEs,
        parent_section_id: parentSectionId,
      },
    },
    {
      headers: { Authorization },
    },
  );
};

// Update section
export const resultUpdateSection = (
  Authorization,
  id,
  nameEn,
  nameEs,
  parentSectionId,
) => {
  return axiosSesion.put(
    `/api/v1/sections/${id}`,
    {
      section: {
        name_en: nameEn,
        name_es: nameEs,
        parent_section_id: parentSectionId,
      },
    },
    {
      headers: { Authorization },
    },
  );
};
