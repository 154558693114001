export default function Modal({ children }) {
  return (
    <section
      className="
        flex
        justify-center
        items-center
        overflow-x-hidden
        overflow-y-auto
        fixed
        inset-0
        z-50
        outline-none
        focus:outline-none
        bg-neutral-800
        bg-opacity-50
      "
    >
      {children}
    </section>
  );
}
