import RoutesApp from './routes/routes';
import Alert from './components/Alert';
import { useErrorBoundary } from './hooks/useErrorBoundaries';

export default function App() {
  const { newAlert, type, message, languageFile, link, closeAlert } = useErrorBoundary();

  return (
    <div className="w-screen h-screen">
      <RoutesApp />

      {newAlert && (
        <Alert
          type={type}
          message={message}
          languageFile={languageFile}
          link={link}
          close={closeAlert}
        />
      )}
    </div>
  );
}
