import { useState, useEffect } from 'react';

import { FaSortAmountDownAlt, FaSortAmountUpAlt } from 'react-icons/fa';

import { sortAscending, sortDescending } from '../../utils/sortData';
import PaginateTable from './PaginateTable';
import FootTable from './FootTable';

export default function DataTable({
  data,
  headers,
  RowsComponent,
  permission,
  noData,
  group = false,
}) {
  const [dataRows, setDataRows] = useState([]);
  const [rowsPage, setRowsPage] = useState([]);
  const [isAscending, setIsAscending] = useState(true);
  const [sortSelected, setSortSelected] = useState('');

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);

  const handlePaginate = () => {
    const copyRows = [...dataRows].slice((page - 1) * perPage, page * perPage);
    setRowsPage(copyRows);
  };

  const handleShowPage = (value) => {
    setPage(value);
  };

  const handlePerPage = (value) => {
    setPerPage(value);
  };

  const handleSortSelected = (sortBy) => {
    let validateSort = isAscending;
    if (sortSelected !== sortBy) {
      setSortSelected(sortBy);
      validateSort = true;
    }

    let sortRows = [];
    if (validateSort) {
      sortRows = sortAscending([...dataRows], sortBy);
    } else {
      sortRows = sortDescending([...dataRows], sortBy);
    }
    setDataRows(sortRows);
    setIsAscending(!validateSort);
  };

  useEffect(() => {
    if (!data.rows.length) setRowsPage([]);
    if (!dataRows || data.rows !== dataRows) setDataRows(data.rows);
  }, [data]);

  useEffect(() => {
    if (dataRows.length) handlePaginate();
  }, [dataRows, page, perPage]);

  return (
    <>
      <section
        className="w-full h-[85%] max-h-[630px] min-h-[360px] overflow-hidden rounded-[6px] shadow-md"
        style={{
          border: '1px solid #d4d4d4',
        }}
      >
        <div className="px-3 pb-4 w-full h-full max-h-[630px] min-h-[360px] overflow-auto relative">
          <table className="min-w-full border-separate border-spacing-0">
            <thead className="min-w-full">
              {group ? (
                <tr>
                  {group.map((item, index) => (
                    <th
                      key={item.id}
                      className={`bg-[#f4f6fa] text-center text-[14.5px] text-[#273447] font-semibold pt-2 pb-2 z-20 sticky top-0 ${
                        index === 0 && '-left-[16px] z-30'
                      }`}
                      colSpan={item.colspan}
                      style={{
                        borderBottom: '1px solid #d4d4d4',
                        borderRight: `${
                          group.length - 1 === index
                            ? 'none'
                            : '1px solid #d4d4d4'
                        }`,
                      }}
                    >
                      {item.groupTitle}
                    </th>
                  ))}
                </tr>
              ) : null}

              <tr
                className={`min-w-full bg-white text-sm sticky z-20 ${
                  group ? 'top-[39px]' : 'top-0'
                }`}
                style={{ borderBottom: '1px solid #d4d4d4' }}
              >
                {headers.map((item, index) => (
                  <th
                    key={item.id}
                    className={`min-w-full ${
                      group ? 'py-2.5 bg-[#f4f6fa]' : 'pb-3 pt-6'
                    } group ${item.action ? 'px-0' : 'px-2.5'} ${
                      group && index === 0 && 'sticky -left-[16px]'
                    } ${group && index === 1 && 'sticky left-[170px]'} ${
                      group && index === 2 && 'sticky left-[381px]'
                    } ${group && index === 3 && 'sticky left-[562px]'}`}
                    style={{
                      maxWidth: `${item.action ? `${item.width}px` : '100%'}`,
                      borderBottom: '1px solid #d4d4d4',
                      borderRight: `${
                        group && headers.length - 1 !== index
                          ? '1px solid #d4d4d4'
                          : 'none'
                      }`,
                    }}
                  >
                    <div
                      className={`${
                        item.action ? 'text-center' : 'flex justify-between'
                      }`}
                      style={{
                        width: `${item.width}px`,
                      }}
                    >
                      <span className="w-full font-semibold">{item.title}</span>

                      {item.sortable && (
                        <button
                          className={`hover:text-[#273447] ${
                            sortSelected === item.sortBy
                              ? 'text-[#50B8E4]'
                              : 'text-slate-400 hidden group-hover:block'
                          }`}
                          onClick={() => handleSortSelected(item.sortBy)}
                        >
                          {sortSelected !== item.sortBy ? (
                            <FaSortAmountUpAlt size={14} />
                          ) : isAscending ? (
                            <FaSortAmountDownAlt size={14} />
                          ) : (
                            <FaSortAmountUpAlt size={14} />
                          )}
                        </button>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {rowsPage.length ? (
                <RowsComponent rows={rowsPage} permission={permission} />
              ) : (
                <tr>
                  <td>
                    <span className="absolute w-[80%] top-[230px] left-[10%] text-center font-semibold">
                      {noData}
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>

      {dataRows.length ? (
        <>
          <FootTable
            handlePerPage={handlePerPage}
            entries={{
              numStart: perPage * (page - 1) + 1,
              numEnd:
                Math.ceil(dataRows.length / perPage) === page
                  ? dataRows.length
                  : page * perPage,
              total: dataRows.length,
            }}
          />

          <PaginateTable
            pages={Math.ceil(dataRows.length / perPage)}
            handleShowPage={handleShowPage}
          />
        </>
      ) : null}
    </>
  );
}
