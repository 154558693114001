import {
  SET_EDIT_MENU,
  SET_MENU_INFORMATIONS,
  SET_NEW_MENU,
  SET_UPDATE_MENU,
} from '../types/menusTypes';

const initialState = {
  resultMenuInformations: [],
  resultNewMenu: [],
  resultEditMenu: [],
  resultUpdateMenu: [],
};

function menusReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MENU_INFORMATIONS:
      return { ...state, resultMenuInformations: action.payload };

    case SET_NEW_MENU:
      return { ...state, resultNewMenu: action.payload };

    case SET_EDIT_MENU:
      return { ...state, resultEditMenu: action.payload };

    case SET_UPDATE_MENU:
      return { ...state, resultUpdateMenu: action.payload };

    default:
      return state;
  }
}

export default menusReducer;
