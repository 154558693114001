import { useEffect, useState } from 'react';
import { PiCheckFatFill } from 'react-icons/pi';

export default function CheckBoxBasic({
  checked = false,
  setParams,
  disabled = false,
}) {
  const [isChecked, setIsChecked] = useState(null);

  const handleChecked = (event) => {
    event.stopPropagation();
    const check = !isChecked;
    setParams(check);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <button
      className={`w-[17px] h-[17px] rounded-[4px] flex justify-center items-center p-[2px] ${
        isChecked
          ? `${
              disabled ? 'bg-[#727d8d] text-white' : 'bg-[#273447] text-white'
            } `
          : 'bg-gray-200'
      }`}
      style={{
        border: `1px solid ${
          isChecked ? `${disabled ? '#a3a3a3' : '273447'}` : '#a3a3a3'
        }`,
      }}
      onClick={handleChecked}
      disabled={disabled}
    >
      {isChecked ? <PiCheckFatFill /> : null}
    </button>
  );
}
