import {
  SET_AREAS,
  SET_AREA_ID,
  SET_EDIT_AREA,
  SET_NEW_AREA,
} from '../types/areasTypes';

const initialState = {
  resultAreas: [],
  resultNewArea: [],
  resultAreaId: [],
  resultEditArea: [],
};

function areasReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AREAS:
      return { ...state, resultAreas: action.payload };

    case SET_NEW_AREA:
      return { ...state, resultNewArea: action.payload };

    case SET_AREA_ID:
      return { ...state, resultAreaId: action.payload };

    case SET_EDIT_AREA:
      return { ...state, resultEditArea: action.payload };

    default:
      return state;
  }
}

export default areasReducer;
