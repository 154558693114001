// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import Users from '../../pages/users_page/components/Users';

// Pages imports with lazy loading
const NewUser = lazy(() => import('../../pages/users_page/components/NewUser'));

const UserDetail = lazy(() =>
  import('../../pages/users_page/components/UserDetail'),
);
const EditUser = lazy(() =>
  import('../../pages/users_page/components/EditUser'),
);

function UserRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const userRoutes = [
    {
      path: 'users',
      element: <Users />,
      isAllowed: customPermission.profile_index,
    },
    {
      path: 'users/show/:id',
      element: <UserDetail />,
      isAllowed: customPermission.profile_show,
    },
    {
      path: 'users/new',
      element: <NewUser />,
      isAllowed: customPermission.profile_create,
    },
    {
      path: 'users/edit/:id',
      element: <EditUser />,
      isAllowed: customPermission.profile_update,
    },
  ];

  return userRoutes;
}

export default UserRoutes;
