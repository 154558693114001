import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LANGUAGES_FILES } from '../../../../constants/enums';
import { dateWithTimezone } from '../../../../utils/dateFormatter';

export default function ServiceToolTip({
  row,
  isLast,
  start,
  end,
  timeFormat,
  totalTime,
  companies,
}) {
  const [t] = useTranslation(LANGUAGES_FILES.DASHBOARD);

  const [startAt] = useState(() => {
    return start
      ? dateWithTimezone(start)
      : t('dashboard.flights.table.tooltips.no-time.start');
  });

  const [endAt] = useState(() => {
    return end
      ? dateWithTimezone(end)
      : t('dashboard.flights.table.tooltips.no-time.end');
  });

  return (
    <section
      className={`bg-white px-2 py-2 rounded-xl absolute ${
        isLast ? '-left-[50px]' : '-left-[40px]'
      } ${
        row === 0 || row === 1 || row === 2 ? 'top-[42px]' : 'bottom-[42px]'
      } z-50`}
      style={{ border: '1px solid #d4d4d4' }}
    >
      <ul className="text-[13px] text-[#273447]">
        <li className="flex flex-col mb-1">
          <span className="font-bold">
            {t('dashboard.flights.table.tooltips.items.companies')}
          </span>

          {companies ? (
            companies.map((company) => (
              <span key={company} className="text-[11.5px] pl-1">
                {company}
              </span>
            ))
          ) : (
            <span>{'--'}</span>
          )}
        </li>

        <li className="flex gap-2">
          <span className="font-bold">
            {t('dashboard.flights.table.tooltips.items.start')}
          </span>
          <span className="whitespace-nowrap">{startAt}</span>
        </li>

        <li className="flex gap-2">
          <span className="font-bold">
            {t('dashboard.flights.table.tooltips.items.end')}
          </span>
          <span className="whitespace-nowrap">{endAt}</span>
        </li>

        <li className="flex gap-2">
          <span className="font-bold">
            {t('dashboard.flights.table.tooltips.items.time-format')}
          </span>
          <span className="whitespace-nowrap">{timeFormat}</span>
        </li>

        <li className="flex gap-2">
          <span className="font-bold">
            {t('dashboard.flights.table.tooltips.items.total-time')}
          </span>
          <span className="whitespace-nowrap">{totalTime}</span>
        </li>
      </ul>
    </section>
  );
}
