import EnableDisableAction from '../../../../components/data_table/EnableDisableAction';
import EditCompanies from './EditCompanies';
import LogoCompany from './LogoCompany';

export default function RowsTableCompanies({ rows, permission }) {
  return (
    <>
      {rows.map((row) => (
        <tr
          key={row.id}
          className={`text-sm even:bg-slate-50 hover:bg-[#cdd5e4] ${
            row.isEnabled
              ? 'text-slate-800'
              : 'line-through text-opacity-70 text-slate-950'
          }`}
          style={{
            borderTop: '1px solid #e2e8f0',
            borderBottom: '1px solid #e2e8f0',
          }}
        >
          <td className="select-none align-middle pl-2.5">{row.country}</td>

          <td
            className="select-none align-middle pl-2.5"
            style={{
              borderLeft: '1px solid #e2e8f0',
            }}
          >
            {row.companyService}
          </td>

          <td
            className="select-none align-middle pl-2.5"
            style={{
              borderLeft: '1px solid #e2e8f0',
              borderRight: '1px solid #e2e8f0',
            }}
          >
            {row.name}
          </td>

          <td
            className={`align-middle h-[60px] ${
              row.isEnabled ? '' : 'opacity-30'
            }`}
          >
            <LogoCompany logo={row.logo} />
          </td>

          <td
            className="select-none align-middle pl-2.5"
            style={{
              borderLeft: '1px solid #e2e8f0',
              borderRight: `${permission ? '1px solid #e2e8f0' : 'none'}`,
            }}
          >
            {row.createdAt}
          </td>

          {permission.canUpdateCompanies && (
            <td
              style={{
                borderRight: `${
                  permission.canEnableDisableCompany
                    ? '1px solid #e2e8f0'
                    : 'none'
                }`,
              }}
            >
              <EditCompanies companyId={row.id} />
            </td>
          )}

          {permission.canEnableDisableCompany && (
            <td>
              <EnableDisableAction
                isEnabled={row.isEnabled}
                infoModal={{
                  handleModal: row.handleModal,
                  info: {
                    id: row.id,
                    name: row.name,
                    isEnabled: row.isEnabled,
                  },
                }}
              />
            </td>
          )}
        </tr>
      ))}
    </>
  );
}
