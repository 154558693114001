import { dateWithTimezone } from '../../../utils/dateFormatter';

export const usersAdapter = (data, handleModal) => {
  const language = localStorage.getItem('language');

  const response = [];
  data.forEach((item) => {
    if (item.identification.includes('xxx')) return;

    response.push({
      id: item.id,
      isEnabled: item.is_enabled,
      identification: item.identification,
      firstName: item.first_name,
      lastName: item.last_name,
      email: item.email,
      jobPosition:
        language === 'en'
          ? item.job_position.name.split('||')[1]
          : item.job_position.name.split('||')[0],
      role: item.rol,
      createdAt: dateWithTimezone(item.created_at),
      handleModal,
    });
  });

  return response;
};
