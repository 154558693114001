// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import Positions from '../../pages/positions_page/components/Positions';

// Pages imports with lazy loading
const NewPosition = lazy(() =>
  import('../../pages/positions_page/components/NewPosition'),
);
const EditPosition = lazy(() =>
  import('../../pages/positions_page/components/EditPosition'),
);

function PositionsRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const positionsRoutes = [
    {
      path: 'parameters/positions',
      element: <Positions />,
      isAllowed: customPermission.position_index,
    },
    {
      path: 'parameters/positions/new',
      element: <NewPosition />,
      isAllowed: customPermission.position_create,
    },
    {
      path: 'parameters/positions/edit/:id',
      element: <EditPosition />,
      isAllowed: customPermission.position_update,
    },
  ];

  return positionsRoutes;
}

export default PositionsRoutes;
