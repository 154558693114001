import { dateWithTimezone } from '../../../utils/dateFormatter';

export const permissionsAdapter = (data, handleModal) => {
  const language = localStorage.getItem('language');

  const response = [];
  data.forEach((item) => {
    if (item.code.includes('xxx')) return;

    response.push({
      id: item.id,
      isEnabled: item.is_enabled,
      code: item.code,
      name: language === 'en' ? item.name_en : item.name_es,
      description: item.description,
      permissionLevel:
        language === 'en'
          ? item.permission_type.name_en
          : item.permission_type.name_es,
      sectionName:
        language === 'en' ? item.section.name_en : item.section.name_es,
      createdAt: dateWithTimezone(item.created_at),
      handleModal,
    });
  });

  return response;
};

export const permissionTypesListAdapter = (data) => {
  const language = localStorage.getItem('language');

  const response = [];
  data.forEach((item) => {
    if (!item.is_enabled) return;
    response.push({
      id: item.id,
      name: language === 'en' ? item.name_en : item.name_es,
      isEnabled: item.is_enabled,
    });
  });

  return response;
};

export const sectionsListAdapter = (data) => {
  const language = localStorage.getItem('language');

  const response = [];
  data.forEach((item) => {
    response.push({
      id: item.menu_id,
      sectionId: item.section_id,
      name:
        language === 'en'
          ? item.full_name_sections_en
          : item.full_name_sections_es,
      isEnabled: item.is_enabled,
    });
  });

  return response;
};

export const currentPermissionAdapter = (data) => {
  const language = localStorage.getItem('language');

  return {
    permissionTypeId: data.permission_type_id,
    defaultPermission:
      language === 'en'
        ? data.permission_type.name_en
        : data.permission_type.name_es,
    sectionId: data.section_id,
    defaultSection:
      language === 'en' ? data.section.name_en : data.section.name_es,
    code: data.code,
    nameEn: data.name_en,
    nameEs: data.name_es,
    description: data.description,
  };
};
