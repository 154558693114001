import { axiosSesion } from '../axios';

// Get frequency flights by filters
export const getFrequencyFlightsFilter = (Authorization, params) => {
  return axiosSesion.get(`/frequency_flights/filter?${params}`, {
    headers: { Authorization },
  });
};

// Get iata_flights by filter
export const getFrequencyFlightIataFlights = (Authorization, params) => {
  return axiosSesion.get(`/frequency_flights/iata_flights?${params}`, {
    headers: { Authorization },
  });
};

// Get airlines from frequency flights
export const getFrequencyFlightAirlines = (Authorization, params) => {
  return axiosSesion.get(`/frequency_flights/airlines?${params}`, {
    headers: { Authorization },
  });
};

// Get airports from frequency flights
export const getFrequencyFlightAirports = (Authorization, params) => {
  return axiosSesion.get(`/frequency_flights/airports?${params}`, {
    headers: { Authorization },
  });
};

// Update frequency flight itinerary
export const updateFrequencyFlight = (Authorization, id, newItinerary) => {
  return axiosSesion.patch(`/frequency_flights/${id}`, newItinerary, {
    headers: { Authorization },
  });
};
