import defaultLogo from '../../../../assets/imgs/default_company_logo.webp';

export default function LogoCompany({ logo }) {
  return (
    <img
      className={`mx-auto ${logo ? 'w-16' : 'w-10'}`}
      src={logo ? logo : defaultLogo}
      alt="Company logo default"
    />
  );
}
