import {
  SET_AIRLINES,
  SET_AIRLINES_DATA,
  SET_AIRLINES_BY_USER,
  SET_AIRLINE_FOR_AIRPORTS,
  SET_AIRLINE_ID,
  SET_EDIT_AIRLINE,
  SET_GET_AIRLINES,
  SET_NEW_AIRLINE,
  SET_OPTION_AIRLINE_ID,
} from '../types/airlinesTypes';

const initialState = {
  resultOptionAirlineId: [],
  resultAirlines: [],
  resultAirlineFromAirport: [],
  resultGetAirline: [],
  resultNewAirline: [],
  resultAirlineId: [],
  resultEditAirline: [],
  resultAirlinesData: [],
  resultAirlinesByUser: [],
};

function airlinesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_OPTION_AIRLINE_ID:
      return { ...state, resultOptionAirlineId: action.payload };

    case SET_AIRLINES:
      return { ...state, resultAirlines: action.payload };

    case SET_AIRLINE_FOR_AIRPORTS:
      return { ...state, resultAirlineFromAirport: action.payload };

    case SET_GET_AIRLINES:
      return { ...state, resultGetAirline: action.payload };

    case SET_NEW_AIRLINE:
      return { ...state, resultNewAirline: action.payload };

    case SET_AIRLINE_ID:
      return { ...state, resultAirlineId: action.payload };

    case SET_EDIT_AIRLINE:
      return { ...state, resultEditAirline: action.payload };

    case SET_AIRLINES_DATA:
      return { ...state, resultAirlinesData: action.payload };

    case SET_AIRLINES_BY_USER:
      return { ...state, resultAirlinesByUser: action.payload };

    default:
      return state;
  }
}

export default airlinesReducer;
