import { useSelector } from 'react-redux';
import { decryption } from '../../../utils/crypto-js';

function DecryptedToken() {
  const authorization = useSelector(
    (state) => state.authorization.authorization,
  );
  const { identification } = useSelector(
    (state) => state.authorization.informationUser,
  );

  if (authorization && identification) {
    const decryptedToken = decryption(authorization, identification);
    return decryptedToken;
  }

  return null;
}

export default DecryptedToken;
