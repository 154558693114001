import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import sha512 from 'js-sha512';

import { ALERT_TYPE, LANGUAGES_FILES } from '../../../../constants/enums';

import {
  createNewAlert,
  clearAlert,
} from '../../../../redux/reducer/alert-messages/alerts.actions';
import { resultUpdatePassword } from '../../../../services/api/v1/user_requests/userRequests';

import ButtonGeneral from '../../../../components/ButtonGeneral';
import ButtonCancel from '../../../../components/ButtonCancel';
import Modal from '../../../../components/Modal';
import Loading from '../../../../components/Loading';

import DecryptedToken from '../../../../components/security_components/decrypted_token/decrypted_token';

export default function ChangePassword({ handleClose }) {
  const authorization = DecryptedToken();
  const dispatch = useDispatch();
  const [t] = useTranslation(LANGUAGES_FILES.NAVBAR);

  const { id } = useSelector((state) => state.authorization.informationUser);
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const sendNewPassword = async (password, confirmPassword) => {
    setIsLoading(true);

    try {
      const response = await resultUpdatePassword(
        authorization,
        id,
        password,
        confirmPassword,
      );

      if (response) {
        dispatch(
          createNewAlert({
            newAlert: true,
            type: ALERT_TYPE.DONE,
            message: t(
              'navbar.views.profile.change-password.messages.api.correct.update',
            ),
            languageFile: LANGUAGES_FILES.NAVBAR,
          }),
        );

        setState({
          newPassword: '',
          confirmPassword: '',
        });
      }
    } catch (e) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t(
            'navbar.views.profile.change-password.messages.api.error.update',
          ),
          languageFile: LANGUAGES_FILES.NAVBAR,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const validateErrors = (name, value) => {
    let error = '';

    if (value.length < 8) {
      error = t(
        'navbar.views.profile.change-password.messages.web.error.password-length',
      );
    } else if (name === 'confirmPassword') {
      if (value !== state.newPassword) {
        error = t(
          'navbar.views.profile.change-password.messages.web.error.no-match',
        );
      }
    }

    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    validateErrors(name, value);

    setState({
      ...state,
      [name]: value,
    });

    dispatch(clearAlert());
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let message = '';

    if (errors.newPassword || errors.confirmPassword) {
      message = errors.newPassword
        ? errors.newPassword
        : errors.confirmPassword;
    } else if (!state.newPassword || !state.confirmPassword) {
      message = t(
        'navbar.views.profile.change-password.messages.web.error.fields-empties',
      );
    }

    if (message) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.WARNING,
          message,
          languageFile: LANGUAGES_FILES.NAVBAR,
        }),
      );

      return;
    }

    const newPassword = sha512(state.newPassword);
    const confirmPassword = sha512(state.confirmPassword);

    sendNewPassword(newPassword, confirmPassword);
  };

  return (
    <>
      {isLoading ? (
        <Modal>
          <Loading />
        </Modal>
      ) : null}

      <section className="bg-white h-[50%] rounded-b-[4px] overflow-hidden">
        <div className="h-full overflow-auto">
          <h2 className="text-[#273447] text-center text-[18px] font-semibold pt-4 pb-2">
            {t('navbar.views.profile.change-password.layout.title')}
          </h2>

          <form
            className="flex flex-col h-3/4 gap-4 pt-1"
            onSubmit={handleSubmit}
          >
            <section className="py-2 text-[15px] w-full flex flex-col gap-4 items-center">
              <div className="w-full flex flex-col items-center gap-1">
                <input
                  className="w-[76%] pl-1 focus:outline-none"
                  style={{ borderBottom: '1px solid #D9D9D9' }}
                  type="password"
                  value={state.newPassword}
                  name="newPassword"
                  placeholder={t(
                    'navbar.views.profile.change-password.layout.inputs.new-password',
                  )}
                  autoComplete="new-password"
                  onChange={handleChange}
                />

                {errors.newPassword && (
                  <span className="w-[76%] text-[13px] text-red-500 pl-1">
                    {errors.newPassword}
                  </span>
                )}
              </div>

              <div className="w-full flex flex-col items-center gap-1">
                <input
                  className="w-[76%] pl-1 focus:outline-none"
                  style={{ borderBottom: '1px solid #D9D9D9' }}
                  type="password"
                  value={state.confirmPassword}
                  name="confirmPassword"
                  placeholder={t(
                    'navbar.views.profile.change-password.layout.inputs.confirm-password',
                  )}
                  autoComplete="new-password"
                  onChange={handleChange}
                />

                {errors.confirmPassword && (
                  <span className="w-[76%] text-[13px] text-red-500 pl-1">
                    {errors.confirmPassword}
                  </span>
                )}
              </div>
            </section>

            <section className="flex justify-evenly">
              <ButtonGeneral
                value={t(
                  'navbar.views.profile.change-password.layout.buttons.accept',
                )}
              />
              <ButtonCancel
                value={t(
                  'navbar.views.profile.change-password.layout.buttons.cancel',
                )}
                onClick={handleClose}
              />
            </section>
          </form>
        </div>
      </section>
    </>
  );
}
