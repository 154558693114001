import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ALERT_TYPE, LANGUAGES_FILES } from '../../../constants/enums';

import { resultUserPermissions } from '../../../services/api/v1/permission_requests/permissionRequests';
import { stationsAllowedPerUser } from '../../../services/api/v1/user_requests/userRequests';
import { resultDisableRole } from '../../../services/api/v1/rol_requests/rolRequests';

import { createNewAlert } from '../../../redux/reducer/alert-messages/alerts.actions';
import {
  setNavbarInfo,
  clearNavbar,
} from '../../../redux/reducer/navbar/navbar.reducers';
import { airportsAdapter } from '../adapters/airports';

import RowTableAirports from './table-airports/RowTableAirports';
import SecondLoading from '../../../components/SecondLoading';
import ButtonGeneral from '../../../components/ButtonGeneral';
import SearchBar from '../../../components/SearchBar';
import DataTable from '../../../components/data_table/DataTable';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';

import DecryptedToken from '../../../components/security_components/decrypted_token/decrypted_token';
import EnableDisableAlert from '../../../components/EnableDisableAlert';

const columnsToSearch = [
  'iataCode',
  'name',
  'timezone',
  'cityName',
  'countryName',
  'createdAt',
];

export default function Airports() {
  const [t, e] = useTranslation(LANGUAGES_FILES.AIRPORTS);
  const authorization = DecryptedToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateSearch, setUpdateSearch] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [openDisableEnableModal, setOpenDisableEnableModal] = useState(false);

  const [airports, setAirports] = useState([]);
  const [airportInfo, setAirportInfo] = useState({
    id: null,
    name: null,
    isEnabled: null,
  });

  const [canUpdateAirports, setCanUpdateAirports] = useState(false);
  const [canEnableDisableAirport, setCanEnableDisableAirport] = useState(false);
  const [canCreateAirport, setCanCreateAirport] = useState(false);

  const { id } = useSelector((state) => state.authorization.informationUser);

  const columns = [
    {
      id: 1,
      title: t(
        'airports.views.index.layout.tables.table-airports.table.header.iata-code',
      ),
      sortable: true,
      sortBy: 'iataCode',
      width: 90,
    },
    {
      id: 2,
      title: t(
        'airports.views.index.layout.tables.table-airports.table.header.name',
      ),
      sortable: true,
      sortBy: 'name',
      width: 220,
    },
    {
      id: 3,
      title: t(
        'airports.views.index.layout.tables.table-airports.table.header.timezone',
      ),
      sortable: true,
      sortBy: 'timezone',
      width: 240,
    },
    {
      id: 4,
      title: t(
        'airports.views.index.layout.tables.table-airports.table.header.country-name',
      ),
      sortable: true,
      sortBy: 'countryName',
      width: 190,
    },
    {
      id: 5,
      title: t(
        'airports.views.index.layout.tables.table-airports.table.header.city-name',
      ),
      sortable: true,
      sortBy: 'cityName',
      width: 190,
    },
    {
      id: 6,
      title: t(
        'airports.views.index.layout.tables.table-airports.table.header.created-at',
      ),
      sortable: true,
      sortBy: 'createdAt',
      width: 195,
    },
  ];

  if (canUpdateAirports)
    columns.push({
      id: 7,
      title: t(
        'airports.views.index.layout.tables.table-airports.table.header.edit',
      ),
      width: 90,
      action: true,
    });

  if (canEnableDisableAirport)
    columns.push({
      id: 8,
      title: t(
        'airports.views.index.layout.tables.table-airports.table.header.enabled',
      ),
      width: 180,
      action: true,
    });

  const title = t('airports.views.index.layout.title');

  const breadcrumbs = [
    {
      id: 1,
      text: t('airports.views.index.layout.breadcrumbs.home'),
      path: '/dashboard',
    },
    {
      id: 2,
      text: t('airports.views.index.layout.breadcrumbs.airports'),
    },
  ];

  const openModal = (value) => {
    setOpenDisableEnableModal(true);
    setAirportInfo(value);
  };

  const closeModal = () => {
    setOpenDisableEnableModal(false);
  };

  const handleEnableDisableAirport = async (id, action) => {
    setLoadingRequest(true);
    try {
      const { data } = await resultDisableRole(
        authorization,
        'stations',
        id,
        action,
      );
      await getDataToTable();
      setUpdateSearch(updateSearch + 1);

      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.DONE,
          message: data.is_enabled
            ? t('airports.views.index.messages.api.correct.enable')
            : t('airports.views.index.messages.api.correct.disable'),
          languageFile: LANGUAGES_FILES.AIRPORTS,
        }),
      );

      closeModal();
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('airports.views.index.messages.api.error.no-update'),
          languageFile: LANGUAGES_FILES.AIRPORTS,
        }),
      );
    } finally {
      setLoadingRequest(false);
    }
  };

  const handleFilter = (rowsFiltereds) => {
    setAirports(rowsFiltereds);
  };

  const getPermissions = async () => {
    try {
      const updateAirports = await resultUserPermissions(
        authorization,
        id,
        'station_update',
      );
      const enableDisableAirport = await resultUserPermissions(
        authorization,
        id,
        'station_enable_disable',
      );
      const createAirport = await resultUserPermissions(
        authorization,
        id,
        'station_create',
      );
  
      setCanUpdateAirports(updateAirports.data.can);
      setCanEnableDisableAirport(enableDisableAirport.data.can);
      setCanCreateAirport(createAirport.data.can);
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('airports.views.update.messages.api.error.connect'),
          languageFile: LANGUAGES_FILES.AIRPORTS,
        }),
      )
    }
  };

  const getDataToTable = async () => {
    try {
      const getAirports = await stationsAllowedPerUser(authorization, id);
      setAirports(airportsAdapter(getAirports.data, openModal));
      setIsLoading(false);
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('airports.views.update.messages.api.error.connect'),
          languageFile: LANGUAGES_FILES.AIRPORTS,
        }),
      )
    }
  };

  useEffect(() => {
    getPermissions();
    getDataToTable();
  }, []);

  useEffect(() => {
    dispatch(setNavbarInfo({ title, breadcrumbs }));

    return () => {
      dispatch(clearNavbar());
    };
  }, [e.language]);

  return (
    <main className="w-full min-w-[280px] h-full flex flex-col gap-1">
      {openDisableEnableModal ? (
        <Modal>
          <EnableDisableAlert
            handleClick={handleEnableDisableAirport}
            handleClose={closeModal}
            itemInfo={airportInfo}
            itemType={t(
              'airports.views.index.layout.modals.enable-disable.title',
            )}
          />
        </Modal>
      ) : null}

      {loadingRequest ? (
        <Modal>
          <Loading />
        </Modal>
      ) : null}

      <section className="w-full h-[95%] min-h-[550px] bg-white sm:rounded-[6px] shadow-md border pb-[28px] pt-4 relative">
        {isLoading && (
          <SecondLoading
            size={100}
            color={'#475569'}
            rounded={'6px'}
            opacity={'0.4'}
          />
        )}

        {columns.length ? (
          <div className="h-full flex flex-col items-center gap-4 xs:px-1 sm:px-4 md:px-5">
            <div className="w-full grid grid-cols-1 gap-3 md:grid-cols-3">
              {canCreateAirport ? (
                <div className="w-full flex items-center justify-center md:order-2 md:justify-end md:pr-3">
                  <ButtonGeneral
                    value={t('airports.views.index.layout.buttons.new-airport')}
                    width={120}
                    onClick={() => navigate('/parameters/airports/new')}
                  />
                </div>
              ) : null}

              <div className="w-full flex justify-center md:col-start-2">
                <SearchBar
                  list={airports}
                  handleFilter={handleFilter}
                  columnsToSearch={columnsToSearch}
                  placeholder={t(
                    'airports.views.index.layout.tables.table-airports.searchbar',
                  )}
                  disabled={isLoading}
                  update={updateSearch}
                />
              </div>
            </div>

            <DataTable
              data={{ rows: airports }}
              headers={columns}
              RowsComponent={RowTableAirports}
              permission={{
                canUpdateAirports,
                canEnableDisableAirport,
              }}
              noData={t(
                'airports.views.index.layout.tables.table-airports.table.no-data',
              )}
            />
          </div>
        ) : null}
      </section>
    </main>
  );
}
