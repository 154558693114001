export const sortAscending = (toSort, sortBy) => {
  toSort.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return -1;
    }
    if (a[sortBy] > b[sortBy]) {
      return 1;
    }
    return 0;
  });

  return toSort;
};

export const sortDescending = (toSort, sortBy) => {
  toSort.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return 1;
    }
    if (a[sortBy] > b[sortBy]) {
      return -1;
    }
    return 0;
  });

  return toSort;
};
