import { MENU_SELECT } from '../../../constants/enums';
import {
  SELECT_LOWMENU,
  SELECT_MENU,
  SELECT_SUBMENU,
} from './menuSelected.types';

const initialState = {
  menuSelected: MENU_SELECT.HOME,
  subMenuSelected: '',
  menuLowSelected: '',
};

export const selectMenus = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_MENU:
      return {
        menuSelected: action.payload,
      };

    case SELECT_SUBMENU:
      return {
        ...state,
        subMenuSelected: action.payload,
      };

    case SELECT_LOWMENU:
      return {
        ...state,
        menuLowSelected: action.payload,
      };

    default:
      return state;
  }
};
