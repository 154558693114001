// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import Roles from '../../pages/roles_page/components/Roles';

// Pages imports with lazy loading
const RoleDetail = lazy(() =>
  import('../../pages/roles_page/components/RoleDetail'),
);
const NewRole = lazy(() => import('../../pages/roles_page/components/NewRole'));
const EditRole = lazy(() =>
  import('../../pages/roles_page/components/EditRole'),
);

function RolesRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const rolesRoutes = [
    {
      path: 'parameters/roles',
      element: <Roles />,
      isAllowed: customPermission.role_index,
    },
    {
      path: 'parameters/roles/show/:id',
      element: <RoleDetail />,
      isAllowed: customPermission.role_show,
    },
    {
      path: 'parameters/roles/new',
      element: <NewRole />,
      isAllowed: customPermission.role_create,
    },
    {
      path: 'parameters/roles/edit/:id',
      element: <EditRole />,
      isAllowed: customPermission.role_update,
    },
  ];

  return rolesRoutes;
}

export default RolesRoutes;
