import { ALERT_TYPE, LANGUAGES_FILES } from '../constants/enums';

const newAlert = true;

export const loginMessages = {
  api: {
    unauthorized: {
      newAlert,
      type: ALERT_TYPE.ERROR,
      message: 'login.messages.api.error.user-unauthorized',
      languageFile: LANGUAGES_FILES.LOGIN,
    },
  },
  web: {
    emailEmpty: 'login.messages.web.error.email-empty',
    emailInvalid: 'login.messages.web.error.email-invalid',
    passwordEmpty: 'login.messages.web.error.password-empty',
    passwordLength: 'login.messages.web.error.password-length',
    fieldsEmpties: 'login.messages.web.error.fields-empties',
  },
};

export const forgotPasswordMessages = {
  api: {
    notFound: {
      newAlert,
      type: ALERT_TYPE.ERROR,
      message: 'forgot-password.messages.api.error.email-not-found',
      languageFile: LANGUAGES_FILES.FORGOT_PASSWORD,
    },
  },
  web: {
    emailEmpty: 'forgot-password.messages.web.error.email-empty',
    emailInvalid: 'forgot-password.messages.web.error.email-invalid',
  },
};

export const connectionMessages = {
  api: {
    connectionError: {
      newAlert,
      type: ALERT_TYPE.ERROR,
      message: 'global.messages.api.error.connection',
      languageFile: LANGUAGES_FILES.GLOBAL,
    },
  },
};
