import {
  SET_GET_DATA_OPERATION_FLIGHT,
  SET_GET_OPERATION_TIME,
  SET_GET_OPERATOR,
} from '../types/operationsTypes';

const initialState = {
  resultGetDataOperationFlight: [],
  resultGetOperator: [],
  resultGetOperationTime: [],
};

function operationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_GET_DATA_OPERATION_FLIGHT:
      return { ...state, resultGetDataOperationFlight: action.payload };

    case SET_GET_OPERATOR:
      return { ...state, resultGetOperator: action.payload };

    case SET_GET_OPERATION_TIME:
      return { ...state, resultGetOperationTime: action.payload };

    default:
      return state;
  }
}

export default operationsReducer;
