import {
  SET_APPLY_PERMISSION,
  SET_APPLY_PERMISSION_USER,
  SET_CUSTOM_PERMISSIONS,
  SET_FLIGHT_PERMISSION,
  SET_GET_PERMISSION_ID,
  SET_GET_PERMISSION_TYPE_ID,
  SET_NEW_PERMISSION,
  SET_NEW_PERMISSION_TYPE,
  SET_PERMISSIONS,
  SET_PERMISSION_APPLY,
  SET_PERMISSION_TYPES,
  SET_UPDATE_PERMISSION,
  SET_UPDATE_PERMISSION_TYPE,
} from '../types/permissionsTypes';

export const setCustomPermissions = (payload) => ({
  type: SET_CUSTOM_PERMISSIONS,
  payload,
});

export const setApplyPermission = (payload) => ({
  type: SET_APPLY_PERMISSION,
  payload,
});

export const setPermissionTypes = (payload) => ({
  type: SET_PERMISSION_TYPES,
  payload,
});

export const setNewPermissionType = (payload) => ({
  type: SET_NEW_PERMISSION_TYPE,
  payload,
});

export const setGetPermissionTypeId = (payload) => ({
  type: SET_GET_PERMISSION_TYPE_ID,
  payload,
});

export const setUpdatePermissionType = (payload) => ({
  type: SET_UPDATE_PERMISSION_TYPE,
  payload,
});

export const setPermissions = (payload) => ({ type: SET_PERMISSIONS, payload });

export const setNewPermission = (payload) => ({
  type: SET_NEW_PERMISSION,
  payload,
});

export const setPermissionId = (payload) => ({
  type: SET_GET_PERMISSION_ID,
  payload,
});

export const setUpdatePermission = (payload) => ({
  type: SET_UPDATE_PERMISSION,
  payload,
});

export const setApplyPermissionUser = (payload) => ({
  type: SET_APPLY_PERMISSION_USER,
  payload,
});

export const setFlightPermission = (payload) => ({
  type: SET_FLIGHT_PERMISSION,
  payload,
});

export const setPermissionApply = (payload) => ({
  type: SET_PERMISSION_APPLY,
  payload,
});
