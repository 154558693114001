import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { BsChevronRight, BsChevronDown } from 'react-icons/bs';

import {
  selectSubMenu,
  selectLowMenu,
} from '../../../../redux/reducer/menu-selected/menuSelected.actions';

import SimpleButton from './SimpleButton';

export default function SubListButton({
  icon: Icon,
  text,
  name,
  menuType,
  list,
  canShow = false,
}) {
  const dispatch = useDispatch();

  const { isContractedMenu } = useSelector((state) => state.contractedMenu);
  const [isOpen, setIsOpen] = useState(false);

  const { subMenuSelected, menuLowSelected } = useSelector(
    (state) => state.selectMenus,
  );

  const handleClick = () => {
    dispatch(selectSubMenu(name));
    dispatch(selectLowMenu(''));
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (subMenuSelected !== name) setIsOpen(false);
  }, [subMenuSelected]);

  return (
    <>
      {canShow ? (
        <button
          className={`w-full text-[13px] text-[#FFFFFF] py-[9px] flex ${
            isContractedMenu
              ? 'xl:justify-center'
              : 'pl-[10px] pr-[18px] items-center justify-between'
          } ${subMenuSelected === name ? 'bg-[#303750]' : 'bg-transparent'}`}
          style={{
            paddingLeft: `${
              isContractedMenu
                ? '0'
                : menuType === 'low'
                ? '30px'
                : menuType === 'medium'
                ? '20px'
                : '10px'
            }`,
            borderLeft: `${
              subMenuSelected === name && !menuLowSelected
                ? '5px solid #50B8E4'
                : '5px solid transparent'
            }`,
            paddingRight: '5px',
          }}
          onClick={handleClick}
        >
          <div className="flex gap-2">
            <Icon fill={subMenuSelected === name ? '#FFFFFF' : '#50B8E4'} />

            {!isContractedMenu ? <span>{text}</span> : null}
          </div>

          {!isContractedMenu ? (
            <span
              className={`${
                subMenuSelected === name ? 'text-[#FFFFFF]' : 'text-[#50B8E4]'
              }`}
            >
              {subMenuSelected === name && isOpen ? (
                <BsChevronDown size={17} />
              ) : (
                <BsChevronRight size={17} />
              )}
            </span>
          ) : null}
        </button>
      ) : null}

      <main
        className={`bg-[#2f3d53] py-1 flex flex-col ${
          subMenuSelected === name && isOpen ? 'block' : 'hidden'
        }`}
      >
        {list.map((item) =>
          item.canShow ? (
            <SimpleButton
              key={item.id}
              icon={item.icon}
              text={item.text}
              goTo={item.goTo}
              name={item.name}
              menuType={item.menuType}
              stroke={item.stroke ? item.stroke : false}
              canShow={item.canShow}
            />
          ) : null,
        )}
      </main>
    </>
  );
}
