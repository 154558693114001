import * as Sentry from '@sentry/react'

function startSentry()  {
  const { id, name, email } = getCurrentUser()

  const dsn = import.meta.env.VITE_SENTRY_DSN
  const tracePropagationTargets = [import.meta.env.VITE_SENTRY_PROPAGATION]

  Sentry.init({
    dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    initialScope: {
      user: {
        id,
        email,
        name,
      },
    },
    tracesSampleRate: 1.0,
    tracePropagationTargets,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

function getCurrentUser() {
  const currentUser = {
    id: 0,
    name: '',
    email: '',
    rol: '',
  }
  const currentUserInfo = localStorage.getItem('persist:root')
  if (currentUserInfo) {
    const userInfo = JSON.parse(currentUserInfo).authorization
    if (userInfo) {
      const { informationUser } = JSON.parse(userInfo)
      currentUser.id = informationUser.id
      currentUser.name = `${informationUser.first_name} ${informationUser.last_name}`
      currentUser.email = informationUser.email
      currentUser.rol = informationUser.rol
    }
  }

  console.log(currentUser)
  return currentUser
}

startSentry()
