import axios from 'axios';
import { errorList } from '../../../utils/errorList';

const apiUrl = import.meta.env.VITE_API_URL;

export const axiosSesion = axios.create({
  baseURL: apiUrl,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosSesion.interceptors.request.use((request) => {
  return request;
});

axiosSesion.interceptors.response.use(
  (response) => {
    if (
      response.data.flight_state?.message &&
      response.data.frequency_flight_state?.message
    ) {
      return Promise.resolve({
        message: errorList.flightAndFrequency,
        res: response,
      });
    } else if (response.data.flight_state?.message) {
      if (response.data.frequency_flight_state?.id) {
        return Promise.resolve({
          message: errorList.onlyFrequency,
          res: response,
        });
      }
      return Promise.resolve({
        message: errorList.flight,
        res: response,
      });
    } else if (response.data.frequency_flight_state?.message) {
      return Promise.resolve({
        message: errorList.frequency_flight,
        flight: true,
        res: response,
      });
    } else if (response.data.flight_state) {
      return response;
    }
    return response;
  },
  (error) => {
    
    if (error.code === 'ECONNABORTED') {
      return Promise.reject(errorList.timeout);
    }
    if (error.code === 'ERR_NETWORK') {
      return Promise.reject(errorList.errNetwork);
    }
    if (error.code === 'ERR_BAD_REQUEST') {
      errorList.errBadRequest.message = error.response.data.error;
      return Promise.reject(error);
    }
    if (!error.response.status) {
      return Promise.reject(errorList.unknown);
    }
    return Promise.reject(errorList[error.response.status]);
  },
);
