import {
  SET_FILTER_FLIGHT,
  SET_FLIGHTS_FROM_AIRP_AIRL,
  SET_FLIGHTS_TODAY,
  SET_GET_EDIT_FLIGHT,
  SET_NEW_FLIGHT,
  SET_OPTION_FLIGHT,
  SET_OPTION_FLIGHT_FILTER,
  SET_OPTION_FLIGHT_ID,
} from '../types/flightsTypes';

const initialState = {
  resultOptionFlight: [],
  resultOptionFlightId: [],
  resultOptionFlightFilter: [],
  resultFlightsFromAirpAirl: [],
  resultFilterFlight: [],
  resultFlightsToday: [],
  resultNewFlight: [],
  resultEditFlight: [],
};

function flightsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_OPTION_FLIGHT:
      return { ...state, resultOptionFlight: action.payload };

    case SET_OPTION_FLIGHT_ID:
      return { ...state, resultOptionFlightId: action.payload };

    case SET_OPTION_FLIGHT_FILTER:
      return { ...state, resultOptionFlightFilter: action.payload };

    case SET_FLIGHTS_FROM_AIRP_AIRL:
      return { ...state, resultFlightsFromAirpAirl: action.payload };

    case SET_FILTER_FLIGHT:
      return { ...state, resultFilterFlight: action.payload };

    case SET_FLIGHTS_TODAY:
      return { ...state, resultFlightsToday: action.payload };

    case SET_NEW_FLIGHT:
      return { ...state, resultNewFlight: action.payload };

    case SET_GET_EDIT_FLIGHT:
      return { ...state, resultEditFlight: action.payload };

    default:
      return state;
  }
}

export default flightsReducer;
