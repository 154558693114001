import { axiosSesion } from '../axios';

// Get all settings
export const getSettings = (Authorization) => {
  return axiosSesion.get('/api/v1/settings', { headers: { Authorization } });
};

// Get setting by ID
export const getSettingById = (Authorization, id) => {
  return axiosSesion.get(`/api/v1/settings/${id}`, {
    headers: { Authorization },
  });
};

// Update setting
export const updateSetting = (Authorization, id, settingUpdated) => {
  return axiosSesion.put(`/api/v1/settings/${id}`, settingUpdated, { headers: { Authorization } },
  );
};

// Enable setting
export const enableSetting = (Authorization, id) => {
  return axiosSesion.patch(`/api/v1/settings/${id}/enable`, null, { headers: { Authorization } },
  );
};

// Disable setting
export const disableSetting = (Authorization, id) => {
  return axiosSesion.patch(`/api/v1/settings/${id}/disable`, null, { headers: { Authorization } },
  );
};

// Get privacy policy URL
export const getPrivacyPolicyUrl = (Authorization) => {
  return axiosSesion.get('/api/v1/settings/privacy_policy_url', { headers: { Authorization } })
}
