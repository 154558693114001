import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';

import {
  LANGUAGES_FILES,
  MENU_SELECT,
  ALERT_TYPE,
} from '../../../constants/enums';
import { dateBasic } from '../../../utils/dateFormatter';

import { getAllIataFlights } from '../../../services/api/v1/flight_requests/flightRequests';
import { resultUserPermissions } from '../../../services/api/v1/permission_requests/permissionRequests';
import { flightsByFilters } from '../../../services/api/v1/flight_requests/flightRequests';
import { airlinesAllowedPerUser, stationsAllowedPerUser } from '../../../services/api/v1/user_requests/userRequests';
import { resultReport } from '../../../services/api/v1/records_and_reports_requests/recordsAndReportsRequests';

import { selectMenu } from '../../../redux/reducer/menu-selected/menuSelected.actions';
import { createNewAlert } from '../../../redux/reducer/alert-messages/alerts.actions';
import {
  setNavbarInfo,
  clearNavbar,
} from '../../../redux/reducer/navbar/navbar.reducers';

import {
  airlinesAdapter,
  airportsAdapter,
  flightsAdapter,
  iataFlightsAdapter,
} from '../adapters/flights';

import RowsTableFlights from './table-flights/RowsTableFlights';
import SecondLoading from '../../../components/SecondLoading';
import ButtonGeneral from '../../../components/ButtonGeneral';
import DatePicker from '../../../components/date_picker/DatePicker';
import SelectAutocomplete from '../../../components/SelectAutocomplete';
import DataTable from '../../../components/data_table/DataTable';
import SearchBar from '../../../components/SearchBar';

import DecryptedToken from '../../../components/security_components/decrypted_token/decrypted_token';

const columnsToSearch = [
  'flightCode',
  'flightDistribution',
  'scheduledDepartureTime',
  'scheduledArrivalTime',
  'flightStatus',
];

export default function Flights() {
  const [t, e] = useTranslation(LANGUAGES_FILES.FLIGHTS);
  const authorization = DecryptedToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultDate = dateBasic(new Date());

  const [updateSearch, setUpdateSearch] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [canShowFlight, setCanShowFlight] = useState(false);
  const [canCreateFlight, setCanCreateFlight] = useState(false);
  const [canDownloadReportCsv, setCanDownloadReportCsv] = useState(false);

  const [airlines, setAirlines] = useState(null);
  const [airports, setAirports] = useState(null);
  const [iataFlights, setIataFlights] = useState(null);
  const [flights, setFlights] = useState({ columns: [], rows: [] });

  const [iataFlight, setIataFlight] = useState('');
  const [date, setDate] = useState(defaultDate);
  const [airlineId, setAirlineId] = useState('');
  const [stationId, setStationId] = useState('');
  const [records, setRecords] = useState('with_records');

  /** States for the CSV report filter */
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [stationCsvId, setStationCsvId] = useState('');
  const [airlineCsvId, setAirlineCsvId] = useState('');
  const [iataFlightCsv, setIataFlightCsv] = useState('');

  const [isReportDownloaded, setIsReportDownloaded] = useState(false);
  const { id } = useSelector((state) => state.authorization.informationUser);

  const columns = [
    {
      id: 1,
      title: t(
        'flights.views.index.layout.tables.table-flights.tabla.header.code',
      ),
      sortable: true,
      sortBy: 'flightCode',
      width: 130,
    },
    {
      id: 2,
      title: t(
        'flights.views.index.layout.tables.table-flights.tabla.header.distribution',
      ),
      sortable: true,
      sortBy: 'flightDistribution',
      width: 160,
    },
    {
      id: 3,
      title: t(
        'flights.views.index.layout.tables.table-flights.tabla.header.scheduled-departure-time',
      ),
      sortable: true,
      sortBy: 'scheduledDepartureTime',
      width: 210,
    },
    {
      id: 4,
      title: t(
        'flights.views.index.layout.tables.table-flights.tabla.header.scheduled-arrival-time',
      ),
      sortable: true,
      sortBy: 'scheduledArrivalTime',
      width: 210,
    },
    {
      id: 5,
      title: t(
        'flights.views.index.layout.tables.table-flights.tabla.header.status',
      ),
      sortable: true,
      sortBy: 'flightStatus',
      width: 130,
    },
  ];

  if (canShowFlight)
    columns.push({
      id: 6,
      title: t(
        'flights.views.index.layout.tables.table-flights.tabla.header.show-detail',
      ),
      width: 100,
      action: true,
    });

  const recordsInOut = [
    {
      id: 'all',
      name: t(
        'flights.views.index.layout.forms.filter-flights.inputs.with-records-in-out-select.all-option',
      ),
    },
    {
      id: 'with_records',
      name: t(
        'flights.views.index.layout.forms.filter-flights.inputs.with-records-in-out-select.with-records-in-out-option',
      ),
    },
    {
      id: 'without_records',
      name: t(
        'flights.views.index.layout.forms.filter-flights.inputs.with-records-in-out-select.without-records-in-out-option',
      ),
    },
  ];

  const title = t('flights.views.index.layout.title');

  const breadcrumbs = [
    {
      id: 1,
      text: t('flights.views.index.layout.breadcrumbs.home'),
      path: '/dashboard',
    },
    {
      id: 2,
      text: t('flights.views.index.layout.breadcrumbs.flights'),
    },
  ];

  const handleFilter = (rowsFiltereds) => {
    setFlights({
      ...flights,
      rows: rowsFiltereds,
    });
  };

  const getFlightsFiltereds = async () => {
    setIsLoading(true);

    try {
      const response = await flightsByFilters(authorization, {
        user_id: id,
        start_date: date,
        end_date: date,
        station_id: stationId,
        airline_id: airlineId,
        iata_flight: iataFlight,
        operated: records === 'without_records' ? 'no_operated' : records,
      });

      if (response.data.length) {
        setFlights({ ...flights, rows: flightsAdapter(response.data) });
        setUpdateSearch(updateSearch + 1);
      } else {
        setFlights({ ...flights, rows: [] });
        dispatch(
          createNewAlert({
            newAlert: true,
            type: ALERT_TYPE.ERROR,
            message: t(
              'flights.views.index.messages.api.error.flight-filter-no-found',
            ),
            languageFile: LANGUAGES_FILES.FLIGHTS,
          }),
        );
      }
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('flights.views.index.messages.api.error.connect'),
          languageFile: LANGUAGES_FILES.FLIGHTS,
        }),
      );
    }

    setIsLoading(false);
  };

  const setParams = (setState) => (id) => {
    setState(id);
  };

  const getPermissions = async () => {
    try {
      const showFlights = await resultUserPermissions(
        authorization,
        id,
        'flight_show',
      );
      const createFlights = await resultUserPermissions(
        authorization,
        id,
        'flight_create',
      );
      const downloadReportCsv = await resultUserPermissions(
        authorization,
        id,
        'download_csv_general_report',
      );

      setCanShowFlight(showFlights.data.can);
      setCanCreateFlight(createFlights.data.can);
      setCanDownloadReportCsv(downloadReportCsv.data.can);
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('flights.views.index.messages.api.error.connect'),
          languageFile: LANGUAGES_FILES.FLIGHTS,
        }),
      );
    }
  };

  const getDataToFilter = async () => {
    try {
      const getAirlines = await airlinesAllowedPerUser(authorization, id);
      setAirlines(airlinesAdapter(getAirlines.data));

      const getAirports = await stationsAllowedPerUser(authorization, id);
      setAirports(airportsAdapter(getAirports.data));

      const getIataFlights = await getAllIataFlights(authorization, id);
      setIataFlights(iataFlightsAdapter(getIataFlights.data));
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t(
            'flights.views.index.messages.api.error.no-data-to-filter',
          ),
          languageFile: LANGUAGES_FILES.FLIGHTS,
        }),
      );
    }
  };

  const getReportCsv = async () => {
    if (!initialDate || !finalDate) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.WARNING,
          message: t(
            'flights.views.index.messages.api.error.all-fields-download',
          ),
          languageFile: LANGUAGES_FILES.FLIGHTS,
        }),
      );
    } else {
      setIsReportDownloaded(true);
      try {
        const { data } = await resultReport(
          authorization,
          initialDate,
          finalDate,
          stationCsvId,
          airlineCsvId,
          iataFlightCsv,
        );

        const urlReport = `/reports_csv/${data.path_csv}.csv`;
        saveAs(urlReport, `${data.path_csv}.csv`);
      } catch (error) {
        dispatch(
          createNewAlert({
            newAlert: true,
            type: ALERT_TYPE.ERROR,
            message: t('flights.views.index.messages.api.error.error-download'),
            languageFile: LANGUAGES_FILES.FLIGHTS,
          }),
        );
      } finally {
        setIsReportDownloaded(false);
      }
    }
  };

  useEffect(() => {
    dispatch(selectMenu(MENU_SELECT.FLIGHTS));
    getPermissions();
    getDataToFilter();
    getFlightsFiltereds();
  }, []);

  useEffect(() => {
    dispatch(setNavbarInfo({ title, breadcrumbs }));

    return () => {
      dispatch(clearNavbar());
    };
  }, [e.language]);

  return (
    <main className="w-full min-w-[280px] h-auto flex flex-col gap-1">
      <section
        className="
          w-full bg-white grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3
          xl:px-5 gap-4 place-items-center shadow-md border py-5 mb-1 sm:rounded-[6px]
        "
      >
        <DatePicker
          setParams={setParams(setDate)}
          defaultValue={defaultDate}
          label={t(
            'flights.views.index.layout.forms.filter-flights.labels.date-select',
          )}
          disabled={isLoading}
        />

        <SelectAutocomplete
          data={recordsInOut}
          setParams={setParams(setRecords)}
          label={t(
            'flights.views.index.layout.forms.filter-flights.labels.with-records-in-out-select',
          )}
          defaultValue={t(
            'flights.views.index.layout.forms.filter-flights.inputs.with-records-in-out-select.with-records-in-out-option',
          )}
          disabled={isLoading}
        />

        <SelectAutocomplete
          data={airports}
          setParams={setParams(setStationId)}
          label={t(
            'flights.views.index.layout.forms.filter-flights.labels.airport-select',
          )}
          disabled={isLoading}
        />

        <SelectAutocomplete
          data={airlines}
          setParams={setParams(setAirlineId)}
          label={t(
            'flights.views.index.layout.forms.filter-flights.labels.airline-select',
          )}
          disabled={isLoading}
        />

        <SelectAutocomplete
          data={iataFlights}
          setParams={setParams(setIataFlight)}
          label={t(
            'flights.views.index.layout.forms.filter-flights.labels.flight-code-select',
          )}
          disabled={isLoading}
        />

        <div className="col-span-1">
          <ButtonGeneral
            value={t(
              'flights.views.index.layout.forms.filter-flights.buttons.filter',
            )}
            onClick={getFlightsFiltereds}
            width={130}
            disabled={isLoading}
          />
        </div>
      </section>

      <section className="w-full h-[75%] min-h-[500px] bg-white sm:rounded-[6px] shadow-md border pb-5 pt-4 relative mb-1">
        {isLoading && (
          <SecondLoading
            size={100}
            color={'#475569'}
            rounded={'6px'}
            opacity={'0.4'}
          />
        )}

        {columns.length ? (
          <div className="h-full flex flex-col items-center gap-4 xs:px-1 sm:px-4 md:px-5">
            <div className="w-full grid grid-cols-1 gap-3 md:grid-cols-3">
              {canCreateFlight ? (
                <div className="w-full flex items-center justify-center md:order-2 md:justify-end md:pr-3">
                  <ButtonGeneral
                    value={t('flights.views.index.layout.buttons.new-flight')}
                    width={120}
                    onClick={() => navigate('/flights/new')}
                  />
                </div>
              ) : null}

              <div className="w-full flex justify-center md:col-start-2">
                <SearchBar
                  list={flights.rows}
                  handleFilter={handleFilter}
                  columnsToSearch={columnsToSearch}
                  placeholder={t('flights.views.index.layout.tables.table-flights.searchbar')}
                  disabled={isLoading}
                  update={updateSearch}
                />
              </div>
            </div>

            <DataTable
              data={flights}
              headers={columns}
              RowsComponent={RowsTableFlights}
              permission={canShowFlight}
              noData={t(
                'flights.views.index.layout.tables.table-flights.tabla.no-data',
              )}
            />
          </div>
        ) : null}
      </section>

      {canDownloadReportCsv ? (
        <section
          className="
          w-full bg-white relative
          xl:px-5 shadow-md border pb-5 pt-4 sm:rounded-[6px]
        "
        >
          {isReportDownloaded && (
            <SecondLoading
              size={100}
              color={'#475569'}
              rounded={'6px'}
              opacity={'0.4'}
            />
          )}

          <h2
            className={`text-[#273447] text-[20px] font-semibold text-center mb-5 ${
              isReportDownloaded && 'text-opacity-50'
            }`}
          >
            {t(
              'flights.views.index.layout.forms.download-flights-report.title',
            )}
          </h2>

          <div className="w-full grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 place-items-center">
            <DatePicker
              setParams={setParams(setInitialDate)}
              label={t(
                'flights.views.index.layout.forms.download-flights-report.labels.initial-date',
              )}
              disabled={isReportDownloaded}
            />

            <DatePicker
              setParams={setParams(setFinalDate)}
              label={t(
                'flights.views.index.layout.forms.download-flights-report.labels.final-date',
              )}
              disabled={isReportDownloaded}
            />

            <SelectAutocomplete
              data={airports}
              setParams={setParams(setStationCsvId)}
              label={t(
                'flights.views.index.layout.forms.download-flights-report.labels.airport-select',
              )}
              disabled={isReportDownloaded}
            />

            <SelectAutocomplete
              data={airlines}
              setParams={setParams(setAirlineCsvId)}
              label={t(
                'flights.views.index.layout.forms.download-flights-report.labels.airline-select',
              )}
              disabled={isReportDownloaded}
            />

            <SelectAutocomplete
              data={iataFlights}
              setParams={setParams(setIataFlightCsv)}
              label={t(
                'flights.views.index.layout.forms.download-flights-report.labels.flight-code-select',
              )}
              disabled={isReportDownloaded}
            />

            <div className="col-span-1">
              <ButtonGeneral
                value={t(
                  'flights.views.index.layout.forms.download-flights-report.buttons.download',
                )}
                onClick={getReportCsv}
                width={130}
                disabled={isReportDownloaded}
              />
            </div>
          </div>
        </section>
      ) : null}
    </main>
  );
}
