// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import Countries from '../../pages/countries_page/components/Countries';

// Pages imports with lazy loading
const NewCountry = lazy(() =>
  import('../../pages/countries_page/components/NewCountry'),
);
const EditCountry = lazy(() =>
  import('../../pages/countries_page/components/EditCountry'),
);

function CountriesRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const countriesRoutes = [
    {
      path: 'parameters/countries',
      element: <Countries />,
      isAllowed: customPermission.country_index,
    },
    {
      path: 'parameters/countries/new',
      element: <NewCountry />,
      isAllowed: customPermission.country_create,
    },
    {
      path: 'parameters/countries/edit/:id',
      element: <EditCountry />,
      isAllowed: customPermission.country_update,
    },
  ];

  return countriesRoutes;
}

export default CountriesRoutes;
