export const menusAdapter = (data, handleModal) => {
  const language = localStorage.getItem('language');

  const response = [];
  data.forEach((item) => {
    if (item.full_name_sections_en.includes('xxx')) return;

    response.push({
      id: item.menu_id,
      isEnabled: item.is_enabled,
      level: item.menu_level,
      nameEn: item.menu_name_en,
      nameEs: item.menu_name_es,
      path: item.menu_path,
      sectionFullName:
        language === 'en'
          ? item.full_name_sections_en
          : item.full_name_sections_es,
      permissionName:
        language === 'en' ? item.permission_name_en : item.permission_name_en,
      handleModal,
    });
  });

  return response;
};

export const permissionsListAdapter = (data) => {
  const language = localStorage.getItem('language');

  const response = [];
  data.forEach((item) => {
    if (item.name_en.includes('xxx')) return;
    if (!item.is_enabled) return;

    response.push({
      id: item.id,
      name: language === 'en' ? item.name_en : item.name_es,
      isEnabled: item.is_enabled,
    });
  });

  return response;
};

export const currentMenuAdapter = (data) => {
  const language = localStorage.getItem('language');

  return {
    id: data.id,
    permission:
      language === 'en'
        ? data.custom_permission.name_en
        : data.custom_permission.name_es,
    permissionId: data.custom_permission_id,
    nameEn: data.name_en,
    nameEs: data.name_es,
    path: data.path,
    level: data.level,
    icon: data.icon,
  };
};
