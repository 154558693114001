// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import Permissions from '../../pages/permissions_page/components/Permissions';

// Pages imports with lazy loading
const NewPermission = lazy(() =>
  import('../../pages/permissions_page/components/NewPermission'),
);
const EditPermission = lazy(() =>
  import('../../pages/permissions_page/components/EditPermission'),
);

function PermissionsRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const permissionsRoutes = [
    {
      path: 'configuration/permissions',
      element: <Permissions />,
      isAllowed: customPermission.custom_permission_index,
    },
    {
      path: 'configuration/permissions/new',
      element: <NewPermission />,
      isAllowed: customPermission.custom_permission_create,
    },
    {
      path: 'configuration/permissions/edit/:id',
      element: <EditPermission />,
      isAllowed: customPermission.custom_permission_update,
    },
  ];

  return permissionsRoutes;
}

export default PermissionsRoutes;
