// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import CompanyServices from '../../pages/companies_page/components/CompanyServices';

// Pages imports with lazy loading
const NewCompanyService = lazy(() =>
  import('../../pages/companies_page/components/NewCompanyService'),
);
const EditCompanyService = lazy(() =>
  import('../../pages/companies_page/components/EditCompanyService'),
);

function CompanyServicesRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const companyServicesRoutes = [
    {
      path: 'parameters/company_services',
      element: <CompanyServices />,
      isAllowed: customPermission.company_service_index,
    },
    {
      path: 'parameters/company_services/new',
      element: <NewCompanyService />,
      isAllowed: customPermission.company_service_create,
    },
    {
      path: 'parameters/company_services/edit/:id',
      element: <EditCompanyService />,
      isAllowed: customPermission.company_service_update,
    },
  ];

  return companyServicesRoutes;
}

export default CompanyServicesRoutes;
