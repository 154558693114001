import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import sha512 from 'js-sha512';

import { LOGO_TECH } from '../../../constants/logoTech';
import { EMAIL_REGUEX } from '../../../constants/regex';
import { ALERT_TYPE, LANGUAGES_FILES } from '../../../constants/enums';

import backImage from '../../../assets/imgs/back_image.png';

import { HiOutlineMail } from 'react-icons/hi';
import { CgPassword } from 'react-icons/cg';
import { FiSettings } from 'react-icons/fi';

import { encryption } from '../../../utils/crypto-js';
import { loginMessages } from '../../../utils/errorMessages';

import { postDataUser, resultUpdateUser } from '../../../services/api/v1/user_requests/userRequests';
import { resultCustomPermissionsByUser } from '../../../services/api/v1/permission_requests/permissionRequests';
import { getPrivacyPolicyUrl } from '../../../services/api/v1/setting_requests/settingRequests'

import { setCustomPermissions } from '../../../redux/reducer/permissions/actions/permissionsActions';
import {
  createNewAlert,
  clearAlert,
} from '../../../redux/reducer/alert-messages/alerts.actions';
import {
  setAuthorization,
  setInformationUser,
} from '../../../redux/reducer/authorization/actions/authorizationsActions';

import InputLogin from './InputLogin';
import ButtonGeneral from '../../../components/ButtonGeneral';
import FooterForm from '../../../components/FooterForm';
import IconTextLink from '../../../components/IconTextLink';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';
import SelectorLanguage from '../../../components/SelectorLanguage';
import PrivacyPolicy from '../../../components/PrivacyPolicy'

export default function Login() {
  const dispatch = useDispatch();
  const [t] = useTranslation(LANGUAGES_FILES.LOGIN);

  const [session, setSession] = useState({})
  const [privacyPolicyURL, setPrivacyPolicyURL] = useState('')
  const [showModalPrivacyPolicy, setShowModalPrivacyPolicy] = useState(false)

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordEncrypted, setPasswordEncrypted] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const sendCredentials = async () => {
    try {
      const res = await postDataUser({ email, password: passwordEncrypted })
      setSession(res)

      if (res.data.privacy_policy_accepted) {
        const response = await resultCustomPermissionsByUser(
          res.data.id,
          res.headers.authorization,
        );
        dispatch(setCustomPermissions(response.data))
  
        const authorization = encryption(
          res.headers.authorization,
          res.data.identification,
        )
  
        dispatch(setAuthorization(authorization))
        dispatch(setInformationUser(res.data))
      } else {
        const { data } = await getPrivacyPolicyUrl(res.headers.authorization)
        setPrivacyPolicyURL(data.privacy_policy_url)
        setShowModalPrivacyPolicy(true)
      }
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('login.messages.api.error.user-unauthorized'),
          languageFile: LANGUAGES_FILES.LOGIN,
        }),
      )
    } finally {
      setIsLoading(false)
    }
  }

  const acceptPrivacyPolicy = async () => {
    setIsLoading(true)
    try {
      const updatePrivacyPolicy = {
        user: {
          privacy_policy_accepted_at: new Date().toISOString(),
        },
      }
      await resultUpdateUser(session.headers.authorization, session.data.id, updatePrivacyPolicy)       
      sendCredentials()
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('login.messages.api.error.update-privacy-policy'),
          languageFile: LANGUAGES_FILES.LOGIN,
        }),
      )
    } finally {
      closePrivacyPolicyModal()
    }
  }

  const closePrivacyPolicyModal = () => {
    setShowModalPrivacyPolicy(false)
  }

  const validateErrors = (name, value) => {
    let newMessage = '';

    if (name === 'email') {
      if (!value) newMessage = loginMessages.web.emailEmpty;
      else if (!EMAIL_REGUEX.test(value))
        newMessage = loginMessages.web.emailInvalid;
    } else if (name === 'password') {
      if (!value) newMessage = loginMessages.web.passwordEmpty;
      else if (value.length < 8) newMessage = loginMessages.web.passwordLength;
    }

    if (newMessage) setErrors({ ...errors, [name]: newMessage });
    else setErrors({ ...errors, [name]: '' });
  };

  const closeAlert = () => {
    dispatch(clearAlert());
  };

  const handleChange = (setState) => (event) => {
    const { name, value } = event.target;
    validateErrors(name, value);
    if (name === 'password') setPasswordEncrypted(sha512(value));
    setState(value);
    closeAlert();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const message = errors.email
      ? errors.email
      : errors.password
      ? errors.password
      : !email || !password
      ? loginMessages.web.fieldsEmpties
      : '';

    if (message) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.WARNING,
          message,
          languageFile: LANGUAGES_FILES.LOGIN,
        }),
      );
      return;
    }

    setIsLoading(true);
    sendCredentials();
  };

  useEffect(() => {
    return () => {
      closeAlert();
    };
  }, []);

  return (
    <main className="h-full w-full flex flex-col items-center justify-center gap-3 sm:px-16 md:bg-[#273447] md:px-0">
      {isLoading && (
        <Modal>
          <Loading />
        </Modal>
      )}

      {showModalPrivacyPolicy && (
        <Modal>
          <PrivacyPolicy
            handleClick={acceptPrivacyPolicy}
            handleClose={closePrivacyPolicyModal}
            URL={privacyPolicyURL}
          />
        </Modal>
      )}

      <div className="text-[13px] hidden text-white absolute right-6 top-5 md:flex items-center gap-2">
        <span>{t('login.layout.language-selector')}</span>
        <SelectorLanguage />
      </div>

      <div className="bg-white min-w-[300px] w-full h-full md:h-[501px] md:flex md:justify-between md:max-w-[660px] z-10">
        <section className="hidden h-full md:block relative">
          <div>
            <img src={backImage} alt="Background airplane" />
          </div>

          <div className="w-[263px] hidden absolute bottom-3 md:flex justify-center">
            <IconTextLink
              icon={FiSettings}
              text={t('login.layout.links.to-forgot-password-page')}
              path={'/password_forgot'}
              textColor={'white'}
            />
          </div>
        </section>

        <section className="pt-12 pb-3 h-full flex flex-col items-center justify-between md:w-3/5">
          <div className="w-52 md:w-40">
            <img src={LOGO_TECH} alt="logo-longport-tech" />
          </div>

          <form
            className="m-0 py-4 w-full flex flex-col items-center gap-5"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col items-center gap-8 w-full">
              <InputLogin
                icon={HiOutlineMail}
                type="text"
                name="email"
                value={email}
                placeholder={t('login.layout.forms.inputs.email')}
                onChange={handleChange(setEmail)}
                error={errors.email && t(errors.email)}
              />

              <InputLogin
                icon={CgPassword}
                type="password"
                name="password"
                value={password}
                placeholder={t('login.layout.forms.inputs.password')}
                onChange={handleChange(setPassword)}
                error={errors.password && t(errors.password)}
              />
            </div>

            <div className="w-9/12 flex flex-col-reverse">
              <div className="w-full flex justify-center mt-24 md:mt-10">
                <ButtonGeneral value={t('login.layout.forms.buttons.login')} />
              </div>

              <div className="w-9/12 md:hidden">
                <IconTextLink
                  icon={FiSettings}
                  text={t('login.layout.links.to-forgot-password-page')}
                  path={'/password_forgot'}
                  textColor={'slate-500'}
                />
              </div>
            </div>
          </form>

          <FooterForm />
        </section>
      </div>

      <p className="text-sm text-white font-light hidden md:block z-10">
        {`${t('login.layout.footer.part-1')} ${new Date().getFullYear()} ${t('login.layout.footer.part-2')}`}
      </p>

      <div className="w-1/2 h-1/2 hidden md:block absolute overflow-hidden right-0 bottom-0">
        <div className="bg-[#239DF7] opacity-60 rounded-full w-[500px] h-[500px] absolute left-56 top-28"></div>
      </div>
      <div className="w-1/2 h-1/2 hidden md:block absolute overflow-hidden left-0 top-0">
        <div className="bg-[#F3F6FE] opacity-60 rounded-full w-[500px] h-[500px] absolute right-56 bottom-28"></div>
      </div>
    </main>
  );
}
