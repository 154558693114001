import {
  SET_AIRPORTS,
  SET_AIRPORTS_BY_USER,
  SET_AIRPORTS_DATA,
  SET_AIRPORT_ID,
  SET_EDIT_AIRPORT,
  SET_NEW_AIRPORT,
  SET_OPTION_AIRPORT,
  SET_OPTION_AIRPORT_FILTER,
  SET_OPTION_AIRPORT_ID,
} from '../types/airportsTypes';

const initialState = {
  resultAirportsData: [],
  resultOptionAirport: [],
  resultOptionAirportId: [],
  resultOptionAirportFilter: [],
  resultAirports: [],
  resultNewAirport: [],
  resultAirportId: [],
  resultEditAirport: [],
  resultAirportsByUser: [],
};

function airportsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AIRPORTS_DATA:
      return { ...state, resultAirportsData: action.payload };

    case SET_OPTION_AIRPORT:
      return { ...state, resultOptionAirport: action.payload };

    case SET_OPTION_AIRPORT_ID:
      return { ...state, resultOptionAirportId: action.payload };

    case SET_OPTION_AIRPORT_FILTER:
      return { ...state, resultOptionAirportFilter: action.payload };

    case SET_AIRPORTS:
      return { ...state, resultAirports: action.payload };

    case SET_NEW_AIRPORT:
      return { ...state, resultNewAirport: action.payload };

    case SET_AIRPORT_ID:
      return { ...state, resultAirportId: action.payload };

    case SET_EDIT_AIRPORT:
      return { ...state, resultEditAirport: action.payload };

    case SET_AIRPORTS_BY_USER:
      return { ...state, resultAirportsByUser: action.payload };

    default:
      return state;
  }
}

export default airportsReducer;
