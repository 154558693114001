import { axiosSesion } from '../axios';

// Sign in to the app
export const postDataUser = (data) => {
  return axiosSesion.post('/login', { user: data });
};

// Sign out of the application
export const logOut = (Autorization) => {
  return axiosSesion.delete('/logout', {
    headers: { Autorization },
  });
};

// Update password
export const resultUpdatePassword = (
  Authorization,
  id,
  password,
  confirmPassword,
) => {
  return axiosSesion.patch(
    `/api/v1/users/${id}`,
    {
      user: {
        password: `${password}`,
        password_confirmation: `${confirmPassword}`,
      },
    },
    {
      headers: { Authorization },
    },
  );
};

// Create new user
export const resultNewUser = (Authorization, newUser) => {
  return axiosSesion.post('/api/v1/users/', newUser, {
    headers: { Authorization },
  });
};

// Get all users
export const resultUsers = (Authorization) => {
  return axiosSesion.get('/api/v1/users', { headers: { Authorization } });
};

// Get user by ID
export const resultUserId = (Authorization, id) => {
  return axiosSesion.get(`/api/v1/users/${id}`, {
    headers: { Authorization },
  });
};

// Update user data
export const resultUpdateUser = (Authorization, id, userUpdate) => {
  return axiosSesion.put(`/api/v1/users/${id}`, userUpdate, {
    headers: { Authorization },
  });
};

// Forgot password
export const forgotPassword = (data) => {
  return axiosSesion.post('/password', data);
};

// Token verification and password reset
export const verifyResetPassword = (data) => {
  return axiosSesion.patch('/password', data);
};

// Get airlines by user id
export const airlinesAllowedPerUser = (Authorization, userId) => {
  return axiosSesion.get(
    '/api/v1/users/get_airlines_allowed',
    {
      headers: { Authorization },
      params: { user_id: userId },
    }
  )
}

// Get stations by user id
export const stationsAllowedPerUser = (Authorization, userId) => {
  return axiosSesion.get(
    '/api/v1/users/get_stations_allowed',
    {
      headers: { Authorization },
      params: { user_id: userId },
    }
  )
}

// Get users list in Excel
export const exportUsersList = (Authorization, locale) => {
  return axiosSesion.get(
    '/api/v1/users/download_list',
    {
      headers: { Authorization },
      params: { locale },
      responseType: 'blob',
    },
  )
}
