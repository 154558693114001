const SET_NAVBAR_INFO = 'SET_NAVBAR_INFO';
const CLEAR_NAVBAR = 'CLEAR_NAVBAR';

export const setNavbarInfo = (payload) => ({ type: SET_NAVBAR_INFO, payload });
export const clearNavbar = () => ({ type: CLEAR_NAVBAR });

const initialState = {
  title: '',
  breadcrumbs: [],
};

export const navbar = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAVBAR_INFO:
      return {
        title: action.payload.title,
        breadcrumbs: action.payload.breadcrumbs,
      };

    case CLEAR_NAVBAR:
      return {
        title: '',
        breadcrumbs: [],
      };

    default:
      return state;
  }
};
