import { CLEAN_ALERT, LAUNCH_NEW_ALERT } from '../types/alertsTypes';

const initialState = {
  launch: false,
  message: 'first message',
  severity: 'success',
  goTo: '',
  subString: '',
};

function alertsReducer(state = initialState, action) {
  switch (action.type) {
    case LAUNCH_NEW_ALERT:
      return {
        launch: action.payload.launch,
        message: action.payload.message,
        severity: action.payload.severity,
        goTo: action.payload.goTo,
        subString: action.payload.subString,
      };

    case CLEAN_ALERT:
      return {
        launch: false,
        message: '',
        severity: '',
        goTo: '',
        subString: '',
      };

    default:
      return state;
  }
}

export default alertsReducer;
