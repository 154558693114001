export default function ButtonGeneral({
  value,
  onClick,
  width,
  disabled = false,
}) {
  return (
    <button
      className="
        bg-[#273447] min-w-fit h-[36px] text-white text-sm rounded-lg px-8 py-2 disabled:bg-opacity-60 select-none
      "
      style={{ width: `${width}px` }}
      onClick={onClick}
      disabled={disabled}
    >
      {value}
    </button>
  );
}
