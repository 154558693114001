import { dateWithTimezone } from '../../../utils/dateFormatter';

export const airlinesAdapter = (data, handleModal) => {
  const response = [];
  data.forEach((item) => {
    if (item.name.includes('xxx')) return;

    response.push({
      id: item.id,
      isEnabled: item.is_enabled,
      iataCode: item.iata_code,
      name: item.name,
      createdAt: dateWithTimezone(item.created_at),
      handleModal,
    });
  });

  return response;
};
