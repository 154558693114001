import { useRef } from 'react';
import { useDispatch} from 'react-redux';
import { setOpenSidebarWithModal } from '../../../redux/reducer/sidebar/sidebar.reducers';
import Sidebar from './Sidebar';

export default function SidebarWithModal() {
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  const handleClick = (event) => {
    if (event.target === modalRef.current) {
      dispatch(setOpenSidebarWithModal(false));
    }
  };

  return (
    <main
      className="fixed inset-0 z-50 outline-none focus:outline-none bg-neutral-800 bg-opacity-50"
      onClick={handleClick}
      ref={modalRef}
    >
      <Sidebar />
    </main>
  );
}
