import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BiSolidUserCircle } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';

import UserInfoSVG from '../../../../assets/icons/profile/user-information.svg?react';
import ChangePasswordSVG from '../../../../assets/icons/profile/change-password.svg?react';
import LogOutSVG from '../../../../assets/icons/profile/log-out.svg?react';

import { LANGUAGES_FILES, ALERT_TYPE } from '../../../../constants/enums';

import {
  clearAlert,
  createNewAlert,
} from '../../../../redux/reducer/alert-messages/alerts.actions';

import { resultUserId } from '../../../../services/api/v1/user_requests/userRequests';

import UserInformation from './UserInformation';
import ChangePassword from './ChangePassword';
import LogOut from './LogOut';

import DecryptedToken from '../../../../components/security_components/decrypted_token/decrypted_token';

export default function Profile({ handleCloseProfile }) {
  const [t] = useTranslation(LANGUAGES_FILES.NAVBAR);
  const authorization = DecryptedToken();
  const dispatch = useDispatch();

  const [buttonActive, setButtonActive] = useState('userInfo');
  const [dataUser, setDataUser] = useState(null);
  const { informationUser } = useSelector((state) => state.authorization);

  const language = localStorage.getItem('language');

  const handleActive = (value) => {
    setButtonActive(value);
  };

  const getData = async () => {
    try {
      const { data } = await resultUserId(authorization, informationUser.id);
      setDataUser(data);
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t(
            'navbar.views.profile.my-profile.messages.api.error.connect',
          ),
          languageFile: LANGUAGES_FILES.NAVBAR,
        }),
      );
    }
  };

  useEffect(() => {
    getData();
    return () => {
      dispatch(clearAlert());
    };
  }, []);

  return (
    <main
      className="
        fixed inset-0 z-50 outline-none focus:outline-none bg-neutral-800 bg-opacity-50 sl:flex
        sl:justify-center sl:items-center
      "
    >
      <section
        className="
          xs:bg-white xs:shadow-lg md:shadow-none md:bg-transparent sl:rounded-b-[4px] xs:h-full xs:w-full
          sl:h-[90vh] sl:max-h-[500px] sl:w-1/2 md:w-[380px] relative
        "
      >
        <section className="absolute right-2 xs:top-[10px] md:top-[70px] z-10">
          <button
            className="text-white hover:text-[#50B8E4]"
            onClick={handleCloseProfile}
          >
            <AiOutlineClose size={20} />
          </button>
        </section>

        <div className="w-full flex justify-center pt-[12px] relative">
          <div className="bg-white w-[96px] h-[96px] flex justify-center items-center text-[#50B8E4] rounded-full z-10">
            <BiSolidUserCircle size={100} />
          </div>
          <div className="bg-[#273447] w-full h-[112px] md:h-[50px] absolute -bottom-1"></div>
        </div>

        <header className="bg-[#273447] text-white pt-2 pb-[15px]">
          <h3 className="text-lg text-center mb-1">{`${informationUser.first_name} ${informationUser.last_name}`}</h3>
          <h5 className="text-[14px] text-center">
            {language === 'en'
              ? informationUser.job_position.name.split('||')[1]
              : informationUser.job_position.name.split('||')[0]}
          </h5>
        </header>

        <section className="flex">
          <button
            className={`w-1/3 flex justify-center py-2 ${
              buttonActive === 'userInfo' ? 'bg-white' : 'bg-[#D9D9D9]'
            }`}
            onClick={() => handleActive('userInfo')}
          >
            <UserInfoSVG
              fill={`${buttonActive === 'userInfo' ? '#50B8E4' : '#273447'}`}
            />
          </button>

          <button
            className={`w-1/3 flex justify-center py-2 ${
              buttonActive === 'changePassword' ? 'bg-white' : 'bg-[#D9D9D9]'
            }`}
            onClick={() => handleActive('changePassword')}
          >
            <ChangePasswordSVG
              stroke={`${
                buttonActive === 'changePassword' ? '#50B8E4' : '#273447'
              }`}
            />
          </button>

          <button
            className={`w-1/3 flex justify-center py-2 ${
              buttonActive === 'logOut' ? 'bg-white' : 'bg-[#D9D9D9]'
            }`}
            onClick={() => handleActive('logOut')}
          >
            <LogOutSVG
              stroke={`${buttonActive === 'logOut' ? '#50B8E4' : '#273447'}`}
            />
          </button>
        </section>

        {buttonActive === 'userInfo' ? (
          <UserInformation
            informationUser={informationUser}
            data={dataUser}
            language={language}
          />
        ) : buttonActive === 'changePassword' ? (
          <ChangePassword handleClose={handleCloseProfile} />
        ) : (
          <LogOut handleClose={handleCloseProfile} />
        )}
      </section>
    </main>
  );
}
