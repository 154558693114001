import CryptoJS from 'crypto-js';

export const encryption = (token, key) => {
  const encryptedToken = CryptoJS.AES.encrypt(token, key).toString();
  return encryptedToken;
};

export const decryption = (encryptedToken, key) => {
  const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
  const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedToken;
};
