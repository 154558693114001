import { axiosSesion } from '../axios';

// Get all areas
export const resultAreas = (Authorization) => {
  return axiosSesion.get('/api/v1/areas', { headers: { Authorization } });
};

// Get area by ID
export const resultAreaId = (Authorization, id) => {
  return axiosSesion.get(`/api/v1/areas/${id}`, {
    headers: { Authorization },
  });
};

// Create new area
export const resultNewArea = (Authorization, newArea) => {
  return axiosSesion.post('/api/v1/areas', newArea, {
    headers: { Authorization },
  });
};

// Update area
export const resultEditArea = (Authorization, id, areaUpdate) => {
  return axiosSesion.put(`/api/v1/areas/${id}`, areaUpdate, {
    headers: { Authorization },
  });
};
