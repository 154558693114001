/* eslint-disable no-console */
// External imports
import { createStore, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

// Reducers imports
import reducer from '../reducer';

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authorization', 'permissions'],
};
const persistedReducer = persistReducer(persistConfig, reducer);

// Store creation
const store = createStore(persistedReducer, applyMiddleware(thunk));

// Persistore creation
export const persistor = persistStore(store);

export default store;
