import { axiosSesion } from '../axios';

// Get all permission types
export const resultPermissionTypes = (Authorization) => {
  return axiosSesion.get('/api/v1/permission_types', {
    headers: { Authorization },
  });
};

// Get permission type by ID
export const resultGetPermissionTypeId = (Authorization, id) => {
  return axiosSesion.get(`/api/v1/permission_types/${id}`, {
    headers: { Authorization },
  });
};

// Create new permission type
export const resultNewPermissionType = (
  Authorization,
  code,
  nameEn,
  nameEs,
) => {
  return axiosSesion.post(
    '/api/v1/permission_types',
    {
      permission_type: {
        code,
        name_en: nameEn,
        name_es: nameEs,
      },
    },
    {
      headers: { Authorization },
    },
  );
};

// Update permission type
export const resultUpdatePermissionType = (
  Authorization,
  id,
  code,
  nameEn,
  nameEs,
) => {
  return axiosSesion.put(
    `/api/v1/permission_types/${id}`,
    {
      permission_type: {
        code,
        name_en: nameEn,
        name_es: nameEs,
      },
    },
    {
      headers: { Authorization },
    },
  );
};

export const enableDisablePermissionType = (Authorization, id, type) => {
  const body = {};
  if (type === 'enable') body.with_disables = true;

  return axiosSesion.patch(`/api/v1/permission_types/${id}/${type}`, body, {
    headers: { Authorization },
  });
};
