import { useNavigate } from 'react-router-dom';

export default function ShowFlightDetail({ flightId, text }) {
  const navigate = useNavigate();

  const text1 = text.split('[')[0].trim();
  const text2 = `[${text.split('[')[1]}`;

  const handleClick = () => {
    navigate(`/flights/show/${flightId}`);
  };

  return (
    <div className="h-[20px] w-full flex justify-left relative">
      <button
        className="h-full w-full text-[#4872b3] text-[13px] hover:text-[#50B8E4] absolute flex justify-between px-0 whitespace-nowrap"
        onClick={handleClick}
      >
        <span className='mr-3'>{text1}</span>
        <span className='overflow-hidden text-ellipsis'>{text2}</span>
      </button>
    </div>
  );
}
