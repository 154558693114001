import { dateWithTimezone } from '../../../utils/dateFormatter';

export const citiesAdapter = (data, handleModal) => {
  const language = localStorage.getItem('language');

  const response = [];
  data.forEach((item) => {
    if (item.name.includes('xxx')) return;

    response.push({
      id: item.id,
      isEnabled: item.is_enabled,
      iataCode: item.iata_code,
      name:
        language === 'en' ? item.name.split('||')[1] : item.name.split('||')[0],
      countryName:
        language === 'en'
          ? `${item.country.iso_code} - ${item.country.name.split('||')[1]}`
          : `${item.country.iso_code} - ${item.country.name.split('||')[0]}`,
      createdAt: dateWithTimezone(item.created_at),
      handleModal,
    });
  });

  return response;
};

export const countriesListAdapter = (data) => {
  const language = localStorage.getItem('language');

  const response = [];
  data.forEach((item) => {
    if (item.name.includes('xxx')) return;
    if (!item.is_enabled) return;

    response.push({
      id: item.id,
      isEnabled: item.is_enabled,
      name:
        language === 'en'
          ? `[${item.iso_code}] ${item.name.split('||')[1]}`
          : `[${item.iso_code}] ${item.name.split('||')[0]}`,
    });
  });

  return response;
};

export const currentCityAdapter = (data) => {
  const language = localStorage.getItem('language');

  return {
    countryName:
      language === 'en'
        ? `[${data.country.iso_code}] ${data.country.name.split('||')[1]}`
        : `[${data.country.iso_code}] ${data.country.name.split('||')[0]}`,
    countryId: data.country_id,
    code: data.iata_code,
    nameEn: data.name.split('||')[1],
    nameEs: data.name.split('||')[0],
    fullName: data.name,
  };
};
