import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ALERT_TYPE, LANGUAGES_FILES } from '../../../constants/enums';

import { resultUserPermissions } from '../../../services/api/v1/permission_requests/permissionRequests';
import { resultCities } from '../../../services/api/v1/city_requests/cityRequests';
import { resultDisableRole } from '../../../services/api/v1/rol_requests/rolRequests';

import { createNewAlert } from '../../../redux/reducer/alert-messages/alerts.actions';
import {
  setNavbarInfo,
  clearNavbar,
} from '../../../redux/reducer/navbar/navbar.reducers';
import { citiesAdapter } from '../adapters/cities';

import RowsTableCities from './table-cities/RowsTableCities';
import SecondLoading from '../../../components/SecondLoading';
import ButtonGeneral from '../../../components/ButtonGeneral';
import SearchBar from '../../../components/SearchBar';
import DataTable from '../../../components/data_table/DataTable';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';

import DecryptedToken from '../../../components/security_components/decrypted_token/decrypted_token';
import EnableDisableAlert from '../../../components/EnableDisableAlert';

const columnsToSearch = ['iataCode', 'name', 'countryName', 'createdAt'];

export default function Cities() {
  const [t, e] = useTranslation(LANGUAGES_FILES.CITIES);
  const authorization = DecryptedToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateSearch, setUpdateSearch] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [openDisableEnableModal, setOpenDisableEnableModal] = useState(false);

  const [cities, setCities] = useState([]);
  const [cityInfo, setCityInfo] = useState({
    id: null,
    name: null,
    isEnabled: null,
  });

  const [canUpdateCities, setCanUpdateCities] = useState(false);
  const [canEnableDisableCity, setCanEnableDisableCity] = useState(false);
  const [canCreateCity, setCanCreateCity] = useState(false);

  const { id } = useSelector((state) => state.authorization.informationUser);

  const columns = [
    {
      id: 1,
      title: t(
        'cities.views.index.layout.tables.table-cities.table.header.iata-code',
      ),
      sortable: true,
      sortBy: 'isoCode',
      width: 90,
    },
    {
      id: 2,
      title: t(
        'cities.views.index.layout.tables.table-cities.table.header.name',
      ),
      sortable: true,
      sortBy: 'name',
      width: 190,
    },
    {
      id: 3,
      title: t(
        'cities.views.index.layout.tables.table-cities.table.header.country-name',
      ),
      sortable: true,
      sortBy: 'countryName',
      width: 190,
    },
    {
      id: 4,
      title: t(
        'cities.views.index.layout.tables.table-cities.table.header.created-at',
      ),
      sortable: true,
      sortBy: 'createdAt',
      width: 195,
    },
  ];

  if (canUpdateCities)
    columns.push({
      id: 5,
      title: t(
        'cities.views.index.layout.tables.table-cities.table.header.edit',
      ),
      width: 90,
      action: true,
    });

  if (canEnableDisableCity)
    columns.push({
      id: 6,
      title: t(
        'cities.views.index.layout.tables.table-cities.table.header.enabled',
      ),
      width: 180,
      action: true,
    });

  const title = t('cities.views.index.layout.title');

  const breadcrumbs = [
    {
      id: 1,
      text: t('cities.views.index.layout.breadcrumbs.home'),
      path: '/dashboard',
    },
    {
      id: 2,
      text: t('cities.views.index.layout.breadcrumbs.cities'),
    },
  ];

  const openModal = (value) => {
    setOpenDisableEnableModal(true);
    setCityInfo(value);
  };

  const closeModal = () => {
    setOpenDisableEnableModal(false);
  };

  const handleEnableDisableCity = async (id, action) => {
    setLoadingRequest(true);
    try {
      const { data } = await resultDisableRole(
        authorization,
        'cities',
        id,
        action,
      );
      await getDataToTable();
      setUpdateSearch(updateSearch + 1);

      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.DONE,
          message: data.is_enabled
            ? t('cities.views.index.messages.api.correct.enable')
            : t('cities.views.index.messages.api.correct.disable'),
          languageFile: LANGUAGES_FILES.CITIES,
        }),
      );

      closeModal();
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('cities.views.index.messages.api.error.no-update'),
          languageFile: LANGUAGES_FILES.CITIES,
        }),
      );
    } finally {
      setLoadingRequest(false);
    }
  };

  const handleFilter = (rowsFiltereds) => {
    setCities(rowsFiltereds);
  };

  const getPermissions = async () => {
    const updateCities = await resultUserPermissions(
      authorization,
      id,
      'city_update',
    );
    const enableDisableCity = await resultUserPermissions(
      authorization,
      id,
      'city_enable_disable',
    );
    const createCity = await resultUserPermissions(
      authorization,
      id,
      'city_create',
    );

    setCanUpdateCities(updateCities.data.can);
    setCanEnableDisableCity(enableDisableCity.data.can);
    setCanCreateCity(createCity.data.can);
  };

  const getDataToTable = async () => {
    const getCities = await resultCities(authorization);
    setCities(citiesAdapter(getCities.data, openModal));
    setIsLoading(false);
  };

  useEffect(() => {
    getPermissions();
    getDataToTable();
  }, []);

  useEffect(() => {
    dispatch(setNavbarInfo({ title, breadcrumbs }));

    return () => {
      dispatch(clearNavbar());
    };
  }, [e.language]);

  return (
    <main className="w-full min-w-[280px] h-full flex flex-col gap-1">
      {openDisableEnableModal ? (
        <Modal>
          <EnableDisableAlert
            handleClick={handleEnableDisableCity}
            handleClose={closeModal}
            itemInfo={cityInfo}
            itemType={t(
              'cities.views.index.layout.modals.enable-disable.title',
            )}
          />
        </Modal>
      ) : null}

      {loadingRequest ? (
        <Modal>
          <Loading />
        </Modal>
      ) : null}

      <section className="w-full h-[95%] min-h-[550px] bg-white sm:rounded-[6px] shadow-md border pb-[28px] pt-4 relative">
        {isLoading && (
          <SecondLoading
            size={100}
            color={'#475569'}
            rounded={'6px'}
            opacity={'0.4'}
          />
        )}

        {columns.length ? (
          <div className="h-full flex flex-col items-center gap-4 xs:px-1 sm:px-4 md:px-5">
            <div className="w-full grid grid-cols-1 gap-3 md:grid-cols-3">
              {canCreateCity ? (
                <div className="w-full flex items-center justify-center md:order-2 md:justify-end md:pr-3">
                  <ButtonGeneral
                    value={t('cities.views.index.layout.buttons.new-city')}
                    width={120}
                    onClick={() => navigate('/parameters/cities/new')}
                  />
                </div>
              ) : null}

              <div className="w-full flex justify-center md:col-start-2">
                <SearchBar
                  list={cities}
                  handleFilter={handleFilter}
                  columnsToSearch={columnsToSearch}
                  placeholder={t(
                    'cities.views.index.layout.tables.table-cities.searchbar',
                  )}
                  disabled={isLoading}
                  update={updateSearch}
                />
              </div>
            </div>

            <DataTable
              data={{ rows: cities }}
              headers={columns}
              RowsComponent={RowsTableCities}
              permission={{
                canUpdateCities,
                canEnableDisableCity,
              }}
              noData={t(
                'cities.views.index.layout.tables.table-cities.table.no-data',
              )}
            />
          </div>
        ) : null}
      </section>
    </main>
  );
}
