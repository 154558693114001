import { PuffLoader } from 'react-spinners';

export default function SecondLoading({ size, color, rounded, opacity }) {
  return (
    <section
      className="absolute top-0 bottom-0 left-0 right-0 z-40 flex justify-center items-center"
      style={{
        backgroundColor: color,
        opacity: opacity,
        borderRadius: rounded,
      }}
    >
      <PuffLoader size={size} speedMultiplier={1} color="white" />
    </section>
  );
}
