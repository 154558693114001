import { useSelector, useDispatch } from 'react-redux';
import { LOGO_TECH_WHITE } from '../../../constants/logoTech';
import { BiChevronLeft } from 'react-icons/bi';
import { BiChevronRight } from 'react-icons/bi';
import { MdArrowBackIosNew } from "react-icons/md";

import { changeContractedMenu } from '../../../redux/reducer/contracted-menu/contractedMenuReducer';
import { setOpenSidebarWithModal } from '../../../redux/reducer/sidebar/sidebar.reducers';

import LogOutButton from './LogOutButton';
import Menu from './menu/Menu';

export default function Sidebar({ isXl }) {
  const dispatch = useDispatch();

  const { isContractedMenu } = useSelector((state) => state.contractedMenu);
  const { openSidebarWithModal } = useSelector((state) => state.sidebar);

  const handleContracted = () => {
    dispatch(changeContractedMenu(!isContractedMenu));
  };

  return (
    <aside
      className={`h-full bg-[#273447] relative ${
        isXl
          ? `hidden xl:block ${isContractedMenu ? 'w-[75px]' : 'xl:w-[270px]'}`
          : 'xs:w-[270px]'
      }`}
    >
      <div
        className={`hidden absolute rounded-full top-[18px] h-fit xl:flex justify-center items-center shadow-md ${
          isContractedMenu ? '-right-[12px]' : '-right-[11px]'
        }`}
        style={{ zIndex: '2' }}
      >
        <button
          className="bg-[#F3F6FE] text-[#273447] rounded-full hover:text-[#50B8E4] hover:shadow-lg"
          onClick={handleContracted}
        >
          {isContractedMenu ? (
            <BiChevronRight size={22} />
          ) : (
            <BiChevronLeft size={22} />
          )}
        </button>
      </div>

      <div className="grid grid-cols-1 content-between h-full overflow-auto">
        <section>
          <header className="w-full flex flex-col gap-3 pt-3 pb-1 items-center mb-1.5 relative">
            <div className="flex justify-center">
              <img
                className={`${
                  !isContractedMenu ? 'w-full' : 'w-[50px] mb-[88px] pr-[3px]'
                }`}
                src={LOGO_TECH_WHITE}
                alt="logo-longport-tech"
              />
            </div>

            {!isContractedMenu ? (
              <>
                {openSidebarWithModal && (
                  <div className='absolute right-[10px] flex'>
                    <button
                      className='text-[#50B8E4] hover:text-white'
                      onClick={() => dispatch(setOpenSidebarWithModal(false))}
                    >
                      <MdArrowBackIosNew size={21} />
                    </button>
                  </div>
                )}

                <h3 className="text-[16px] text-white">
                  Operations Control System
                </h3>
              </>
            ) : null}
          </header>
          <Menu />
        </section>

        <LogOutButton />
      </div>
    </aside>
  );
}
