import { useNavigate } from 'react-router-dom';

import { RxSlash } from 'react-icons/rx';

export default function Breadcrumb({ breadcrumb }) {
  const navigate = useNavigate();

  const isLatest = (index) => {
    return breadcrumb.length - 1 === index;
  };

  const handleClick = (item) => {
    item.path && navigate(item.path);
  };

  return (
    <section className="flex">
      {breadcrumb.map((item, index) => (
        <button
          key={item.id}
          className="text-[#273447] font-semibold flex items-center text-sm"
          onClick={() => handleClick(item)}
        >
          <span
            className={
              !isLatest(index) ? 'hover:text-[#50B8E4]' : 'hover:text-[#526683]'
            }
          >
            {item.text}
          </span>
          {!isLatest(index) ? <RxSlash size={18} /> : null}
        </button>
      ))}
    </section>
  );
}
