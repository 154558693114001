const OPEN_SIDEBAR_WITH_MODAL = 'OPEN_SIDEBAR_WITH_MODAL';

export const setOpenSidebarWithModal = (payload) => ({
  type: OPEN_SIDEBAR_WITH_MODAL,
  payload,
});

const initialState = {
  openSidebarWithModal: false,
};

export const sidebar = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SIDEBAR_WITH_MODAL:
      return { openSidebarWithModal: action.payload };

    default:
      return state;
  }
}
