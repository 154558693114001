export const LANGUAGES = {
  ES: 'es',
  EN: 'en',
};

export const ALERT_TYPE = {
  ERROR: 'error',
  DONE: 'done',
  WARNING: 'warning',
  DEFAULT: 'default',
};

export const LANGUAGES_FILES = {
  GLOBAL: 'global',
  NAVBAR: 'navbar',
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgotPassword',
  FLIGHTS: 'flights',
  USERS: 'users',
  ROLES: 'roles',
  ID_TYPES: 'idTypes',
  JOB_POSITIONS: 'jobPositions',
  COMPANIES: 'companies',
  COUNTRIES: 'countries',
  CITIES: 'cities',
  AIRPORTS: 'airports',
  AIRLINES: 'airlines',
  AREAS: 'areas',
  POSITIONS: 'positions',
  SECTIONS: 'sections',
  PERMISSIONS: 'permissions',
  MENUS: 'menus',
  DASHBOARD: 'dashboard',
  OPERATORS: 'operators',
  SETTINGS: 'settings',
};

export const MENU_SELECT = {
  HOME: 'home',
  FLIGHTS: 'flights',
  ADMINISTRATION: 'administration',
  ADMINISTRATION_USERS: 'administration-users',
  ADMINISTRATION_COMPANIES: 'administration-companies',
  ADMINISTRATION_FLIGHTS: 'administration-flights',
  PARAMETERIZATION: 'Parameterization',
  PARAMETERIZATION_USERS: 'parameterization-users',
  USERS_ROLES: 'user-roles',
  USERS_IDENTIFICATION_TYPES: 'user-identification-types',
  USERS_JOB_POSITIONS: 'user-job-positions',
  PARAMETERIZATION_FLIGHTS: 'parameterization-flights',
  FLIGHTS_COUNTRIES: 'flights-countries',
  FLIGHTS_CITIES: 'flights-cities',
  FLIGHTS_AIRPORTS: 'flights-airports',
  FLIGHTS_AIRLINES: 'flights-airlines',
  FLIGHTS_AREAS: 'flights-areas',
  FLIGHTS_POSITIONS: 'flights-positions',
  FLIGHTS_PARAMETERIZATION: 'flights-parameterization',
  PARAMETERIZATION_COMPANIES: 'parameterization-companies',
  COMPANY_SERVICES: 'company-services',
  CONFIGURATION: 'configuration',
  CONFIGURATION_SECTIONS: 'configuration-sections',
  CONFIGURATION_PERMISSION_TYPES: 'configuration-permission-types',
  CONFIGURATION_PERMISSIONS: 'configuration-permissions',
  CONFIGURATION_MENUS: 'configuration-menus',
  CONFIGURATION_SETTINGS: 'configuration-settings',
};
