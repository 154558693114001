import { dateWithTimezone } from '../../../utils/dateFormatter';

export const positionsAdapter = (data, handleModal) => {
  const language = localStorage.getItem('language');

  const response = [];
  data.forEach((item) => {
    if (item.name.includes('xxx')) return;

    response.push({
      id: item.id,
      isEnabled: item.is_enabled,
      name:
        language === 'en' ? item.name.split('||')[1] : item.name.split('||')[0],
      areaName:
        language === 'en'
          ? item.area.name.split('||')[1]
          : item.area.name.split('||')[0],
      createdAt: dateWithTimezone(item.created_at),
      handleModal,
    });
  });

  return response;
};

export const areasListAdapter = (data) => {
  const language = localStorage.getItem('language');

  const response = [];
  data.forEach((item) => {
    if (item.name.includes('xxx')) return;
    if (!item.is_enabled) return;

    response.push({
      id: item.id,
      name:
        language === 'en' ? item.name.split('||')[1] : item.name.split('||')[0],
    });
  });

  return response;
};

export const currentPositionAdapter = (data) => {
  const language = localStorage.getItem('language');

  return {
    areaName:
      language === 'en'
        ? data.area.name.split('||')[1]
        : data.area.name.split('||')[0],
    areaId: data.area_id,
    fullName: data.name,
    nameEn: data.name.split('||')[1],
    nameEs: data.name.split('||')[0],
  };
};
