import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ALERT_TYPE, LANGUAGES_FILES } from '../../../constants/enums';

import { resultUserPermissions } from '../../../services/api/v1/permission_requests/permissionRequests';
import {
  resultPermissionTypes,
  enableDisablePermissionType,
} from '../../../services/api/v1/permission_type_requests/permissionTypeRequests';

import { createNewAlert } from '../../../redux/reducer/alert-messages/alerts.actions';
import {
  setNavbarInfo,
  clearNavbar,
} from '../../../redux/reducer/navbar/navbar.reducers';
import { permissionTypesAdapter } from '../adapters/permissionTypes';

import RowTablePermissionTypes from './table-permission-types/RowTablePermissionTypes';
import SecondLoading from '../../../components/SecondLoading';
import ButtonGeneral from '../../../components/ButtonGeneral';
import SearchBar from '../../../components/SearchBar';
import DataTable from '../../../components/data_table/DataTable';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';

import DecryptedToken from '../../../components/security_components/decrypted_token/decrypted_token';
import EnableDisableAlert from '../../../components/EnableDisableAlert';

const columnsToSearch = ['code', 'name', 'createdAt'];

export default function PermissionTypes() {
  const [t, e] = useTranslation(LANGUAGES_FILES.PERMISSIONS);
  const authorization = DecryptedToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateSearch, setUpdateSearch] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [openDisableEnableModal, setOpenDisableEnableModal] = useState(false);

  const [permissionTypes, setPermissionTypes] = useState([]);
  const [permissionTypeInfo, setPermissionTypeInfo] = useState({
    id: null,
    name: null,
    isEnabled: null,
  });

  const [canUpdatePermissionTypes, setCanUpdatePermissionTypes] =
    useState(false);
  const [canEnableDisablePermissionType, setCanEnableDisablePermissionType] =
    useState(false);
  const [canCreatePermissionType, setCanCreatePermissionType] = useState(false);

  const { id } = useSelector((state) => state.authorization.informationUser);

  const columns = [
    {
      id: 1,
      title: t(
        'permission-types.views.index.layout.tables.table-permission-types.table.header.code',
      ),
      sortable: true,
      sortBy: 'code',
      width: 100,
    },
    {
      id: 2,
      title: t(
        'permission-types.views.index.layout.tables.table-permission-types.table.header.name',
      ),
      sortable: true,
      sortBy: 'name',
      width: 120,
    },
    {
      id: 3,
      title: t(
        'permission-types.views.index.layout.tables.table-permission-types.table.header.created-at',
      ),
      sortable: true,
      sortBy: 'createdAt',
      width: 195,
    },
  ];

  if (canUpdatePermissionTypes)
    columns.push({
      id: 4,
      title: t(
        'permission-types.views.index.layout.tables.table-permission-types.table.header.edit',
      ),
      width: 90,
      action: true,
    });

  if (canEnableDisablePermissionType)
    columns.push({
      id: 5,
      title: t(
        'permission-types.views.index.layout.tables.table-permission-types.table.header.enabled',
      ),
      width: 180,
      action: true,
    });

  const title = t('permission-types.views.index.layout.title');

  const breadcrumbs = [
    {
      id: 1,
      text: t('permission-types.views.index.layout.breadcrumbs.home'),
      path: '/dashboard',
    },
    {
      id: 2,
      text: t(
        'permission-types.views.index.layout.breadcrumbs.permission-types',
      ),
    },
  ];

  const openModal = (value) => {
    setOpenDisableEnableModal(true);
    setPermissionTypeInfo(value);
  };

  const closeModal = () => {
    setOpenDisableEnableModal(false);
  };

  const handleEnableDisablePermissionType = async (id, action) => {
    setLoadingRequest(true);
    try {
      const { data } = await enableDisablePermissionType(
        authorization,
        id,
        action,
      );
      await getDataToTable();
      setUpdateSearch(updateSearch + 1);

      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.DONE,
          message: data.is_enabled
            ? t('permission-types.views.index.messages.api.correct.enable')
            : t('permission-types.views.index.messages.api.correct.disable'),
          languageFile: LANGUAGES_FILES.PERMISSIONS,
        }),
      );

      closeModal();
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t(
            'permission-types.views.index.messages.api.error.no-update',
          ),
          languageFile: LANGUAGES_FILES.PERMISSIONS,
        }),
      );
    } finally {
      setLoadingRequest(false);
    }
  };

  const handleFilter = (rowsFiltereds) => {
    setPermissionTypes(rowsFiltereds);
  };

  const getPermissions = async () => {
    const updatePermissionTypes = await resultUserPermissions(
      authorization,
      id,
      'permission_type_update',
    );
    const enableDisablePermissionType = await resultUserPermissions(
      authorization,
      id,
      'permission_type_enable_disable',
    );
    const createPermissionType = await resultUserPermissions(
      authorization,
      id,
      'permission_type_create',
    );

    setCanUpdatePermissionTypes(updatePermissionTypes.data.can);
    setCanEnableDisablePermissionType(enableDisablePermissionType.data.can);
    setCanCreatePermissionType(createPermissionType.data.can);
  };

  const getDataToTable = async () => {
    const getPermissionTypes = await resultPermissionTypes(authorization);
    setPermissionTypes(
      permissionTypesAdapter(getPermissionTypes.data, openModal),
    );
    setIsLoading(false);
  };

  useEffect(() => {
    getPermissions();
    getDataToTable();
  }, []);

  useEffect(() => {
    dispatch(setNavbarInfo({ title, breadcrumbs }));

    return () => {
      dispatch(clearNavbar());
    };
  }, [e.language]);

  return (
    <main className="w-full min-w-[280px] h-full flex flex-col gap-1">
      {openDisableEnableModal ? (
        <Modal>
          <EnableDisableAlert
            handleClick={handleEnableDisablePermissionType}
            handleClose={closeModal}
            itemInfo={permissionTypeInfo}
            itemType={t(
              'permission-types.views.index.layout.modals.enable-disable.title',
            )}
          />
        </Modal>
      ) : null}

      {loadingRequest ? (
        <Modal>
          <Loading />
        </Modal>
      ) : null}

      <section className="w-full h-[95%] min-h-[550px] bg-white sm:rounded-[6px] shadow-md border pb-[28px] pt-4 relative">
        {isLoading && (
          <SecondLoading
            size={100}
            color={'#475569'}
            rounded={'6px'}
            opacity={'0.4'}
          />
        )}

        {columns.length ? (
          <div className="h-full flex flex-col items-center gap-4 xs:px-1 sm:px-4 md:px-5">
            <div className="w-full grid grid-cols-1 gap-3 md:grid-cols-3">
              {canCreatePermissionType ? (
                <div className="w-full flex items-center justify-center md:order-2 md:justify-end md:pr-3">
                  <ButtonGeneral
                    value={t(
                      'permission-types.views.index.layout.buttons.new-permission-type',
                    )}
                    width={120}
                    onClick={() =>
                      navigate('/configuration/permission_types/new')
                    }
                  />
                </div>
              ) : null}

              <div className="w-full flex justify-center md:col-start-2">
                <SearchBar
                  list={permissionTypes}
                  handleFilter={handleFilter}
                  columnsToSearch={columnsToSearch}
                  placeholder={t(
                    'permission-types.views.index.layout.tables.table-permission-types.searchbar',
                  )}
                  disabled={isLoading}
                  update={updateSearch}
                />
              </div>
            </div>

            <DataTable
              data={{ rows: permissionTypes }}
              headers={columns}
              RowsComponent={RowTablePermissionTypes}
              permission={{
                canUpdatePermissionTypes,
                canEnableDisablePermissionType,
              }}
              noData={t(
                'permission-types.views.index.layout.tables.table-permission-types.table.no-data',
              )}
            />
          </div>
        ) : null}
      </section>
    </main>
  );
}
