import { useTranslation } from 'react-i18next';
import { LANGUAGES_FILES } from '../../../../constants/enums';
import EditFlightParameterization from './EditFlightParameterization';

export default function RowsTableFlightParameterization({ rows, permission }) {
  const [t] = useTranslation(LANGUAGES_FILES.FLIGHTS);

  return (
    <>
      {rows.map((row) => (
        <tr
          key={row.id}
          className="text-slate-800 text-sm even:bg-slate-50 hover:bg-[#cdd5e4]"
        >
          <td
            className="p-2.5 select-none"
            style={{
              borderTop: '1px solid #e2e8f0',
              borderBottom: '1px solid #e2e8f0',
            }}
          >
            {row.flightCode}
          </td>

          <td
            className="p-2.5 select-none"
            style={{ border: '1px solid #e2e8f0' }}
          >
            {row.flightDistribution}
          </td>

          <td
            className="p-2.5 select-none"
            style={{ border: '1px solid #e2e8f0' }}
          >
            {row.configurationStatus
              ? t(
                  'flight-parameterization.views.index.layout.tables.table-flight-parameterization.tabla.field-configured.configured',
                )
              : t(
                  'flight-parameterization.views.index.layout.tables.table-flight-parameterization.tabla.field-configured.not-configured',
                )}
          </td>

          <td
            className="p-2.5 select-none"
            style={{
              borderTop: '1px solid #e2e8f0',
              borderBottom: '1px solid #e2e8f0',
              borderRight: `${permission ? '1px solid #e2e8f0' : 'none'}`,
            }}
          >
            {row.configurationAt}
          </td>

          {permission && (
            <td
              style={{
                borderTop: '1px solid #e2e8f0',
                borderBottom: '1px solid #e2e8f0',
              }}
            >
              <EditFlightParameterization flightId={row.id} />
            </td>
          )}
        </tr>
      ))}
    </>
  );
}
