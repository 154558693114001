import { dateWithTimezone } from '../../../utils/dateFormatter';

export const companiesAdapter = (data, handleModal) => {
  const language = localStorage.getItem('language');

  const response = [];
  data.forEach((item) => {
    if (item.name.includes('xxx')) return;

    response.push({
      id: item.id,
      isEnabled: item.is_enabled,
      country:
        language === 'en'
          ? item.country.name.split('||')[1]
          : item.country.name.split('||')[0],
      companyService:
        language === 'en'
          ? item.company_service.name_en
          : item.company_service.name_es,
      name: item.name,
      logo: item.base64_logo,
      createdAt: dateWithTimezone(item.created_at),
      handleModal,
    });
  });

  return response;
};
