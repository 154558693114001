import { useSelector, useDispatch } from 'react-redux';

import { BiSolidUserCircle } from 'react-icons/bi';
import { AiOutlineMenuUnfold } from 'react-icons/ai';

import { setOpenSidebarWithModal } from '../../../redux/reducer/sidebar/sidebar.reducers';

import SelectorLanguage from '../../../components/SelectorLanguage';
import Breadcrumb from '../../../components/Breadcrums';

export default function Navbar({ handleOpenProfile }) {
  const dispatch = useDispatch();
  const { title, breadcrumbs } = useSelector((state) => state.navbar);

  return (
    <nav className="bg-white sticky w-full shadow-md flex justify-between py-[1px] pr-[3%] pl-[3%] md:pl-[18px]">
      <div className="flex items-center gap-6 pl-4">
        <button
          className="text-[#273447] hover:text-[#50B8E4] xl:hidden"
          onClick={() => dispatch(setOpenSidebarWithModal(true))}
        >
          <AiOutlineMenuUnfold size={24} />
        </button>

        <h2 className="hidden text-[#273447] text-[30px] font-bold p-0 m-0 sm:text-[36px] xsl:block">
          {title}
        </h2>
      </div>

      <div className="py-2 flex items-center xs:gap-2 md:gap-4">
        {breadcrumbs.length ? (
          <div className="hidden h-full mr-4 pb-[2px] xsl:flex items-end">
            <Breadcrumb breadcrumb={breadcrumbs} />
          </div>
        ) : null}

        <button
          className="text-[#273447] hover:text-[#50B8E4]"
          onClick={handleOpenProfile}
        >
          <BiSolidUserCircle size={40} />
        </button>

        <SelectorLanguage />
      </div>
    </nav>
  );
}
