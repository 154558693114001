// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

import FlightParameterization from '../../pages/flights_page/components/FlightParameterization';

// Pages imports with lazy loading
const EditFlight = lazy(() =>
  import('../../pages/flights_page/components/EditFlight'),
);

function FlightParameterizationRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const flightParameterizationRoutes = [
    {
      path: 'parameters/flights',
      element: <FlightParameterization />,
      isAllowed: customPermission.flight_config_flights,
    },
    {
      path: 'parameters/flights/edit/:id',
      element: <EditFlight />,
      isAllowed: customPermission.flight_update,
    },
  ];

  return flightParameterizationRoutes;
}

export default FlightParameterizationRoutes;
