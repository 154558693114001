// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import Companies from '../../pages/companies_page/components/Companies';

// Pages imports with lazy loading
const NewCompany = lazy(() =>
  import('../../pages/companies_page/components/NewCompany'),
);

const EditCompany = lazy(() =>
  import('../../pages/companies_page/components/EditCompany'),
);

function CompaniesRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const companiesRoutes = [
    {
      path: '/companies',
      element: <Companies />,
      isAllowed: customPermission.company_index,
    },
    {
      path: 'companies/new',
      element: <NewCompany />,
      isAllowed: customPermission.company_create,
    },
    {
      path: 'companies/edit/:id',
      element: <EditCompany />,
      isAllowed: customPermission.company_update,
    },
  ];

  return companiesRoutes;
}

export default CompaniesRoutes;
