// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import IdentificationTypes from '../../pages/identification_types_page/components/IdentificationTypes';

// Pages imports with lazy loading
const NewIdentificationType = lazy(() =>
  import(
    '../../pages/identification_types_page/components/NewIdentificationType'
  ),
);
const EditIdentificationType = lazy(() =>
  import(
    '../../pages/identification_types_page/components/EditIdentificationType'
  ),
);

function IdentificationsTypesRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const identificationsTypesRoutes = [
    {
      path: 'parameters/identifications_types',
      element: <IdentificationTypes />,
      isAllowed: customPermission.identification_type_index,
    },
    {
      path: 'parameters/identifications_types/new',
      element: <NewIdentificationType />,
      isAllowed: customPermission.identification_type_create,
    },
    {
      path: 'parameters/identifications_types/edit/:id',
      element: <EditIdentificationType />,
      isAllowed: customPermission.identification_type_update,
    },
  ];

  return identificationsTypesRoutes;
}

export default IdentificationsTypesRoutes;
