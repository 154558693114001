import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaExternalLinkAlt } from "react-icons/fa";

import { createNewAlert } from '../../../../redux/reducer/alert-messages/alerts.actions';

import { getPrivacyPolicyUrl } from '../../../../services/api/v1/setting_requests/settingRequests';

import { LANGUAGES_FILES, ALERT_TYPE } from '../../../../constants/enums';
import DecryptedToken from '../../../../components/security_components/decrypted_token/decrypted_token';

export default function UserInformation({ informationUser, data, language }) {
  const authorization = DecryptedToken();
  const dispatch = useDispatch();
  const [t] = useTranslation(LANGUAGES_FILES.NAVBAR);

  const [infoUser, setInfoUser] = useState(null);
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState("");

  const getInfoUser = () => {
    const user = [
      {
        id: 1,
        title: t('navbar.views.profile.my-profile.layout.email'),
        value: informationUser.email,
      },
      {
        id: 4,
        title: t('navbar.views.profile.my-profile.layout.user-role'),
        value: informationUser.rol,
      },
    ];

    setInfoUser(user);
  };

  const obtainPrivacyPolicyUrl = async () => {
    try {
      const { data } = await getPrivacyPolicyUrl(authorization)
      setPrivacyPolicyUrl(data.privacy_policy_url);
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('navbar.views.profile.my-profile.messages.api.error.connect'),
          languageFile: LANGUAGES_FILES.NAVBAR,
        }),
      )
    }
  }

  useEffect(() => {
    obtainPrivacyPolicyUrl()
    getInfoUser();
  }, []);

  return (
    <section className="h-[50%] bg-white rounded-b-[4px] overflow-hidden">
      <div className="h-full overflow-auto">
        <h2 className="text-[#273447] text-center text-[18px] font-semibold pt-4 pb-3">
          {t('navbar.views.profile.my-profile.layout.title')}
        </h2>

        <span>
          <a
            className='
              w-fit flex items-center gap-2 text-[13px] font-bold text-[#273447]
              underline hover:text-[#50B8E4] hover:cursor-pointer ml-4
            '
            href={privacyPolicyUrl ? privacyPolicyUrl : ''}
            target='_blank'
          >
            <span>
              {t('navbar.views.profile.my-profile.layout.privacy-policy')}
            </span>
            <span>
              <FaExternalLinkAlt />
            </span>
          </a>
        </span>

        <div className="mt-4 px-4 flex flex-col gap-3">
          {infoUser &&
            infoUser.map((item) => (
              <section key={item.id} className="flex flex-col text-sm">
                <span className="whitespace-nowrap font-bold">
                  {item.title}
                </span>
                <span
                  className="w-full pl-2 ml-3"
                  style={{ borderBottom: '1px solid #D9D9D9' }}
                >
                  {item.value}
                </span>
              </section>
            ))}

          {data ? (
            <>
              <section className="flex flex-col text-sm">
                <span className="whitespace-nowrap font-bold">
                  {t('navbar.views.profile.my-profile.layout.airports')}
                </span>
                <ul className="w-full ml-3">
                  {data.stations.map((station) => (
                    <li
                      key={station.id}
                      className="pl-2 mb-[2px]"
                      style={{ borderBottom: '1px solid #D9D9D9' }}
                    >{`[${station.iata_code}] ${station.name}`}</li>
                  ))}
                </ul>
              </section>

              <section className="flex flex-col text-sm">
                <span className="whitespace-nowrap font-bold">
                  {t('navbar.views.profile.my-profile.layout.airlines')}
                </span>
                <ul className="w-full ml-3">
                  {data.airlines.map((airline) => (
                    <li
                      key={airline.id}
                      className="pl-2 mb-[2px]"
                      style={{ borderBottom: '1px solid #D9D9D9' }}
                    >{`[${airline.iata_code}] ${airline.name}`}</li>
                  ))}
                </ul>
              </section>
            </>
          ) : null}
        </div>
      </div>
    </section>
  );
}
