// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import Areas from '../../pages/areas_page/components/Areas';

// Pages imports with lazy loading
const NewArea = lazy(() => import('../../pages/areas_page/components/NewArea'));
const EditArea = lazy(() =>
  import('../../pages/areas_page/components/EditArea'),
);

function AreasRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const areasRoutes = [
    {
      path: 'parameters/areas',
      element: <Areas />,
      isAllowed: customPermission.area_index,
    },
    {
      path: 'parameters/areas/new',
      element: <NewArea />,
      isAllowed: customPermission.area_create,
    },
    {
      path: 'parameters/areas/edit/:id',
      element: <EditArea />,
      isAllowed: customPermission.area_update,
    },
  ];

  return areasRoutes;
}

export default AreasRoutes;
