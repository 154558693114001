import {
  SET_COUNTRIES,
  SET_NEW_COUNTRY,
  SET_GET_COUNTRY_ID,
  SET_UPDATE_COUNTRY,
  SET_GET_TIMEZONE,
} from '../types/countriesTypes';

const initialState = {
  resultCountries: [],
  resultNewCountry: [],
  resultGetCountryId: [],
  resultUpdateCountry: [],
  resultGetTimezone: [],
};

function countriesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_COUNTRIES:
      return { ...state, resultCountries: action.payload };

    case SET_NEW_COUNTRY:
      return { ...state, resultNewCountry: action.payload };

    case SET_GET_COUNTRY_ID:
      return { ...state, resultGetCountryId: action.payload };

    case SET_UPDATE_COUNTRY:
      return { ...state, resultUpdateCountry: action.payload };

    case SET_GET_TIMEZONE:
      return { ...state, resultGetTimezone: action.payload };

    default:
      return state;
  }
}

export default countriesReducer;
