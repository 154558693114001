import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ALERT_TYPE, LANGUAGES_FILES } from '../../../constants/enums';

import { resultUserPermissions } from '../../../services/api/v1/permission_requests/permissionRequests';
import { getSettings, enableSetting, disableSetting } from '../../../services/api/v1/setting_requests/settingRequests';

import { createNewAlert } from '../../../redux/reducer/alert-messages/alerts.actions';
import {
  setNavbarInfo,
  clearNavbar,
} from '../../../redux/reducer/navbar/navbar.reducers';
import { settingAdapter } from '../adapters/settings';

import RowTableSettings from './table-settings/RowTableSettings';
import SecondLoading from '../../../components/SecondLoading';
import SearchBar from '../../../components/SearchBar';
import DataTable from '../../../components/data_table/DataTable';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';

import DecryptedToken from '../../../components/security_components/decrypted_token/decrypted_token';
import EnableDisableAlert from '../../../components/EnableDisableAlert';

const columnsToSearch = ['code', 'description', 'nameEn', 'nameEs', 'value', 'createdAt'];

export default function Settings() {
  const [t, e] = useTranslation(LANGUAGES_FILES.SETTINGS);
  const authorization = DecryptedToken();
  const dispatch = useDispatch();

  const [updateSearch, setUpdateSearch] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [openDisableEnableModal, setOpenDisableEnableModal] = useState(false);

  const [settings, setSettings] = useState([]);
  const [settingInfo, setSettingInfo] = useState({
    id: null,
    name: null,
    isEnabled: null,
  });

  const [canUpdateSettings, setCanUpdateSettings] = useState(false);
  const [canEnableDisableSetting, setCanEnableDisableSetting] = useState(false);

  const { id } = useSelector((state) => state.authorization.informationUser);

  const columns = [
    {
      id: 1,
      title: t('settings.views.index.layout.tables.table-settings.table.header.code'),
      sortable: true,
      sortBy: 'code',
      width: 200,
    },
    {
      id: 2,
      title: t('settings.views.index.layout.tables.table-settings.table.header.description'),
      sortable: true,
      sortBy: 'description',
      width: 300,
    },
    {
      id: 3,
      title: t('settings.views.index.layout.tables.table-settings.table.header.name-en'),
      sortable: true,
      sortBy: 'nameEn',
      width: 200,
    },
    {
      id: 4,
      title: t('settings.views.index.layout.tables.table-settings.table.header.name-es'),
      sortable: true,
      sortBy: 'nameEs',
      width: 230,
    },
    {
      id: 5,
      title: t('settings.views.index.layout.tables.table-settings.table.header.value'),
      sortable: true,
      sortBy: 'value',
      width: 420,
    },
    {
      id: 6,
      title: t('settings.views.index.layout.tables.table-settings.table.header.created-at'),
      sortable: true,
      sortBy: 'createdAt',
      width: 195,
    },
  ];

  if (canUpdateSettings)
    columns.push({
      id: 7,
      title: t('settings.views.index.layout.tables.table-settings.table.header.edit'),
      width: 90,
      action: true,
    });

  if (canEnableDisableSetting)
    columns.push({
      id: 8,
      title: t('settings.views.index.layout.tables.table-settings.table.header.enabled'),
      width: 180,
      action: true,
    });

  const title = t('settings.views.index.layout.title');

  const breadcrumbs = [
    {
      id: 1,
      text: t('settings.views.index.layout.breadcrumbs.home'),
      path: '/dashboard',
    },
    {
      id: 2,
      text: t('settings.views.index.layout.breadcrumbs.settings'),
    },
  ];

  const openModal = (value) => {
    setOpenDisableEnableModal(true);
    setSettingInfo(value);
  };

  const closeModal = () => {
    setOpenDisableEnableModal(false);
  };

  const handleEnableDisableSetting = async (id, action) => {
    setLoadingRequest(true);
    let message = ""
    try {
      if (action === 'enable') {
        await enableSetting(authorization, id)
        message = t('settings.views.index.messages.api.correct.enable')
      } else if (action === 'disable') {
        await disableSetting(authorization, id);
        message = t('settings.views.index.messages.api.correct.disable')
      }

      await getDataToTable();
      setUpdateSearch(updateSearch + 1);

      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.DONE,
          message,
          languageFile: LANGUAGES_FILES.SETTINGS,
        }),
      );

      closeModal();
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('settings.views.index.messages.api.error.no-update'),
          languageFile: LANGUAGES_FILES.SETTINGS,
        }),
      );
    } finally {
      setLoadingRequest(false);
    }
  };

  const handleFilter = (rowsFiltereds) => {
    setSettings(rowsFiltereds);
  };

  const getPermissions = async () => {
    const updateSettings = await resultUserPermissions(
      authorization,
      id,
     'setting_update',
    );
    const enableDisableSetting = await resultUserPermissions(
      authorization,
      id,
     'setting_enable_disable',
    );

    setCanUpdateSettings(updateSettings.data.can);
    setCanEnableDisableSetting(enableDisableSetting.data.can);
  };

  const getDataToTable = async () => {
    const { data } = await getSettings(authorization);
    setSettings(settingAdapter(data, openModal));
    setIsLoading(false);
  };

  useEffect(() => {
    getPermissions();
    getDataToTable();
  }, []);

  useEffect(() => {
    dispatch(setNavbarInfo({ title, breadcrumbs }));

    return () => {
      dispatch(clearNavbar());
    };
  }, [e.language]);

  return (
    <main className="w-full min-w-[280px] h-full flex flex-col gap-1">
      {openDisableEnableModal ? (
        <Modal>
          <EnableDisableAlert
            handleClick={handleEnableDisableSetting}
            handleClose={closeModal}
            itemInfo={settingInfo}
            itemType={t('settings.views.index.layout.modals.enable-disable.title')}
          />
        </Modal>
      ) : null}

      {loadingRequest ? (
        <Modal>
          <Loading />
        </Modal>
      ) : null}

      <section className="w-full h-[95%] min-h-[550px] bg-white sm:rounded-[6px] shadow-md border pb-[28px] pt-4 relative">
        {isLoading && (
          <SecondLoading
            size={100}
            color={'#475569'}
            rounded={'6px'}
            opacity={'0.4'}
          />
        )}

        {columns.length ? (
          <div className="h-full flex flex-col items-center gap-4 xs:px-1 sm:px-4 md:px-5">
            <div className="w-full grid grid-cols-1 gap-3 md:grid-cols-3">
              <div className="w-full flex justify-center md:col-start-2">
                <SearchBar
                  list={settings}
                  handleFilter={handleFilter}
                  columnsToSearch={columnsToSearch}
                  placeholder={t('settings.views.index.layout.tables.table-settings.searchbar')}
                  disabled={isLoading}
                  update={updateSearch}
                />
              </div>
            </div>

            <DataTable
              data={{ rows: settings }}
              headers={columns}
              RowsComponent={RowTableSettings}
              permission={{
                canUpdateSettings,
                canEnableDisableSetting,
              }}
              noData={t('settings.views.index.layout.tables.table-settings.table.no-data')}
            />
          </div>
        ) : null}
      </section>
    </main>
  );
}
