import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import sha512 from 'js-sha512';

import { LOGO_TECH } from '../../../constants/logoTech';
import { ALERT_TYPE, LANGUAGES_FILES } from '../../../constants/enums';

import backImage from '../../../assets/imgs/back_image.png';

import { CgPassword } from 'react-icons/cg';

import {
  createNewAlert,
  clearAlert,
} from '../../../redux/reducer/alert-messages/alerts.actions';
import { verifyResetPassword } from '../../../services/api/v1/user_requests/userRequests'

import InputLogin from '../../login_page/components/InputLogin';
import ButtonGeneral from '../../../components/ButtonGeneral';
import FooterForm from '../../../components/FooterForm';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';

export default function PasswordRecovery() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t, e] = useTranslation(LANGUAGES_FILES.FORGOT_PASSWORD);

  const [isLoading, setIsLoading] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const verifyAndReset = async (password) => {
    const urlParams = new URLSearchParams(window.location.search);
    const info = {
      reset_password_token: urlParams.get('token_reset'),
      locale: e.language,
    };

    if (password) {
      info.user = password;
    }

    try {
      const response = await verifyResetPassword(info);
      if (response.data?.success) {
        dispatch(
          createNewAlert({
            newAlert: true,
            type: ALERT_TYPE.DONE,
            message: t('password-recovery.messages.api.correct.new-password-ok'),
            languageFile: LANGUAGES_FILES.FORGOT_PASSWORD,
          }),
        );
        setIsBlocked(true);
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      }
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('password-recovery.messages.api.error.error-password'),
          languageFile: LANGUAGES_FILES.FORGOT_PASSWORD,
        }),
      );
      setIsBlocked(true);
    } finally {
      setIsLoading(false);
    }
  };

  const validateErrors = () => {
    let newMessage = '';

    if (!newPassword || !passwordConfirmation) {
      newMessage = t('password-recovery.messages.web.error.fields-empties');
    } else if (newPassword.length < 8 || passwordConfirmation < 8) {
      newMessage = t('password-recovery.messages.web.error.password-length')
    } else if (newPassword !== passwordConfirmation) {
      newMessage = t('password-recovery.messages.web.error.password-no-match')
    }

    return newMessage;
  };

  const closeAlert = () => {
    dispatch(clearAlert());
  };

  const handleChange = (setState) => (event) => {
    const { value } = event.target;
    setState(value);
    closeAlert();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isError = validateErrors();

    if (isError) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: isError,
          languageFile: LANGUAGES_FILES.FORGOT_PASSWORD,
        }),
      );
    } else {
      const setPassword = {
        password: sha512(newPassword),
        password_confirmation: sha512(passwordConfirmation),
      };
      setIsLoading(true);
      verifyAndReset(setPassword);
    }
  }

  useEffect(() => {
    verifyAndReset(null);
  }, []);
  
  return (
    <main className="h-full w-full flex flex-col items-center justify-center gap-3 sm:px-16 md:bg-[#273447] md:px-0">
      {isLoading && (
        <Modal>
          <Loading />
        </Modal>
      )}

      <div className="bg-white min-w-[300px] w-full h-full md:h-[501px] md:flex md:justify-between md:max-w-[660px] z-10">
        <section className="hidden h-full md:block relative">
          <div>
            <img src={backImage} alt="Background airplane" />
          </div>
        </section>

        <section className="pt-12 pb-3 h-full flex flex-col items-center justify-between md:w-3/5">
          <div className="w-52 md:w-40">
            <img src={LOGO_TECH} alt="logo-longport-tech" />
          </div>

          <form
            className="m-0 py-4 w-full flex flex-col items-center gap-5"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col items-center gap-8 w-full">
              <span className="text-sm text-slate-500">
                {t('password-recovery.layout.forms.labels.recovery')}
              </span>

              <InputLogin
                icon={CgPassword}
                type="password"
                name="new-password"
                value={newPassword}
                placeholder={t('password-recovery.layout.forms.inputs.password')}
                onChange={handleChange(setNewPassword)}
                disabled={isLoading ? isLoading : isBlocked}
              />

              <InputLogin
                icon={CgPassword}
                type="password"
                name="password-confirmation"
                value={passwordConfirmation}
                placeholder={t('password-recovery.layout.forms.inputs.password-confirm')}
                onChange={handleChange(setPasswordConfirmation)}
                disabled={isLoading ? isLoading : isBlocked}
              />
            </div>

            <div className="w-9/12 flex flex-col-reverse">
              <div className="w-full flex justify-center mt-24 md:mt-10">
                <ButtonGeneral
                  value={t('password-recovery.layout.forms.buttons.send-password')}
                  disabled={isLoading ? isLoading : isBlocked}
                />
              </div>
            </div>
          </form>

          <FooterForm />
        </section>
      </div>

      <p className="text-sm text-white font-light hidden md:block z-10">
        {`${t('password-recovery.layout.footer.part-1')} ${new Date().getFullYear()} ${t('password-recovery.layout.footer.part-2')}`}
      </p>

      <div className="w-1/2 h-1/2 hidden md:block absolute overflow-hidden right-0 bottom-0">
        <div className="bg-[#239DF7] opacity-60 rounded-full w-[500px] h-[500px] absolute left-56 top-28"></div>
      </div>
      <div className="w-1/2 h-1/2 hidden md:block absolute overflow-hidden left-0 top-0">
        <div className="bg-[#F3F6FE] opacity-60 rounded-full w-[500px] h-[500px] absolute right-56 bottom-28"></div>
      </div>
    </main>
  );
}
