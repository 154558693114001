import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ALERT_TYPE, LANGUAGES_FILES } from '../../../constants/enums';

import { resultUserPermissions } from '../../../services/api/v1/permission_requests/permissionRequests';
import {
  resultUpdateIdentificationType,
  resultIdentificationTypes,
} from '../../../services/api/v1/identification_type_requests/identificationTypesRequests';

import { createNewAlert } from '../../../redux/reducer/alert-messages/alerts.actions';
import {
  setNavbarInfo,
  clearNavbar,
} from '../../../redux/reducer/navbar/navbar.reducers';
import { identificationTypesAdapter } from '../adapters/identificationTypes';

import RowsTableIdentificationTypes from './table-identification-types/RowsTableIdentificationTypes';
import SecondLoading from '../../../components/SecondLoading';
import ButtonGeneral from '../../../components/ButtonGeneral';
import SearchBar from '../../../components/SearchBar';
import DataTable from '../../../components/data_table/DataTable';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';

import DecryptedToken from '../../../components/security_components/decrypted_token/decrypted_token';
import EnableDisableAlert from '../../../components/EnableDisableAlert';

const columnsToSearch = ['code', 'name', 'createdAt'];

export default function IdentificationTypes() {
  const [t, e] = useTranslation(LANGUAGES_FILES.ID_TYPES);
  const authorization = DecryptedToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateSearch, setUpdateSearch] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [openDisableEnableModal, setOpenDisableEnableModal] = useState(false);

  const [idTypes, setIdTypes] = useState([]);
  const [idTypeInfo, setIdTypeInfo] = useState({
    id: null,
    name: null,
    isEnabled: null,
  });

  const [canUpdateIdTypes, setCanUpdateIdTypes] = useState(false);
  const [canEnableDisableIdType, setCanEnableDisableIdType] = useState(false);
  const [canCreateIdType, setCanCreateIdType] = useState(false);

  const { id } = useSelector((state) => state.authorization.informationUser);

  const columns = [
    {
      id: 1,
      title: t(
        'id-types.views.index.layout.tables.table-id-types.table.header.code',
      ),
      sortable: true,
      sortBy: 'code',
      width: 80,
    },
    {
      id: 2,
      title: t(
        'id-types.views.index.layout.tables.table-id-types.table.header.name',
      ),
      sortable: true,
      sortBy: 'name',
      width: 270,
    },
    {
      id: 3,
      title: t(
        'id-types.views.index.layout.tables.table-id-types.table.header.created-at',
      ),
      sortable: true,
      sortBy: 'createdAt',
      width: 195,
    },
  ];

  if (canUpdateIdTypes)
    columns.push({
      id: 4,
      title: t(
        'id-types.views.index.layout.tables.table-id-types.table.header.edit',
      ),
      width: 90,
      action: true,
    });

  if (canEnableDisableIdType)
    columns.push({
      id: 5,
      title: t(
        'id-types.views.index.layout.tables.table-id-types.table.header.enabled',
      ),
      width: 180,
      action: true,
    });

  const title = t('id-types.views.index.layout.title');

  const breadcrumbs = [
    {
      id: 1,
      text: t('id-types.views.index.layout.breadcrumbs.home'),
      path: '/dashboard',
    },
    {
      id: 2,
      text: t('id-types.views.index.layout.breadcrumbs.id-types'),
    },
  ];

  const openModal = (value) => {
    setOpenDisableEnableModal(true);
    setIdTypeInfo(value);
  };

  const closeModal = () => {
    setOpenDisableEnableModal(false);
  };

  const handleEnableDisableIdType = async (id, action) => {
    setLoadingRequest(true);

    const identification_type = {
      is_enabled: action === 'enable' ? true : false,
    };

    try {
      const { data } = await resultUpdateIdentificationType(
        authorization,
        id,
        identification_type,
      );
      await getDataToTable();
      setUpdateSearch(updateSearch + 1);

      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.DONE,
          message: data.is_enabled
            ? t('id-types.views.index.messages.api.correct.enable')
            : t('id-types.views.index.messages.api.correct.disable'),
          languageFile: LANGUAGES_FILES.ID_TYPES,
        }),
      );

      closeModal();
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('id-types.views.index.messages.api.error.no-update'),
          languageFile: LANGUAGES_FILES.ID_TYPES,
        }),
      );
    } finally {
      setLoadingRequest(false);
    }
  };

  const handleFilter = (rowsFiltereds) => {
    setIdTypes(rowsFiltereds);
  };

  const getPermissions = async () => {
    const updateIdTypes = await resultUserPermissions(
      authorization,
      id,
      'identification_type_update',
    );
    const enableDisableIdType = await resultUserPermissions(
      authorization,
      id,
      'identification_type_enable_disable',
    );
    const createIdType = await resultUserPermissions(
      authorization,
      id,
      'identification_type_create',
    );

    setCanUpdateIdTypes(updateIdTypes.data.can);
    setCanEnableDisableIdType(enableDisableIdType.data.can);
    setCanCreateIdType(createIdType.data.can);
  };

  const getDataToTable = async () => {
    const getIdTypes = await resultIdentificationTypes(authorization);
    setIdTypes(identificationTypesAdapter(getIdTypes.data, openModal));
    setIsLoading(false);
  };

  useEffect(() => {
    getPermissions();
    getDataToTable();
  }, []);

  useEffect(() => {
    dispatch(setNavbarInfo({ title, breadcrumbs }));

    return () => {
      dispatch(clearNavbar());
    };
  }, [e.language]);

  return (
    <main className="w-full min-w-[280px] h-full flex flex-col gap-1">
      {openDisableEnableModal ? (
        <Modal>
          <EnableDisableAlert
            handleClick={handleEnableDisableIdType}
            handleClose={closeModal}
            itemInfo={idTypeInfo}
            itemType={t(
              'id-types.views.index.layout.modals.enable-disable.title',
            )}
          />
        </Modal>
      ) : null}

      {loadingRequest ? (
        <Modal>
          <Loading />
        </Modal>
      ) : null}

      <section className="w-full h-[95%] min-h-[550px] bg-white sm:rounded-[6px] shadow-md border pb-[28px] pt-4 relative">
        {isLoading && (
          <SecondLoading
            size={100}
            color={'#475569'}
            rounded={'6px'}
            opacity={'0.4'}
          />
        )}

        {columns.length ? (
          <div className="h-full flex flex-col items-center gap-4 xs:px-1 sm:px-4 md:px-5">
            <div className="w-full grid grid-cols-1 gap-3 md:grid-cols-3">
              {canCreateIdType ? (
                <div className="w-full flex items-center justify-center md:order-2 md:justify-end md:pr-3">
                  <ButtonGeneral
                    value={t('id-types.views.index.layout.buttons.new-id-type')}
                    width={120}
                    onClick={() =>
                      navigate('/parameters/identifications_types/new')
                    }
                  />
                </div>
              ) : null}

              <div className="w-full flex justify-center md:col-start-2">
                <SearchBar
                  list={idTypes}
                  handleFilter={handleFilter}
                  columnsToSearch={columnsToSearch}
                  placeholder={t(
                    'id-types.views.index.layout.tables.table-id-types.searchbar',
                  )}
                  disabled={isLoading}
                  update={updateSearch}
                />
              </div>
            </div>

            <DataTable
              data={{ rows: idTypes }}
              headers={columns}
              RowsComponent={RowsTableIdentificationTypes}
              permission={{
                canUpdateIdTypes,
                canEnableDisableIdType,
              }}
              noData={t(
                'id-types.views.index.layout.tables.table-id-types.table.no-data',
              )}
            />
          </div>
        ) : null}
      </section>
    </main>
  );
}
