export const SET_USERS = 'SET_USERS';
export const SET_NEW_USER = 'SET_NEW_USER';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_UPDATE_USER = 'SET_UPDATE_USER';
export const SET_CAN_USER = 'SET_CAN_USER';
export const SET_CAN_USER_ENABLE = 'SET_CAN_USER_ENABLE';
export const SET_CAN_USER_UPDATE = 'SET_CAN_USER_UPDATE';
export const SET_CAN_USER_DOWNLOAD = 'SET_CAN_USER_DOWNLOAD';
export const SET_CAN_USER_SHOW_DETAIL = 'SET_CAN_USER_SHOW_DETAIL';
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';
export const SET_LOGGED = 'SET_LOGGED';
