const CHANGE_ZOOM = 'CHANGE_ZOOM';
const UPDATE_CENTER = 'UPDATE_CENTER';
const UPDATE_CURRENT_POSITION = 'UPDATE_CURRENT_POSITION';

export const changeZoom = (payload) => ({ type: CHANGE_ZOOM, payload });
export const updateCenter = (payload) => ({ type: UPDATE_CENTER, payload });
export const updateCurrentPosition = (payload) => ({
  type: UPDATE_CURRENT_POSITION,
  payload,
});

const initialState = {
  zoom: 4,
  center: { lat: 4.71099, lng: -74.07209 },
  currentPosition: { lat: 0, lng: 0 },
};

export const googleMaps = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_ZOOM:
      return { ...state, zoom: action.payload };

    case UPDATE_CENTER:
      return { ...state, center: action.payload };

    case UPDATE_CURRENT_POSITION:
      return { ...state, currentPosition: action.payload };

    default:
      return state;
  }
};
