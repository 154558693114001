const CHANGE_CONTRACTED_MENU = 'CHANGE_CONTRACTED_MENU';

export const changeContractedMenu = (payload) => ({
  type: CHANGE_CONTRACTED_MENU,
  payload,
});

const initialState = {
  isContractedMenu: false,
};

export const contractedMenu = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_CONTRACTED_MENU:
      return { isContractedMenu: action.payload };

    default:
      return state;
  }
};
