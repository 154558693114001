import UserRoutes from '../users_routes/users_routes';
import AirlinesRoutes from '../airlines_routes/airlines_routes';
import AirportsRoutes from '../airports_routes/airports_routes';
import AreasRoutes from '../areas_routes/areas_routes';
import CitiesRoutes from '../cities_routes/cities_routes';
import CompanyServicesRoutes from '../company_services_routes/company_services_routes';
import CountriesRoutes from '../countries_routes/countries_routes';
import IdentificationsTypesRoutes from '../identifications_types_routes/identifications_types_routes';
import JobsPositionsRoutes from '../jobs_positions_routes/jobs_positions_routes';
import MenusRoutes from '../menus_routes/menus_routes';
import PermissionTypesRoutes from '../permission_types_routes/permission_types_routes';
import PermissionsRoutes from '../permissions_routes/permissions_routes';
import PositionsRoutes from '../positions_routes/positions_routes';
import RolesRoutes from '../roles_routes/roles_routes';
import SectionsRoutes from '../sections_routes/sections_routes';
import FrequencyFlightRoutes from '../frequency_flights_routes/frequency_flights_routes';
import FlightsRoutes from '../flights_routes/flights_routes';
import FlightParameterizationRoutes from '../flight_parameterization_routes/flight_parameterization_routes';
import DashboardRoute from '../dashboard_route/dashboard_route';
import CompaniesRoutes from '../companies_routes/companies_routes';
import SettingsRoutes from '../settings_routes/SettingsRoutes';

function HomeRoutes() {
  let homeRoutes = [];

  homeRoutes.push(UserRoutes());
  homeRoutes.push(AirlinesRoutes());
  homeRoutes.push(AirportsRoutes());
  homeRoutes.push(AreasRoutes());
  homeRoutes.push(CitiesRoutes());
  homeRoutes.push(CompanyServicesRoutes());
  homeRoutes.push(CountriesRoutes());
  homeRoutes.push(IdentificationsTypesRoutes());
  homeRoutes.push(JobsPositionsRoutes());
  homeRoutes.push(MenusRoutes());
  homeRoutes.push(PermissionTypesRoutes());
  homeRoutes.push(PermissionsRoutes());
  homeRoutes.push(PositionsRoutes());
  homeRoutes.push(RolesRoutes());
  homeRoutes.push(SectionsRoutes());
  homeRoutes.push(FrequencyFlightRoutes());
  homeRoutes.push(FlightsRoutes());
  homeRoutes.push(FlightParameterizationRoutes());
  homeRoutes.push(DashboardRoute());
  homeRoutes.push(CompaniesRoutes());
  homeRoutes.push(SettingsRoutes());

  homeRoutes = homeRoutes.flat();
  return homeRoutes;
}

export default HomeRoutes;
