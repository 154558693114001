import { useNavigate } from 'react-router-dom';
import { BsFillEyeFill } from 'react-icons/bs';

export default function ShowRoleDetail({ roleId }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/parameters/roles/show/${roleId}`);
  };

  return (
    <div className="flex justify-center relative">
      <button
        className="text-[#273447] hover:text-[#50B8E4] absolute bottom-[-4px]"
        onClick={handleClick}
      >
        <BsFillEyeFill size={18} />
      </button>
    </div>
  );
}
