// External imports
import { lazy } from 'react';
import { useSelector } from 'react-redux';

// Pages imports
import JobPositions from '../../pages/job_positions_page/components/JobPositions';

// Pages imports with lazy loading
const NewJobPosition = lazy(() =>
  import('../../pages/job_positions_page/components/NewJobPosition'),
);
const EditJobPosition = lazy(() =>
  import('../../pages/job_positions_page/components/EditJobPosition'),
);

function JobsPositionsRoutes() {
  const customPermission = useSelector(
    (state) => state.permissions.resultCustomPermission,
  );

  const jobsPositionsRoutes = [
    {
      path: 'parameters/jobs_positions',
      element: <JobPositions />,
      isAllowed: customPermission.job_position_index,
    },
    {
      path: 'parameters/jobs_positions/new',
      element: <NewJobPosition />,
      isAllowed: customPermission.job_position_create,
    },
    {
      path: 'parameters/jobs_positions/edit/:id',
      element: <EditJobPosition />,
      isAllowed: customPermission.job_position_update,
    },
  ];

  return jobsPositionsRoutes;
}

export default JobsPositionsRoutes;
