import { SET_STATIONS } from '../types/stationsTypes';

const initialState = {
  resultStation: [],
};

function stationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STATIONS:
      return { ...state, resultStation: action.payload };

    default:
      return state;
  }
}

export default stationsReducer;
