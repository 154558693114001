import { axiosSesion } from '../axios';

// Get all records
export const resultTotalRecords = (
  Authorization,
  stationId,
  airlineId,
  iataFlight,
) => {
  const sParams = `flight_in_out_operator[type_data]=records&flight_in_out_operator[station_id]=${
    stationId ? stationId : ''
  }&flight_in_out_operator[airline_id]=${
    airlineId ? airlineId : ''
  }&flight_in_out_operator[iata_flight]=${iataFlight ? iataFlight : ''}`;
  return axiosSesion.get(
    `/api/v1/in_out_operators/count_by_type_data?${sParams}`,
    {
      headers: { Authorization },
    },
  );
};

// Get flight reports
export const resultReport = (
  Authorization,
  startFilter,
  endFilter,
  stationId,
  airlineId,
  iataFlight,
) => {
  const sParams = `station_id=${stationId ? stationId : ''}&airline_id=${
    airlineId ? airlineId : ''
  }&iata_flight=${iataFlight ? iataFlight : ''}&start_filter_date=${
    startFilter ? startFilter : ''
  }&end_filter_date=${endFilter ? endFilter : ''}`;
  return axiosSesion.get(`/api/v1/flights/general_report?${sParams}`, {
    headers: { Authorization },
  });
};

// Get record by parameters
export const resultsRecord = (Authorization, year) => {
  const sParams = `[date_year]=${year}`;
  return axiosSesion.get(`/in_out_counters/get_records_in_out?${sParams}`, {
    headers: { Authorization },
  });
};

// Get flight operation report
export const flightOperationReport = (
  Authorization,
  flight_id,
  locale,
  isJetBlue,
  tail,
  screening,
) => {
  return axiosSesion.get(
    `/api/v1/flights/flight_operation_report/${flight_id}`,
    {
      headers: { Authorization },
      params: {
        locale,
        is_jet_blue: isJetBlue ? isJetBlue : null,
        tail,
        screening,
      },
    },
  );
};
