import { FaRegEdit } from 'react-icons/fa';

export default function EditFrequentFlight({ flightInfo, handleModal }) {
  const handleClick = () => {
    handleModal(flightInfo);
  };

  return (
    <div className="flex justify-center relative">
      <button
        className="text-[#273447] hover:text-[#50B8E4] absolute bottom-[-4px]"
        onClick={handleClick}
      >
        <FaRegEdit size={18} />
      </button>
    </div>
  );
}
