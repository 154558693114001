import {
  dateWithTimezone,
  getHoursAndMinutes,
} from '../../../utils/dateFormatter';

export const iataCodeListAdapter = (data) => {
  const response = [];
  data.forEach((item) => {
    response.push({
      id: item.iata_flight,
      name: item.iata_flight,
    });
  });

  return response;
};

export const airlinesListAdapter = (data, alloweds) => {
  const airlinesAllowed = alloweds.map((allowed) => allowed.id);
  const response = [];
  data.forEach((item) => {
    response.push({
      id: item.id,
      name: `[${item.iata_code}] ${item.name}`,
      hidden: !airlinesAllowed.includes(item.id),
    });
  });

  return response;
};

export const airportsListAdapter = (data, alloweds) => {
  const airportsAllowed = alloweds.map((allowed) => allowed.id);
  const response = [];
  data.forEach((item) => {
    response.push({
      id: item.id,
      name: `[${item.iata_code}] ${item.name}`,
      hidden: !airportsAllowed.includes(item.id),
    });
  });

  return response;
};

export const frequentFlightsAdapter = (data, handleModal) => {
  const response = [];
  data.forEach((item) => {
    if (!item.is_enabled) return;
    const flightInfo = {
      id: item.id,
      monday: item.monday,
      mondayDep: item.monday_dep_scheduled_at
        ? getHoursAndMinutes(item.monday_dep_scheduled_at)
        : '',
      mondayArr: item.monday_arr_scheduled_at
        ? getHoursAndMinutes(item.monday_arr_scheduled_at)
        : '',
      tuesday: item.tuesday,
      tuesdayDep: item.tuesday_dep_scheduled_at
        ? getHoursAndMinutes(item.tuesday_dep_scheduled_at)
        : '',
      tuesdayArr: item.tuesday_arr_scheduled_at
        ? getHoursAndMinutes(item.tuesday_arr_scheduled_at)
        : '',
      wednesday: item.wednesday,
      wednesdayDep: item.wednesday_dep_scheduled_at
        ? getHoursAndMinutes(item.wednesday_dep_scheduled_at)
        : '',
      wednesdayArr: item.wednesday_arr_scheduled_at
        ? getHoursAndMinutes(item.wednesday_arr_scheduled_at)
        : '',
      thursday: item.thursday,
      thursdayDep: item.thursday_dep_scheduled_at
        ? getHoursAndMinutes(item.thursday_dep_scheduled_at)
        : '',
      thursdayArr: item.thursday_arr_scheduled_at
        ? getHoursAndMinutes(item.thursday_arr_scheduled_at)
        : '',
      friday: item.friday,
      fridayDep: item.friday_dep_scheduled_at
        ? getHoursAndMinutes(item.friday_dep_scheduled_at)
        : '',
      fridayArr: item.friday_arr_scheduled_at
        ? getHoursAndMinutes(item.friday_arr_scheduled_at)
        : '',
      saturday: item.saturday,
      saturdayDep: item.saturday_dep_scheduled_at
        ? getHoursAndMinutes(item.saturday_dep_scheduled_at)
        : '',
      saturdayArr: item.saturday_arr_scheduled_at
        ? getHoursAndMinutes(item.saturday_arr_scheduled_at)
        : '',
      sunday: item.sunday,
      sundayDep: item.sunday_dep_scheduled_at
        ? getHoursAndMinutes(item.sunday_dep_scheduled_at)
        : '',
      sundayArr: item.sunday_arr_scheduled_at
        ? getHoursAndMinutes(item.sunday_arr_scheduled_at)
        : '',
    };

    response.push({
      id: item.flight_id,
      flightCode: item.iata_flight,
      scheduledDepartureTime: dateWithTimezone(item.departure_scheduled_time),
      scheduledArrivalTime: dateWithTimezone(item.arrival_scheduled_time),
      flightStatus: item.flight_status,
      flightInfo,
      handleModal,
    });
  });

  return response;
};
