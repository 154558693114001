import { dateWithTimezone } from '../../../utils/dateFormatter';

export const sectionsAdapter = (data, handleModal) => {
  const language = localStorage.getItem('language');

  const response = [];
  data.forEach((item) => {
    if (item.name_en.includes('xxx')) return;

    response.push({
      id: item.id,
      isEnabled: item.is_enabled,
      nameEn: item.name_en,
      nameEs: item.name_es,
      parentSectionName: !item.section
        ? '-'
        : language === 'en'
        ? item.section.name_en
        : item.section.name_es,
      createdAt: dateWithTimezone(item.created_at),
      handleModal,
    });
  });

  return response;
};

export const sectionParentAdapter = (data) => {
  const language = localStorage.getItem('language');

  const response = [];
  data.forEach((item) => {
    if (item.full_name_sections_en.includes('xxx')) return;

    response.push({
      id: item.menu_id,
      parentId: item.parent_section_id,
      name:
        language === 'en'
          ? item.full_name_sections_en
          : item.full_name_sections_es,
      sectionId: item.section_id,
      isEnabled: item.is_enabled,
    });
  });

  return response;
};

export const currentSectionAdapter = (data) => {
  return {
    nameEn: data.name_en,
    nameEs: data.name_es,
    parentSectionId: data.parent_section_id,
  };
};
