import { dateWithTimezone } from '../../../utils/dateFormatter';

export const companyServicesAdapter = (data, handleModal) => {
  const response = [];
  data.forEach((item) => {
    if (item.description.includes('xxx')) return;

    response.push({
      id: item.id,
      isEnabled: item.is_enabled,
      nameEn: item.name_en,
      nameEs: item.name_es,
      description: item.description,
      createdAt: dateWithTimezone(item.created_at),
      handleModal,
    });
  });

  return response;
};
