import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ALERT_TYPE, LANGUAGES_FILES } from '../../../constants/enums';

import { resultUserPermissions } from '../../../services/api/v1/permission_requests/permissionRequests';
import { resultSections } from '../../../services/api/v1/section_requests/sectionRequests';
import { resultDisableRole } from '../../../services/api/v1/rol_requests/rolRequests';

import { createNewAlert } from '../../../redux/reducer/alert-messages/alerts.actions';
import {
  setNavbarInfo,
  clearNavbar,
} from '../../../redux/reducer/navbar/navbar.reducers';
import { sectionsAdapter } from '../adapters/sections';

import RowTableSections from './table-sections/RowTableSections';
import SecondLoading from '../../../components/SecondLoading';
import ButtonGeneral from '../../../components/ButtonGeneral';
import SearchBar from '../../../components/SearchBar';
import DataTable from '../../../components/data_table/DataTable';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';

import DecryptedToken from '../../../components/security_components/decrypted_token/decrypted_token';
import EnableDisableAlert from '../../../components/EnableDisableAlert';

const columnsToSearch = ['nameEn', 'nameEs', 'parentSectionName', 'createdAt'];

export default function Sections() {
  const [t, e] = useTranslation(LANGUAGES_FILES.SECTIONS);
  const authorization = DecryptedToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateSearch, setUpdateSearch] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [openDisableEnableModal, setOpenDisableEnableModal] = useState(false);

  const [sections, setSections] = useState([]);
  const [sectionInfo, setSectionInfo] = useState({
    id: null,
    name: null,
    isEnabled: null,
  });

  const [canUpdateSections, setCanUpdateSections] = useState(false);
  const [canEnableDisableSection, setCanEnableDisableSection] = useState(false);
  const [canCreateSection, setCanCreateSection] = useState(false);

  const { id } = useSelector((state) => state.authorization.informationUser);

  const columns = [
    {
      id: 1,
      title: t(
        'sections.views.index.layout.tables.table-sections.table.header.name-en',
      ),
      sortable: true,
      sortBy: 'nameEn',
      width: 200,
    },
    {
      id: 2,
      title: t(
        'sections.views.index.layout.tables.table-sections.table.header.name-es',
      ),
      sortable: true,
      sortBy: 'nameEs',
      width: 230,
    },
    {
      id: 3,
      title: t(
        'sections.views.index.layout.tables.table-sections.table.header.parent-section',
      ),
      sortable: true,
      sortBy: 'parentSectionName',
      width: 220,
    },
    {
      id: 4,
      title: t(
        'sections.views.index.layout.tables.table-sections.table.header.created-at',
      ),
      sortable: true,
      sortBy: 'createdAt',
      width: 195,
    },
  ];

  if (canUpdateSections)
    columns.push({
      id: 5,
      title: t(
        'sections.views.index.layout.tables.table-sections.table.header.edit',
      ),
      width: 90,
      action: true,
    });

  if (canEnableDisableSection)
    columns.push({
      id: 6,
      title: t(
        'sections.views.index.layout.tables.table-sections.table.header.enabled',
      ),
      width: 180,
      action: true,
    });

  const title = t('sections.views.index.layout.title');

  const breadcrumbs = [
    {
      id: 1,
      text: t('sections.views.index.layout.breadcrumbs.home'),
      path: '/dashboard',
    },
    {
      id: 2,
      text: t('sections.views.index.layout.breadcrumbs.sections'),
    },
  ];

  const openModal = (value) => {
    setOpenDisableEnableModal(true);
    setSectionInfo(value);
  };

  const closeModal = () => {
    setOpenDisableEnableModal(false);
  };

  const handleEnableDisableSection = async (id, action) => {
    setLoadingRequest(true);
    try {
      const { data } = await resultDisableRole(
        authorization,
        'sections',
        id,
        action,
      );
      await getDataToTable();
      setUpdateSearch(updateSearch + 1);

      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.DONE,
          message: data.is_enabled
            ? t('sections.views.index.messages.api.correct.enable')
            : t('sections.views.index.messages.api.correct.disable'),
          languageFile: LANGUAGES_FILES.SECTIONS,
        }),
      );

      closeModal();
    } catch (error) {
      dispatch(
        createNewAlert({
          newAlert: true,
          type: ALERT_TYPE.ERROR,
          message: t('sections.views.index.messages.api.error.no-update'),
          languageFile: LANGUAGES_FILES.SECTIONS,
        }),
      );
    } finally {
      setLoadingRequest(false);
    }
  };

  const handleFilter = (rowsFiltereds) => {
    setSections(rowsFiltereds);
  };

  const getPermissions = async () => {
    const updateSections = await resultUserPermissions(
      authorization,
      id,
      'section_update',
    );
    const enableDisableSection = await resultUserPermissions(
      authorization,
      id,
      'section_enable_disable',
    );
    const createSection = await resultUserPermissions(
      authorization,
      id,
      'section_create',
    );

    setCanUpdateSections(updateSections.data.can);
    setCanEnableDisableSection(enableDisableSection.data.can);
    setCanCreateSection(createSection.data.can);
  };

  const getDataToTable = async () => {
    const getSections = await resultSections(authorization);
    setSections(sectionsAdapter(getSections.data, openModal));
    setIsLoading(false);
  };

  useEffect(() => {
    getPermissions();
    getDataToTable();
  }, []);

  useEffect(() => {
    dispatch(setNavbarInfo({ title, breadcrumbs }));

    return () => {
      dispatch(clearNavbar());
    };
  }, [e.language]);

  return (
    <main className="w-full min-w-[280px] h-full flex flex-col gap-1">
      {openDisableEnableModal ? (
        <Modal>
          <EnableDisableAlert
            handleClick={handleEnableDisableSection}
            handleClose={closeModal}
            itemInfo={sectionInfo}
            itemType={t(
              'sections.views.index.layout.modals.enable-disable.title',
            )}
          />
        </Modal>
      ) : null}

      {loadingRequest ? (
        <Modal>
          <Loading />
        </Modal>
      ) : null}

      <section className="w-full h-[95%] min-h-[550px] bg-white sm:rounded-[6px] shadow-md border pb-[28px] pt-4 relative">
        {isLoading && (
          <SecondLoading
            size={100}
            color={'#475569'}
            rounded={'6px'}
            opacity={'0.4'}
          />
        )}

        {columns.length ? (
          <div className="h-full flex flex-col items-center gap-4 xs:px-1 sm:px-4 md:px-5">
            <div className="w-full grid grid-cols-1 gap-3 md:grid-cols-3">
              {canCreateSection ? (
                <div className="w-full flex items-center justify-center md:order-2 md:justify-end md:pr-3">
                  <ButtonGeneral
                    value={t('sections.views.index.layout.buttons.new-section')}
                    width={120}
                    onClick={() => navigate('/configuration/sections/new')}
                  />
                </div>
              ) : null}

              <div className="w-full flex justify-center md:col-start-2">
                <SearchBar
                  list={sections}
                  handleFilter={handleFilter}
                  columnsToSearch={columnsToSearch}
                  placeholder={t(
                    'sections.views.index.layout.tables.table-sections.searchbar',
                  )}
                  disabled={isLoading}
                  update={updateSearch}
                />
              </div>
            </div>

            <DataTable
              data={{ rows: sections }}
              headers={columns}
              RowsComponent={RowTableSections}
              permission={{
                canUpdateSections,
                canEnableDisableSection,
              }}
              noData={t(
                'sections.views.index.layout.tables.table-sections.table.no-data',
              )}
            />
          </div>
        ) : null}
      </section>
    </main>
  );
}
