import {
  SET_OPEN_MODAL_CHANGE_PASSWORD,
  SET_OPEN_MODAL_PROFILE,
  SET_UPDATE_PASSWORD,
} from '../types/profileTypes';

const initialState = {
  resultUpdatePassword: '',
  resultOpenModalChangePassword: false,
  resultOpenModalProfile: false,
};

function profileReducer(state = initialState, action) {
  switch (action.type) {
    case SET_UPDATE_PASSWORD:
      return { ...state, resultUpdatePassword: action.payload };

    case SET_OPEN_MODAL_CHANGE_PASSWORD:
      return { ...state, resultOpenModalChangePassword: action.payload };

    case SET_OPEN_MODAL_PROFILE:
      return { ...state, resultOpenModalProfile: action.payload };

    default:
      return state;
  }
}

export default profileReducer;
